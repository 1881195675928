import React from "react";

const arrowLongUp: React.FC<{ color?: string }> = ({ color='#32AE08' }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73484 1.73483C5.88128 1.58839 6.11872 1.58839 6.26517 1.73483L9.26516 4.73484C9.41161 4.88128 9.41161 5.11872 9.26516 5.26517C9.11872 5.41161 8.88128 5.41161 8.73484 5.26517L6.375 2.90533L6.375 10C6.375 10.2071 6.20711 10.375 6 10.375C5.79289 10.375 5.625 10.2071 5.625 10L5.625 2.90533L3.26517 5.26516C3.11872 5.41161 2.88128 5.41161 2.73483 5.26516C2.58839 5.11872 2.58839 4.88128 2.73483 4.73483L5.73484 1.73483Z"
        fill={color}
      />
    </svg>
  );
};

export default arrowLongUp;
