import { Chip as ChipComponent } from "@mui/material";

import { ChipProps } from "interfaces/Components.types";

const Chip: React.FC<ChipProps> = ({ isSelected, label, ...props }) => {
  return (
    <ChipComponent
      sx={{
        backgroundColor: isSelected ? "#222124" : "#efefef",
        color: isSelected ? "#ffffff" : "#222124",
        fontSize: "15px",
        p: "16px 0px",
        borderRadius: "100px",
        textTransform: "capitalize",
        fontWeight: 500,
        whiteSpace: "none",
      }}
      label={label}
      {...props}
    />
  );
};

export default Chip;
