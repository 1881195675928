import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Typography,
} from "@mui/material";
import { Button } from "components/Buttons";
import { DropDown } from "components/Select";
import TextField from "components/TextField";
import { CategoryProps, ServiceProps } from "interfaces/BusinessProfiles.types";
import React, { forwardRef, useState } from "react";
import { flexColumnCenterCenter, flexRowCenterCenter } from "utils/styles";
import plus from "assets/icons/add-black.svg";
import rightArrow from "assets/icons/chevron-right.svg";
import back from "assets/icons/back.svg";
import bluePlus from "assets/icons/plus-blue.svg";
import close from "assets/icons/close.svg";
import tick from "assets/icons/tick-blue.svg";

type CustomServiceProps = { open: boolean; value: string };

const CustomService: React.FC<{
  customService: CustomServiceProps;
  setCustomService: (customService: CustomServiceProps) => void;
}> = forwardRef(({ customService, setCustomService }, ref) => {
  if (customService.open)
    return (
      <Box sx={{ minWidth: "60%", maxWidth: 300, display: "flex" }}>
        <Box width="100%" ref={ref}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 0.2 }}
            value={customService.value}
            onChange={(event) =>
              setCustomService({
                ...customService,
                value: event.target.value.slice(0, 58),
              })
            }
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="caption">
              {customService.value.length}/58
            </Typography>
          </Box>
        </Box>
        <IconButton
          sx={{ height: 34, width: 34, mt: 1 }}
          onClick={() => setCustomService({ ...customService, value: "" })}
        >
          <img src={close} alt="close" style={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
    );
  return null;
});

const priceMenu = [
  { id: 1, name: "No Price" },
  { id: 2, name: "Free" },
  { id: 3, name: "Fixed" },
  { id: 4, name: "From" },
];

const Services: React.FC = forwardRef((props, ref) => {
  const [categories, setCategories] = useState<CategoryProps[]>([
    {
      id: 1,
      category: "primary",
      company: "software",
      services: [
        {
          id: 1,
          name: "Website Development",
          priceId: 1,
          price: 0,
          description: "",
        },
      ],
    },
    {
      id: 2,
      category: "additional",
      company: "software",
      services: [
        {
          id: 1,
          name: "Website Development",
          priceId: 1,
          price: 0,
          description: "",
        },
      ],
    },
  ]);
  const [services, setServices] = useState([
    {
      id: 1,
      name: "Website Development",
      priceId: 1,
      price: 0,
      description: "",
      isSelected: false,
    },
    {
      id: 2,
      name: "Mobile app development",
      priceId: 1,
      price: 0,
      description: "",
      isSelected: false,
    },
    {
      id: 3,
      name: "Website Development and Service Management",
      priceId: 1,
      price: 0,
      description: "",
      isSelected: false,
    },
    {
      id: 4,
      name: "Software development",
      priceId: 1,
      price: 0,
      description: "",
      isSelected: false,
    },
  ]);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [openDeleteService, setOpenDeleteService] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceProps | null>(
    null
  );
  const [addCategory, setAddCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryProps | null>(null);
  const [customService, setCustomService] = useState({
    open: false,
    value: "",
  });

  const onDeleteCategory = (currentCategory: CategoryProps) => {
    setCategories(
      categories.filter((category) => category.id !== currentCategory.id)
    );
    setOpenDeleteCategory(false);
  };

  const onDeleteService = (
    currentCategory: CategoryProps,
    currentService: ServiceProps
  ) => {
    const newCategories = categories.map((category) => {
      if (category.id === currentCategory.id) {
        return {
          ...category,
          services: category.services.filter(
            (service) => service.id !== currentService.id
          ),
        };
      }
      return category;
    });
    setCategories(newCategories);
    setOpenDeleteService(false);
    setSelectedCategory(null);
    setSelectedService(null);
  };
  const onClickService = (currentService: any) => {
    const newServices = services.map((service) => {
      if (service.id === currentService.id) {
        return { ...currentService, isSelected: !currentService.isSelected };
      }
      return service;
    });
    setServices(newServices);
  };
  if (selectedCategory && selectedService)
    return (
      <Box ref={ref}>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontWeight: 600,
            py: 1,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <IconButton
            onClick={() => {
              setSelectedService(null);
              setSelectedCategory(null);
            }}
          >
            <img src={back} alt="back-button" />
          </IconButton>
          Edit Services
        </Typography>
        <Box sx={{ p: 2 }}>
          <Box>
            <Typography variant="caption">Service</Typography>
            <Typography variant="body2">{selectedService.name}</Typography>
          </Box>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              flexWrap: "wrap",
              py: 2,
              pr: 4,
            }}
          >
            <Box>
              <Typography variant="caption" sx={{ display: "block" }}>
                Price
              </Typography>
              <Box mt={1} mb={2} width={250}>
                <DropDown value={selectedService.priceId} width="100%">
                  {priceMenu.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </DropDown>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{ display: "block", whiteSpace: "nowrap" }}
              >
                Service price (INR)
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                value={selectedService.price}
              />
            </Box>
          </Box>
          <Box pr={4}>
            <Typography variant="caption" sx={{ display: "block" }}>
              Service Description
            </Typography>
            <TextField
              variant="outlined"
              multiline
              minRows={4}
              sx={{ mb: 0.3 }}
              fullWidth
              value={selectedService.description}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="caption" sx={{ fontSize: "10px" }}>
                0/300
              </Typography>
            </Box>
            <Button
              sx={{ color: "#1650CF", border: "none" }}
              onClick={() => setOpenDeleteService(true)}
            >
              Delete Service
            </Button>
            <Dialog
              sx={{
                "& .MuiDialog-paper": { width: 550, borderRadius: "11px" },
              }}
              maxWidth="md"
              open={openDeleteService}
            >
              <DialogTitle sx={{ fontSize: "16px" }}>
                Delete Service?
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2" color="textDisabled">
                  {selectedService.name} will be removed from all categories and
                  will no longer be visible on your Business Profile
                </Typography>
              </DialogContent>
              <DialogActions sx={{ pb: 2 }}>
                <Button
                  sx={{ border: "none" }}
                  autoFocus
                  onClick={() => setOpenDeleteService(false)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ border: "none", color: "rgba(22, 80, 207, 1)" }}
                  onClick={() =>
                    onDeleteService(selectedCategory, selectedService)
                  }
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    );
  if (selectedCategory)
    return (
      <Box ref={ref}>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontWeight: 600,
            py: 1,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <IconButton
            onClick={() => {
              setSelectedCategory(null);
              setCustomService({ open: false, value: "" });
            }}
          >
            <img src={back} alt="back-button" />
          </IconButton>
          Add Services
        </Typography>
        <Box
          p={2}
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {selectedCategory.category} Category
          </Typography>
          <Typography
            variant="body1"
            sx={{ textTransform: "capitalize", fontWeight: 400 }}
          >
            {selectedCategory.company} Company
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          {selectedCategory.category === "primary" ? (
            <Box gap={1} sx={{ display: "flex", flexWrap: "wrap", py: 2 }}>
              {services.map((service) => (
                <Chip
                  key={service.id}
                  label={service.name}
                  icon={
                    service.isSelected ? (
                      <img src={tick} alt="tick" />
                    ) : (
                      <img src={plus} alt="plus" />
                    )
                  }
                  onClick={() => onClickService(service)}
                  sx={(theme) => {
                    if (service.isSelected)
                      return {
                        backgroundColor: "#e7f0fe",
                        color: "#1967d2",
                        borderRadius: "4px",
                      };
                    return {
                      backgroundColor: "transparent",
                      border: `1px solid ${theme.palette.grey["300"]}`,
                      borderRadius: "4px",
                    };
                  }}
                />
              ))}
            </Box>
          ) : null}

          <Typography variant="caption" sx={{ display: "block", mt: 2 }}>
            Don’t see a service you offer? Create your own
          </Typography>
          <CustomService
            customService={customService}
            setCustomService={(val) => setCustomService(val)}
          />
          <Button
            startIcon={<img src={bluePlus} alt="plus" />}
            sx={{
              color: "#1650CF",
              border: "none",
              my: 1,
              textTransform: "unset",
            }}
            onClick={() =>
              setCustomService({ ...customService, open: !customService.open })
            }
          >
            Add custom service
          </Button>
        </Box>
      </Box>
    );
  if (addCategory)
    return (
      <Box ref={ref}>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontWeight: 600,
            py: 1,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <IconButton
            onClick={() => {
              setAddCategory(false);
              setCustomService({ open: false, value: "" });
            }}
          >
            <img src={back} alt="back-button" />
          </IconButton>
          Add a business category
        </Typography>
        <Box sx={{ p: 2 }}>
          <Box>
            <Typography variant="caption">Enter a business category</Typography>
            <Box>
              <TextField
                variant="outlined"
                size="small"
                sx={{ minWidth: "60%" }}
              />
            </Box>
          </Box>
          <Typography variant="caption" sx={{ display: "block", mt: 4 }}>
            Don’t see a service you offer? Create your own
          </Typography>
          <CustomService
            customService={customService}
            setCustomService={(val) => setCustomService(val)}
          />
          <Button
            startIcon={<img src={bluePlus} alt="plus" />}
            sx={{
              color: "#1650CF",
              border: "none",
              my: 2,
              mb: 4,
              textTransform: "unset",
            }}
            onClick={() =>
              setCustomService({ ...customService, open: !customService.open })
            }
          >
            Add custom service
          </Button>
        </Box>
      </Box>
    );
  return (
    <Box ref={ref}>
      <Typography
        variant="body1"
        sx={(theme) => ({
          fontWeight: 600,
          p: 2,
          borderBottom: `1px solid ${theme.palette.grey["200"]}`,
        })}
      >
        Add Services
      </Typography>
      <Box sx={{ p: 2 }}>
        {categories.map((category) => (
          <Box key={category.id}>
            <Box
              pb={2}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                {category.category} Category
              </Typography>
              <Box
                sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 400,
                    height: 37,
                    ...flexColumnCenterCenter,
                  }}
                >
                  {category.company} Company
                </Typography>
                {category.category === "primary" ? null : (
                  <Button
                    sx={{ color: "#1650CF", border: "none" }}
                    onClick={() => setOpenDeleteCategory(true)}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            </Box>
            <List sx={{ p: 0 }}>
              {category.services.map((service) => (
                <ListItem
                  key={service.id}
                  sx={(theme) => ({
                    p: 0,
                    borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                  })}
                >
                  <Typography variant="body2">{service.name} </Typography>
                  <IconButton
                    onClick={() => {
                      setSelectedCategory(category);
                      setSelectedService(service);
                    }}
                  >
                    <img
                      src={rightArrow}
                      alt="right-arrow"
                      style={{ width: 30, height: 30 }}
                    />
                  </IconButton>
                </ListItem>
              ))}
            </List>
            <Button
              startIcon={<img src={bluePlus} alt="plus" />}
              sx={{ color: "#1650CF", border: "none", mt: 1, mb: 5 }}
              onClick={() => setSelectedCategory(category)}
            >
              Add more services
            </Button>
            <Dialog
              sx={{
                "& .MuiDialog-paper": { width: 550, borderRadius: "11px" },
              }}
              maxWidth="md"
              open={openDeleteCategory}
            >
              <DialogTitle sx={{ fontSize: "16px" }}>
                Delete Category?
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2" color="textDisabled">
                  Removing a category will also remove these services from your
                  Business Profile on Google
                </Typography>
              </DialogContent>
              <DialogActions sx={{ pb: 2 }}>
                <Button
                  sx={{ border: "none" }}
                  autoFocus
                  onClick={() => setOpenDeleteCategory(false)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ border: "none", color: "rgba(22, 80, 207, 1)" }}
                  onClick={() => onDeleteCategory(category)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        ))}
        <Box sx={{ ...flexRowCenterCenter, pt: 2 }}>
          <Button
            sx={{ color: "#1650CF", textTransform: "unset", px: 2 }}
            onClick={() => setAddCategory(true)}
          >
            Add another business category
          </Button>
        </Box>
      </Box>
    </Box>
  );
});

export default Services;
