import styled from "@emotion/styled";
import { TextField as Text, TextFieldProps } from "@mui/material";

export const CustomTextField = styled(Text)(( ) => ({
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontWeight:500
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight:400
  },
}));

const TextField = ({ children, slotProps, sx, variant, ...props }: TextFieldProps) => {
  return (
    variant==='outlined'?<CustomTextField
      sx={{
        mb: 2,
        mt: 1,
        minWidth: 100,
        fontWeight: 400,
        borderRadius: "8px",
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            border: `1px solid #d0d0d0`,
          },
          "& .Mui-focused fieldset": {
            border: `1px solid #d0d0d0`,
          },
          "&input": {
            color: '#222124',
            backgroundColor: '#222124',
            fontWeight: 400,
            fontSize: "14px",
            borderWidth:'1px'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          "&::placeholder": {
          color: '#d0d0d0',
          opacity: 100,
          display: "visible",
        },
        },
        ...sx
      }}
      slotProps={{
        inputLabel: { shrink: false },
        htmlInput: {
          sx: { fontSize: "14px", fontWeight: 400 },
      },
      ...slotProps}}
      {...props}
    >
      {children}
    </CustomTextField>:<Text />
  );
};

export default TextField;
