import {
  Avatar,
  Box,
  Chip,
  InputAdornment,
  ListItemText,
  MenuItem,
  Modal,
  Pagination,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { flexRowCenterCenter } from "utils/styles";
import category from "assets/icons/category.svg";
import sort from "assets/icons/sort.svg";
import list from "assets/icons/list.svg";
import tile from "assets/icons/tile.svg";
import arrowUp from "assets/icons/arrow-up-green-bold.svg";
import arrowDown from "assets/icons/arrow-down-bold-red.svg";
import Select, { DropDown } from "components/Select";
import Searchbar from "components/Searchbar";
import styled from "@emotion/styled";
import Dot from "components/Dot";
import Checkbox from "components/Checkbox";
import { MoreFiltersModal } from "./Modals";
import { getHealthStatus } from "utils/functions";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";

import { ReactComponent as DownIcon } from "assets/icons/down.svg";
import { Button } from "components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getListings } from "store/listingsSlice";

const CustomTableSortLabel = styled(TableSortLabel)(() => ({
  "&.Mui-active": {
    color: "#222124",
  },
}));

type BusinessProfile = {
  id: string;
  businessProfile: {
    title: string;
    address: string;
  };
  image: string;
  logo: string;
  status: string;
  avgRating: number;
  rating: number;
  reviewCount: number;
  reviewGrowth: number;
  sync: string;
  profileScore: number;
  checked: boolean;
};

const options = [
  { id: "1", label: "Edit Profile" },
  { id: "2", label: "Photos or Videos" },
  { id: "3", label: "Edit Products" },
  { id: "4", label: "Edit Service" },
  { id: "5", label: "Updates" },
  { id: "6", label: "Labels" },
];

const ratings = [
  { id: 1, number: "5" },
  { id: 2, number: "4" },
  { id: 3, number: "3" },
  { id: 4, number: "2" },
  { id: 5, number: "1" },
];

const BusinessProfiles = () => {
  const navigate = useNavigate();
  const {
    listings: initialListings,
    count,
    statesList,
    citiesList,
  } = useSelector((state: RootState) => state.listings);
  const dispatch = useDispatch<AppDispatch>();
  const [listings, setListings] = useState(initialListings);
  const [selectedProfiles, setSelectedProfiles] = useState<
    BusinessProfile[] | null
  >([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof BusinessProfile>("profileScore");
  const [view, setView] = useState("list");
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [bulkActions, setBulkActions] = useState({ open: false, value: '0' })
  const [action, setAction] = useState({ open: false, action: "0" });
  const [selectedRatings, setSelectedRatings] = useState<string[]>([]);
  const [labelVisible, setLabelVisible] = useState({
    state: true,
    city: true,
    rating: true,
  });
  const [moreFilters, setMoreFilters] = useState({
    open: false,
    selectedFilters: [],
  });
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({
    page: 1,
    rowsPerPage: 10,
  });
  const [states, setStates] = useState<{
    filteredList: { state: string; count: number }[] | null;
    search: string;
    selectedList: string[] | null;
  }>({
    filteredList: statesList,
    search: "",
    selectedList: [],
  });
  const [cities, setCities] = useState<{
    filteredList: { city: string; count: number }[] | null;
    search: string;
    selectedList: string[] | null;
  }>({
    filteredList: citiesList,
    search: "",
    selectedList: [],
  });
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        getListings({
          page: pagination.page,
          limit: pagination.rowsPerPage,
          state: states.selectedList?.join(","),
          city: cities.selectedList?.join(","),
        })
      );
    }, 800);

    setStates({ ...states, filteredList: statesList });
    setCities({ ...cities, filteredList: citiesList });
  }, [pagination, states.selectedList, cities.selectedList]);

  useEffect(() => {
    if (states.search)
      setStates({
        ...states,
        filteredList: statesList
          ? statesList?.filter((item) =>
              item.state.toLowerCase().includes(states.search.toLowerCase())
            )
          : null,
      });
    else
      setStates({
        ...states,
        filteredList: statesList,
      });
  }, [statesList, states.search]);

  useEffect(() => {
    if (cities.search)
      setCities({
        ...cities,
        filteredList: citiesList
          ? citiesList?.filter((item) =>
              item.city.toLowerCase().includes(cities.search.toLowerCase())
            )
          : null,
      });
    else
      setCities({
        ...cities,
        filteredList: citiesList,
      });
  }, [citiesList, cities.search]);

  useEffect(() => {
    setListings(initialListings);
  }, [initialListings]);

  const handleRequestSort = (property: keyof BusinessProfile) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setListings(
      listings
        ? listings.map((data) => {
            return { ...data, checked: event.target.checked };
          })
        : null
    );
    if (event.target.checked) {
      setSelectedProfiles(listings && null);
    } else setSelectedProfiles([]);
  };

  const onSelectPost = (
    event: React.ChangeEvent<HTMLInputElement>,
    selected: BusinessProfile
  ) => {
    setListings(
      listings
        ? listings.map((data) => {
            if (data.id === selected.id) {
              return { ...data, checked: event.target.checked };
            }
            return data;
          })
        : null
    );
    setSelectedProfiles((prevSelected) => {
      if (prevSelected?.some((data) => data.id === selected.id)) {
        return prevSelected.filter((data) => data.id !== selected.id);
      } else {
        return prevSelected ? [...prevSelected, selected] : [selected];
      }
    });
  };

  // const filteredListings = listings?.filter(
  //   (row) =>
  //     row.businessProfile?.title
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase()) ||
  //     row.businessProfile?.toLowerCase().includes(searchText.toLowerCase())
  // );

  // const sortedListings = filteredListings
  //   ? [...filteredListings].sort((a, b) => {
  //       const isAsc = order === "asc";
  //       if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
  //       if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
  //       return 0;
  //     })
  //   : null;

  // const onClose = () => setBulkActions({ open: false, value: '0' })
  const onCloseMoreFilters = () =>
    setMoreFilters({ ...moreFilters, open: false });

  // const getComponent = (value: any) => {
  //   switch (value) {
  //     case 1:
  //       return <CreateNewPostModal onClose={onClose} />
  //     case 2:
  //       return <UploadPhotosOrVideosModal onClose={onClose} />
  //     case 3:
  //       return <UpdateDescriptionModal onClose={onClose} />
  //     case 4:
  //       return <UpdateWebsiteModal onClose={onClose} />
  //     case 5:
  //       return <UpdateWorkingHoursModal onClose={onClose} />
  //     case 6:
  //       return <UpdatePrimaryCategoryModal onClose={onClose} />
  //     default:
  //       return <Box></Box>
  //   }
  // }

  const onChangeBulkActions = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAction({ action: event.target.value, open: true });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  return (
    <Box width="100%" p={3} sx={{ boxSizing: "border-box", textAlign: "left" }}>
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        <Box sx={{ ...flexRowCenterCenter }}>
          <Typography variant="h3" pr={1} sx={{ whiteSpace: "nowrap" }}>
            Business Profile
          </Typography>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              viewType: string
            ) => setView(viewType)}
            size="small"
          >
            <ToggleButton value="list">
              <img src={list} alt="list" />
            </ToggleButton>
            <ToggleButton value="tile">
              <img src={tile} alt="tile" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Select
          placeholder="Bulk Actions"
          sx={{
            minWidth: 175,
            "& .MuiSelect-select": {
              fontSize: "14px",
              fontWeight: 500,
            },
            "&:after": {
              content: '""',
              position: "absolute",
              right: "30px",
              top: "50%",
              transform: "translateY(-50%)",
              width: "1px",
              height: "40px",
              backgroundColor: "#c5c5c5",
              zIndex: 1,
            },
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <img src={category} alt="category" />
                </InputAdornment>
              ),
            },
          }}
          defaultValue={action.action}
          value={action.action}
          onChange={onChangeBulkActions}
        >
          <MenuItem color="primary" value="0" disabled sx={{ display: "none" }}>
            Bulk Actions
          </MenuItem>
          {options.map((option: any, index) => (
            <MenuItem
              key={option.id}
              color="primary"
              value={option.id}
              sx={(theme) => ({
                minWidth: 200,
                fontWeight: 400,
                fontSize: "12px",
                borderBottom:
                  options.length - 1 !== index
                    ? `1px solid ${theme.palette.grey["200"]}`
                    : null,
              })}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", pb: 1.5 }}>
        <Searchbar
          placeholder="Search Locations, Listing, Users"
          sx={{ minWidth: 100, width: 350 }}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
      </Box>
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        <Box sx={{ ...flexRowCenterCenter }}>
          <Select
            defaultValue="All"
            slotProps={{
              inputLabel: {
                shrink: true,
                style: { color: "#424242" },
              },
            }}
            label="Batch"
          >
            <MenuItem value="All">All</MenuItem>
          </Select>
          <Select
            defaultValue="verified"
            slotProps={{
              inputLabel: {
                shrink: true,
                style: { color: "#424242" },
              },
              select: {
                IconComponent: (props) => (
                  <DownIcon {...props} style={{ width: 20, fontWeight: 100 }} />
                ),
              },
            }}
            label="Status"
          >
            <MenuItem value="verified">Verified (1096)</MenuItem>
          </Select>
        </Box>
        <Box
          gap={2}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            minWidth: 300,
            overflowX: "scroll",
          }}
        >
          <DropDown
            multiple
            label="Select State"
            labelVisible={labelVisible.state}
            variant="outlined"
            value={states.selectedList ? states.selectedList : []}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              if (value && Array.isArray(value) && value.includes("search")) {
                event.stopPropagation();
                return;
              }
              const newValue: any =
                typeof value === "string" ? value.split(",") : value;
              setStates({ ...states, selectedList: newValue });
              setLabelVisible({
                ...labelVisible,
                state: newValue.length === 0,
              });
            }}
            onKeyDownCapture={(e) => e.stopPropagation()}
            renderValue={(selected: any) => {
              const displayValue = selected
                .filter((val: any) => val !== "search")
                .join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "100%",
                  }}
                >
                  {displayValue}
                </span>
              );
            }}
          >
            <MenuItem
              value="search"
              onClick={(e) => e.stopPropagation()}
              sx={(theme) => ({
                position: "sticky",
                top: 0,
                zIndex: 10,
                backgroundColor: theme.palette.secondary.main,
              })}
            >
              <Searchbar
                value={states.search}
                onChange={(e) => {
                  setStates({ ...states, search: e.target.value });
                }}
                sx={{ m: 0 }}
              />
            </MenuItem>
            {states?.filteredList?.map((state) => (
              <MenuItem key={state.state} value={state.state}>
                <Checkbox
                  checked={
                    states?.selectedList
                      ? states?.selectedList?.indexOf(state.state) > -1
                      : undefined
                  }
                />
                <ListItemText
                  primary={
                    <Tooltip
                      title={
                        state.state[0].toUpperCase() +
                        state.state.slice(1).toLowerCase()
                      }
                    >
                      <Typography>
                        {state.state[0].toUpperCase() +
                          state.state.slice(1).toLowerCase()}
                      </Typography>
                    </Tooltip>
                  }
                  secondary={`${state.count} Locations`}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "14px",
                      fontWeight: 500,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "12px", color: "gray" },
                  }}
                />
              </MenuItem>
            ))}
          </DropDown>
          <DropDown
            multiple
            label="Select City"
            labelVisible={labelVisible.city}
            variant="outlined"
            value={cities.selectedList ? cities.selectedList : []}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              if (value && Array.isArray(value) && value.includes("search")) {
                event.stopPropagation();
                return;
              }
              const newValue: any =
                typeof value === "string" ? value.split(",") : value;
              setCities({ ...cities, selectedList: newValue });
              setLabelVisible({ ...labelVisible, city: newValue.length === 0 });
            }}
            onKeyDownCapture={(e) => e.stopPropagation()}
            renderValue={(selected: any) => {
              const displayValue = selected
                .filter((val: any) => val !== "search")
                .join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "100%",
                  }}
                >
                  {displayValue}
                </span>
              );
            }}
          >
            <MenuItem
              value="search"
              sx={(theme) => ({
                position: "sticky",
                top: 0,
                zIndex: 10,
                backgroundColor: theme.palette.secondary.main,
              })}
            >
              <Searchbar
                value={cities.search}
                onChange={(e) => {
                  setCities({ ...cities, search: e.target.value });
                }}
                sx={{ mr: 1 }}
              />
            </MenuItem>
            {cities.filteredList?.map((city) => (
              <MenuItem key={city.city} value={city.city}>
                <Checkbox
                  checked={
                    cities?.selectedList
                      ? cities?.selectedList?.indexOf(city.city) > -1
                      : undefined
                  }
                />
                <ListItemText
                  primary={
                    <Tooltip
                      title={
                        city.city[0].toUpperCase() +
                        city.city.slice(1).toLowerCase()
                      }
                    >
                      <Typography>
                        {city.city[0].toUpperCase() +
                          city.city.slice(1).toLowerCase()}
                      </Typography>
                    </Tooltip>
                  }
                  secondary={`${city.count} Locations`}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "14px",
                      fontWeight: 500,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  secondaryTypographyProps={{
                    sx: { fontSize: "12px", color: "gray" },
                  }}
                />
              </MenuItem>
            ))}
          </DropDown>
          <DropDown
            multiple
            label="Select Rating"
            labelVisible={labelVisible.rating}
            variant="outlined"
            value={selectedRatings}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              const newValue: any =
                typeof value === "string" ? value.split(",") : value;
              setSelectedRatings(newValue);
              setLabelVisible({
                ...labelVisible,
                rating: newValue.length === 0,
              });
            }}
            renderValue={(selected: any) => {
              const displayValue = selected.join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "100%",
                  }}
                >
                  {displayValue}
                </span>
              );
            }}
          >
            {ratings.map((rating) => (
              <MenuItem key={rating.id} value={rating.number}>
                {/* <Checkbox
                  checked={selectedCities.indexOf(rating.number) > -1}
                /> */}
                <ListItemText
                  primary={rating.number}
                  primaryTypographyProps={{
                    style: { fontSize: "14px", fontWeight: 400 },
                  }}
                />
              </MenuItem>
            ))}
          </DropDown>
          <Button
            sx={{ borderRadius: "4px", whiteSpace: "nowrap", fontWeight: 400 }}
            onClick={() => setMoreFilters({ ...moreFilters, open: true })}
          >
            More Filters
          </Button>
        </Box>
      </Box>
      {view === "list" ? (
        <Box>
          {selectedProfiles ? (
            selectedProfiles.length > 0 ? (
              <Typography variant="body2" sx={{ fontWeight: 600, pb: 0.5 }}>
                {selectedProfiles.length} Profiles Selected
              </Typography>
            ) : null
          ) : null}
          <TableContainer
            sx={{
              "& .MuiTableCell-root": {
                color: "#222124",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#F6F6F6",
                  }}
                >
                  <TableCell>
                    <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  </TableCell>
                  <TableCell color="primary" sx={{ whiteSpace: "nowrap" }}>
                    Business Id
                  </TableCell>
                  <TableCell color="primary" sx={{ whiteSpace: "nowrap" }}>
                    Business Profile
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "profileScore" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "profileScore"}
                      direction={orderBy === "profileScore" ? order : "asc"}
                      onClick={() => handleRequestSort("profileScore")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Profile Score
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "status" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? order : "asc"}
                      onClick={() => handleRequestSort("status")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Status
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "avgRating" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "avgRating"}
                      direction={orderBy === "avgRating" ? order : "asc"}
                      onClick={() => handleRequestSort("avgRating")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Avg. Rating
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "reviewCount" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "reviewCount"}
                      direction={orderBy === "reviewCount" ? order : "asc"}
                      onClick={() => handleRequestSort("reviewCount")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Review Count
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "reviewGrowth" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "reviewGrowth"}
                      direction={orderBy === "reviewGrowth" ? order : "asc"}
                      onClick={() => handleRequestSort("reviewGrowth")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Review Growth
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "sync" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "sync"}
                      direction={orderBy === "sync" ? order : "asc"}
                      onClick={() => handleRequestSort("sync")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Sync
                    </CustomTableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listings?.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={row.checked}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onSelectPost(event, row)}
                      />
                    </TableCell>
                    <TableCell>{row?.id}</TableCell>
                    <TableCell
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate(`/business-profile/${row.id}`)}
                    >
                      <Tooltip title={row.title}>
                        <Typography
                          variant="body2"
                          sx={() => ({
                            color: "#1C35D4",
                            fontWeight: 700,
                            maxWidth: 250,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          })}
                        >
                          {row.title}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={row.addressLines}>
                        <Typography
                          variant="body2"
                          sx={{
                            maxWidth: 250,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {row.addressLines}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Typography
                        variant="body2"
                        sx={(theme) => ({
                          color:
                            getHealthStatus(row.score) === "Poor"
                              ? theme.palette.error.main
                              : theme.palette.success.main,
                        })}
                      >
                        {getHealthStatus(row.score)} ({row.score}
                        %)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={() => ({
                        textAlign: "center",
                        textTransform: "capitalize",
                      })}
                    >
                      <Typography
                        variant="body2"
                        sx={(theme) => ({
                          color:
                            row.openInfo === "OPEN"
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                        })}
                      >
                        {row.openInfo === "OPEN" ? "Verified" : "Unverified"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.averageRating}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.totalReviewCount}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box sx={{ ...flexRowCenterCenter }}>
                        <img
                          src={row.reviewGrowth >= 0 ? arrowUp : arrowDown}
                          alt="arrow"
                        />
                        <Typography
                          variant="body2"
                          sx={(theme) => ({
                            pl: 0.3,
                            color:
                              row.reviewGrowth >= 0
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                          })}
                        >
                          {row.reviewGrowth}%
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Chip label={row.sync} sx={{ minWidth: 94 }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            gap={2}
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              flexWrap: "wrap",
              pt: 4,
            }}
          >
            <Box
              gap={2}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <DropDown
                label="Rows per rage"
                width={100}
                onChange={(e: any) =>
                  setPagination({ ...pagination, rowsPerPage: e.target.value })
                }
                value={pagination.rowsPerPage}
              >
                {[10, 50, 100].map((rows, index) => (
                  <MenuItem
                    value={rows}
                    key={index}
                    sx={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    {rows}
                  </MenuItem>
                ))}
              </DropDown>
              {count && (
                <Typography variant="body2">
                  Showing{" "}
                  {count > pagination?.rowsPerPage
                    ? pagination?.rowsPerPage
                    : count}{" "}
                  of {count}{" "}
                </Typography>
              )}
            </Box>

            <Pagination
              count={count ? Math.round(count / pagination?.rowsPerPage) : 0}
              variant="outlined"
              shape="rounded"
              page={pagination?.page}
              onChange={handleChangePage}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#222124",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  color: "#1976d2",
                  borderColor: "#1976d2",
                  backgroundColor: "#ffffff",
                  fontWeight: 500,
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          gap={3}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {listings?.map((post: any, index: number) => (
            <Box
              key={index}
              onClick={() => navigate(`/business-profile/${post.id}`)}
              sx={(theme) => ({
                maxHeight: 280,
                minWidth: 330,
                overflow: "hidden",
                border: `1px solid ${theme.palette.grey["200"]}`,
                borderRadius: "8px",
                cursor: "pointer",
              })}
            >
              <img
                src={list}
                alt="post-image"
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  height: 108,
                  paddingBottom: "12px",
                }}
              />
              <Avatar
                sx={{
                  width: 80,
                  height: 80,
                  position: "relative",
                  left: 60,
                  top: -69,
                  transform: "translateX(-50%)",
                  border: "1px solid #DDDDDD",
                  backgroundColor: "#fff",
                }}
              >
                <img alt="Business Logo" src={list} width={63} />
              </Avatar>
              <Box sx={{ position: "relative", top: -69, p: 3, pt: 0 }}>
                <Tooltip title={post.title}>
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: 290,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {post.title}
                  </Typography>
                </Tooltip>{" "}
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                    pb: 1,
                  }}
                >
                  <Typography variant="caption">
                    {" "}
                    {post.averageRating}{" "}
                  </Typography>
                  <Rating
                    value={post.averageRating}
                    precision={0.1}
                    readOnly
                    sx={{ pl: 0.5, pr: 0.5 }}
                  />
                  <Dot />
                  <Typography
                    variant="caption"
                    color="textDisabled"
                    sx={{ ml: 1 }}
                  >
                    {" "}
                    {post.totalReviewCount} Reviews{" "}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ pr: 3 }}>
                    <Typography
                      variant="caption"
                      sx={{ pb: 1, fontWeight: 600 }}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textDisabled"
                      sx={{
                        pb: 1,
                        display: "block",
                        maxWidth: 178,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {post.addressLines}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ pb: 1, fontWeight: 600 }}
                    >
                      Profile Score
                    </Typography>
                    <Typography
                      variant="caption"
                      color="success"
                      sx={{
                        pb: 1,
                        display: "block",
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {getHealthStatus(post.score)} {post.score}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {/* <Modal open={bulkActions.open} onClose={onClose}>{getComponent(bulkActions.value)}</Modal> */}
      <Modal
        open={action.open}
        onClose={() => setAction({ action: "0", open: false })}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) =>
            setAction({ action, open })
          }
          action={action.action}
        />
      </Modal>
      <Modal open={moreFilters.open} onClose={onCloseMoreFilters}>
        <MoreFiltersModal onClose={onCloseMoreFilters} />
      </Modal>
    </Box>
  );
};

export default BusinessProfiles;
