import styled from "@emotion/styled";
import {
  Box,
  FormControlLabel,
  Typography,
  TextField as TextInput,
  Chip,
  RadioGroup,
} from "@mui/material";
import Checkbox from "components/Checkbox";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import plus from "assets/icons/add-black.svg";
import checkedRound from "assets/icons/checked-round.svg";
import Radio from "components/Radio";
import {
  BusinessProfile,
  ProfileTab,
  Section,
} from "interfaces/BusinessProfiles.types";
import { Map, WorkingHours } from "../Components";

export const StyledTextField = styled(TextInput)(() => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#f8f1f182",
  },
  "& .MuiFilledInput-root::before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-root::after": {
    borderBottom: "none",
  },
  "& .MuiInputBase-input": {
    fontWeight: 400,
    fontSize: "14px",
    p: "0px !important",
  },
  "& .MuiFilledInput-input": {
    padding: "0px !important",
  },
  "& .MuiInputBase-root": {
    padding: "4px 8px!important",
  },
}));

type Step = {
  id: number;
  label: string;
  subLabel: string;
};

type AttributesData = {
  id?: number;
  title?: string;
  description?: string;
  options?: {
    value?: string;
    selectedValue?: string;
  }[];
};

const steps: Step[] = [
  { id: 1, label: "Accessibility", subLabel: "Select product" },
  { id: 2, label: "Amenities", subLabel: "Select type" },
  { id: 3, label: "Crowd", subLabel: "Select topic" },
  { id: 4, label: "From the business", subLabel: "Select date & time" },
  { id: 5, label: "Planning", subLabel: "Select HCP" },
  { id: 6, label: "Payments", subLabel: "Enter location for engagement" },
  { id: 7, label: "Offering", subLabel: "Invite speaker" },
  { id: 8, label: "Recycling", subLabel: "Select HCP" },
];

const initialAttributesData = [
  {
    id: 1,
    title: "Accessibility",
    description:
      "Accessiblity ensures your business is easy to access for everyone, including those with disabilities.",
    options: [
      {
        value: "Wheel Chair accessible elevator",
        selectedValue: "",
      },
      {
        value: "Wheel Chair accessible Parking Lot",
        selectedValue: "",
      },
      {
        value: "Wheel Chair accessible Restroom",
        selectedValue: "",
      },
      { value: "Wheel Chair accessible seating", selectedValue: "" },
    ],
  },
  {
    id: 2,
    title: "Amenities",
    description:
      "Amenities are additional features or services provided by your business to enhance customer experience",
    options: [
      { value: "Gender Neutral Restroom", selectedValue: "" },
      { value: "WIFI", selectedValue: "" },
      { value: "Wheel Chair accessible Restroom", selectedValue: "" },
      { value: "Wheel Chair accessible seating", selectedValue: "" },
    ],
  },
  {
    id: 3,
    title: "Crowd",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services",
    options: [{ value: "LGBTQ+ friendly", selectedValue: "" }],
  },
  {
    id: 4,
    title: "From the business",
    description: `Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services. If you're not the owner, confirm with the owner before adding an attribute.`,
    options: [{ value: "Identifies as women-owned", selectedValue: "" }],
  },
  {
    id: 5,
    title: "Planning",
    description:
      "Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.",
    options: [{ value: "Appointment required", selectedValue: "" }],
  },
  {
    id: 6,
    title: "Payments",
  },
  {
    id: 7,
    title: "Offering",
  },
  {
    id: 8,
    title: "Recycling",
  },
];

const Profile: React.FC<{
  selectedBusinessProfiles: BusinessProfile[];
  selectedRef?: Section;
}> = forwardRef(({ selectedBusinessProfiles, selectedRef }, ref) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>("100vh");

  useEffect(() => {
    if (tabsRef.current) {
      const tabsHeight = tabsRef.current.scrollHeight || 0;
      const calculatedHeight = `calc(100vh - 217px - ${tabsHeight}px)`;
      setHeight(calculatedHeight);
    }
  }, [tabsRef?.current?.scrollHeight]);

  useEffect(() => {
    if (selectedRef) {
      const sectionRef = sectionRefs[selectedRef].current;
      if (sectionRef) {
        sectionRef.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedRef]);

  const profile =
    selectedBusinessProfiles.length === 1 ? selectedBusinessProfiles[0] : null;
  const [activeAttribute, setActiveAttribute] = useState(1);
  const [attributesData, setAttributesData] = useState<AttributesData[]>(
    initialAttributesData
  );
  const selectedAtrributeData = attributesData.filter(
    (data) => data.id === activeAttribute
  )[0];
  const [profileTabs, setProfileTabs] = useState<ProfileTab[]>([
    { id: 1, name: "about", isActive: true },
    { id: 2, name: "contact", isActive: false },
    { id: 3, name: "location", isActive: false },
    { id: 4, name: "hours", isActive: false },
    { id: 5, name: "attributes", isActive: false },
  ]);
  const sectionRefs = {
    about: useRef<HTMLDivElement>(null),
    contact: useRef<HTMLDivElement>(null),
    location: useRef<HTMLDivElement>(null),
    hours: useRef<HTMLDivElement>(null),
    attributes: useRef<HTMLDivElement>(null),
  };
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  console.log(selectedPlace, setSelectedPlace);
  const scrollToSection = (currentProfileTab: ProfileTab) => {
    const sectionRef = sectionRefs[currentProfileTab.name as Section].current;
    if (sectionRef) {
      sectionRef.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setProfileTabs(
      profileTabs.map((profileTab) => ({
        ...profileTab,
        isActive: profileTab.id === currentProfileTab.id,
      }))
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setProfileTabs(
              profileTabs.map((profileTab) => ({
                ...profileTab,
                isActive: profileTab.name === entry.target.id,
              }))
            );
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Box ref={ref}>
      <Box
        ref={tabsRef}
        sx={(theme) => ({
          position: "sticky",
          top: -1,
          backgroundColor: theme.palette.secondary.main,
          zIndex: 10,
          p: 2,
          pb: 0,
        })}
      >
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Business Information
        </Typography>
        <Box
          sx={(theme) => ({
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
            pt: 1,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          {profileTabs.map((tab) => (
            <Typography
              component="a"
              key={tab.id}
              variant="body2"
              sx={(theme) => ({
                textDecoration: "none",
                textTransform: "capitalize",
                borderBottom: tab.isActive ? "4px solid #440BFF" : "",
                mr: 2,
                p: 1,
                cursor: "pointer",
                boxSizing: "border-box",
                fontWeight: 400,
                color: tab.isActive
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
              })}
              onClick={() => scrollToSection(tab)}
            >
              {tab.name}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box
        px={2}
        sx={{
          maxHeight: height,
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <Box
          id="about"
          ref={sectionRefs.about}
          sx={(theme) => ({
            py: 2,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <Typography variant="body1" sx={{ pb: 1 }}>
            About your business
          </Typography>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">Business Name</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={(theme) => ({ color: theme.palette.grey["300"] })}
                />
              }
              label="Add additional information"
              sx={() => ({
                mr: 0,
                "& .MuiTypography-root": {
                  fontSize: "14px",
                  fontWeight: 200,
                },
              })}
            />
          </Box>
          <StyledTextField
            variant="filled"
            value={profile?.name}
            fullWidth
            multiline
          />
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Primary Business Category
          </Typography>
          <StyledTextField
            variant="filled"
            fullWidth
            multiline
            minRows={2}
            sx={{
              backgroundColor: "rgba(248, 241, 241, 0.51)",
              "& .MuiFilledInput-root::before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-root::after": {
                borderBottom: "none",
              },
              "& .MuiInputBase-input": {
                fontWeight: 400,
                p: 1,
              },
            }}
          />
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Typography variant="body2">Business Description</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={(theme) => ({ color: theme.palette.grey["300"] })}
                />
              }
              label="Add additional information"
              sx={() => ({
                "& .MuiTypography-root": {
                  fontSize: "14px",
                  fontWeight: 200,
                },
              })}
            />
          </Box>
          <StyledTextField
            variant="filled"
            value={profile?.description}
            fullWidth
            multiline
            minRows={5}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="caption">{}/750</Typography>
          </Box>
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Opening Date
          </Typography>
          <StyledTextField variant="filled" type="date" />
        </Box>
        <Box
          id="contact"
          ref={sectionRefs.contact}
          sx={(theme) => ({
            py: 2,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <Typography variant="body1" sx={{ pb: 2 }}>
            Contact Information
          </Typography>
          <Typography variant="body2" sx={{ pb: 1 }}>
            Phone No
          </Typography>
          <StyledTextField variant="filled" value={profile?.phone} fullWidth />
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Website
          </Typography>
          <StyledTextField
            variant="filled"
            value={profile?.website}
            fullWidth
          />
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Short Name
          </Typography>
          <StyledTextField variant="filled" fullWidth />
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Menu Link
          </Typography>
          <StyledTextField variant="filled" fullWidth />
        </Box>
        <Box
          id="location"
          ref={sectionRefs.location}
          sx={(theme) => ({
            py: 2,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <Typography variant="body1" sx={{ pb: 2 }}>
            Location and areas
          </Typography>
          {selectedBusinessProfiles.length === 1 ? (
            <>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Business Location
              </Typography>
              <Map />
            </>
          ) : (
            <Typography variant="body2" fontWeight={400}>
              Select individual location to view or edit business location
              Service area and map
            </Typography>
          )}
        </Box>
        <Box
          id="hours"
          ref={sectionRefs.hours}
          sx={(theme) => ({
            py: 2,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          })}
        >
          <Typography variant="body1" sx={{ pb: 1 }}>
            Opening Hours
          </Typography>
          <WorkingHours />
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Special Hours
          </Typography>
          <StyledTextField variant="filled" fullWidth />
          <Typography variant="body2" sx={{ pt: 2, pb: 1 }}>
            Add more hours
          </Typography>
          <Box gap={1} sx={{ display: "flex" }}>
            {["Breakfast", "Lunch", "Dinner"].map((label, index) => (
              <Chip
                key={index}
                label={label}
                avatar={
                  <img
                    src={plus}
                    alt="plus"
                    style={{ width: 16, height: 16 }}
                  />
                }
                sx={(theme) => ({
                  fontSize: "14px",
                  fontWeight: 500,
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.palette.grey["500"]}`,
                })}
              />
            ))}
          </Box>
        </Box>
        <Box
          id="attributes"
          ref={sectionRefs.attributes}
          sx={() => ({ py: 2 })}
        >
          <Typography variant="body1" sx={{ pb: 1 }}>
            Attributes
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.grey["200"]}`,
                p: 2,
                pb: 0,
              })}
            >
              {steps.map((step, index) => {
                const isActive = activeAttribute === step.id;
                const isChecked = attributesData[step.id - 1]?.options?.every(
                  (item) => item.selectedValue
                );
                return (
                  <Box
                    key={step.id}
                    onClick={() => setActiveAttribute(step.id)}
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                      position: "relative",
                      mb: 2,
                      cursor: "pointer",
                    }}
                  >
                    {isChecked ? (
                      <Box
                        sx={{
                          ...flexRowCenterCenter,
                          boxSizing: "border-box",
                          height: "34px",
                          minHeight: 34,
                          maxHeight: 34,
                          width: "34px",
                          minWidth: 34,
                          maxWidth: 34,
                        }}
                      >
                        <img src={checkedRound} alt="checked" sizes="24px" />
                      </Box>
                    ) : (
                      <Box
                        sx={(theme) => ({
                          ...flexRowCenterCenter,
                          height: "24px",
                          minHeight: 24,
                          maxHeight: 24,
                          width: "24px",
                          minWidth: 24,
                          maxWidth: 24,
                          border: `5px solid ${theme.palette.secondary.main}`,
                          borderRadius: "50%",
                          backgroundColor: isActive
                            ? "#1650CF"
                            : theme.palette.grey["300"],
                          color: !isActive
                            ? theme.palette.text.disabled
                            : theme.palette.secondary.main,
                          fontWeight: 600,
                          zIndex: 1,
                          fontSize: "14px",
                        })}
                      >
                        {step.id}
                      </Box>
                    )}
                    <Box
                      ml={2}
                      sx={{
                        maxWidth: 144,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color={isActive ? "primary" : "textDisabled"}
                        sx={{ fontWeight: 600 }}
                      >
                        {step.label}
                      </Typography>
                      <Typography variant="caption" color="textDisabled">
                        {step.subLabel}
                      </Typography>
                    </Box>
                    {steps.length - 1 !== index ? (
                      <Box
                        sx={{
                          position: "absolute",
                          width: "1px",
                          height: "100%",
                          backgroundColor: "lightgray",
                          top: "38px",
                          left: 17,
                          zIndex: 0,
                        }}
                      ></Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
            {selectedAtrributeData ? (
              <Box py={1.5} px={3}>
                <Typography variant="body1" sx={{ fontWeight: 600, pb: 0.5 }}>
                  {selectedAtrributeData.title}
                </Typography>
                <Typography variant="caption" color="textDisabled">
                  {selectedAtrributeData.description
                    ? selectedAtrributeData.description
                    : "No data available"}
                </Typography>
                {selectedAtrributeData?.options?.map((option, index) => (
                  <Box key={index} sx={{ pt: 2 }}>
                    <Typography variant="body2" sx={{ pb: 0.5 }}>
                      {option.value}
                    </Typography>
                    <RadioGroup
                      name="radio-buttons-group"
                      row
                      value={option.selectedValue}
                      onChange={(event) => {
                        const newData = attributesData.map((data) => {
                          if (data.id === selectedAtrributeData.id) {
                            return {
                              ...data,
                              options: data.options?.map((dataOption) => {
                                if (dataOption.value === option.value) {
                                  return {
                                    ...dataOption,
                                    selectedValue: event.target.value,
                                  };
                                }
                                return dataOption;
                              }),
                            };
                          }
                          return data;
                        });
                        setAttributesData(newData);
                      }}
                    >
                      <FormControlLabel
                        sx={(theme) => ({
                          color: theme.palette.text.disabled,
                          "& .MuiTypography-root": {
                            fontSize: "12px",
                            fontWeight: 400,
                          },
                        })}
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        sx={(theme) => ({
                          color: theme.palette.text.disabled,
                          "& .MuiTypography-root": {
                            fontSize: "12px",
                            fontWeight: 400,
                          },
                        })}
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Profile;
