import {
  Avatar,
  Box,
  Chip,
  Grid2,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import nike from "assets/images/nike.png";
import addPhoto from "assets/icons/add-photo.svg";
import shop from "assets/icons/shop.svg";
import mapPin from "assets/icons/address.svg";
import call from "assets/icons/call.svg";
import category from "assets/icons/category.svg";
import description from "assets/icons/description-overview.svg";
import website from "assets/icons/website.svg";
import clock from "assets/icons/clock.svg";
// import star from "assets/icons/star.svg";
import search from "assets/icons/search-black.svg";
import bell from "assets/icons/notification-black.svg";
import google from "assets/icons/google.svg";
import boost from "assets/icons/boost.svg";
import tick from "assets/icons/tick-circle-solid.svg";
import arrowDownRed from "assets/icons/arrow-down-red.svg";
import { flexRowCenterCenter } from "utils/styles";
import { CircularProgressWithBufferProps } from "interfaces/Components.types";
import { Button } from "components/Buttons";
import { ReviewsCard } from "pages/Insights/Reviews";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import { Section } from "interfaces/BusinessProfiles.types";
import pencil from "assets/icons/pencil.svg";
import { useSelector } from "react-redux";
import { RootState } from "store";

// const profile = {
//   id: "5102384584759847987",
//   name: "Nike Store",
//   address: "Bata Store, G7 Fortune Empire, Jubilee Hills Road",
//   city: "Chennai",
//   state: "Tamil Nadu",
//   phone: "8668881010",
//   description:
//     "Stylish and comfortable footwear for the whole family. Explore our quality-crafted collections featuring the latest trends and timeless classics",
//   website: "www.bata.in",
// };

const Overview: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const [coverPhoto, setCoverPhoto] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCoverPhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box width="100%">
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, lg: 8 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              margin: "auto",
              textAlign: "center",
              height: "calc(100% - 150px)",
              border: "1px solid #E6E6E6",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                height: 200,
                backgroundColor: "#efefef",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={handleButtonClick}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {coverPhoto ? (
                  <Box
                    component="img"
                    src={coverPhoto}
                    alt="Cover Photo"
                    sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <Box>
                    <img src={addPhoto} alt="add-photo" sizes="48px" />
                    <Typography variant="body2" color="textDisabled">
                      Add Cover Photo
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleCoverPhotoChange}
            />
            <Avatar
              sx={{
                width: 200,
                height: 200,
                position: "relative",
                bottom: 150,
                left: 150,
                transform: "translateX(-50%)",
                border: "1px solid #DDDDDD",
                backgroundColor: "#fff",
              }}
            >
              <img alt="Business Logo" src={nike} width={144} />
            </Avatar>
            <Box
              sx={{
                position: "relative",
                top: -150,
                textAlign: "left",
              }}
            >
              <ListComponent isEdit={isEdit} onClose={onClose} />
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 4 }}>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              p: "32px 24px",
              mb: 3,
            })}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Profile Score
            </Typography>
            <Box sx={flexRowCenterCenter}>
              <CircularProgressWithBuffer progress={48} buffer={100 - 48} />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              p: "32px 24px",
              mb: 3,
            })}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Platforms
            </Typography>
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <Box sx={flexRowCenterCenter}>
                <img src={google} alt="google" sizes="24px" />
                <Typography variant="body1" sx={{ pl: 1 }}>
                  Google
                </Typography>
              </Box>
              <img src={tick} alt="tick" sizes="20px" />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              p: "32px 24px",
              mb: 3,
            })}
          >
            <ReviewsCard />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Overview;

// const workingHours = [
//   { week: "Monday", time: "10:00 AM - 11:00 PM", checked: true },
//   { week: "Tuesday", time: "10:00 AM - 11:00 PM", checked: true },
//   { week: "Wednesday", time: "10:00 AM - 11:00 PM", checked: true },
//   { week: "Thursday", time: "10:00 AM - 11:00 PM", checked: true },
//   { week: "Friday", time: "10:00 AM - 11:00 PM", checked: true },
//   { week: "Saturday", time: "10:00 AM - 11:00 PM", checked: true },
//   { week: "Sunday", time: "Closed", checked: false },
// ];

const WorkingHoursComponent: React.FC<{ listing: any }> = ({ listing }) => (
  <>
    {listing?.overView?.hours.map((item: any, index: number) => (
      <Box
        key={index}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "flex-start",
          my: 1.5,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            minWidth: 80,
            pr: 4,
          }}
        >
          {item.openDay[0].toUpperCase() + item.openDay.slice(1).toLowerCase()}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, pr: 4, textAlign: "left", minWidth: 172 }}
        >
          {item.openTime.hours}:{item.openTime.minutes} - {item.closeTime.hours}
          :{item.closeTime.minutes}
        </Typography>
      </Box>
    ))}
  </>
);

type ListComponentProps = {
  icon: string;
  title: string | React.ReactNode;
  description: React.ReactNode;
  isEdit: boolean;
  sectionRef: Section;
};

const initialListData = (listing: any) => [
  {
    icon: shop,
    title: <Typography variant="h5">Business Name</Typography>,
    description: (
      <>
        <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
          {listing?.overView?.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h6" sx={{ pr: 1 }}>
            2.1
          </Typography>
          <Rating value={2.1} precision={0.1} readOnly />
        </Box>
      </>
    ),
    isEdit: false,
    sectionRef: "about" as Section,
  },
  {
    icon: mapPin,
    title: "Address",
    description: (
      <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
        {listing?.overView?.addressLines}
      </Typography>
    ),
    isEdit: false,
    sectionRef: "location" as Section,
  },
  {
    icon: call,
    title: "Contact",
    description: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Box pr={5}>
          <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
            Primary number
          </Typography>
          <Typography variant="body1">
            {listing?.overView?.phoneNumbers?.split(",")[0]}
          </Typography>
        </Box>
        {listing?.overView?.phoneNumbers?.split(",")[1] && (
          <Box>
            <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
              Display number
            </Typography>
            <Typography variant="body1">
              {listing?.overView?.phoneNumbers?.split(",")[1] || ""}
            </Typography>
          </Box>
        )}
      </Box>
    ),
    isEdit: false,
    sectionRef: "contact" as Section,
  },
  {
    icon: category,
    title: "Category",
    description: (
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}>
        <Typography variant="body2" color="textDisabled" sx={{ py: 1, mr: 2 }}>
          {listing?.overView?.categoriesDisplayName}
        </Typography>
        <Chip
          label="Primary"
          sx={{ backgroundColor: "#EBF1FD", color: "#1442A7", fontWeight: 600 }}
        />
      </Box>
    ),
    isEdit: false,
    sectionRef: "about" as Section,
  },
  {
    icon: description,
    title: "Description",
    description: (
      <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
        {listing?.overView?.description}
      </Typography>
    ),
    isEdit: false,
    sectionRef: "about" as Section,
  },
  {
    icon: website,
    title: "Website",
    description: (
      <Typography variant="body2" color="textDisabled" sx={{ py: 1 }}>
        {listing?.overView?.webSite}
      </Typography>
    ),
    isEdit: false,
    sectionRef: "contact" as Section,
  },
  {
    icon: clock,
    title: "Working Hours",
    description: <WorkingHoursComponent listing={listing} />,
    isEdit: false,
    sectionRef: "hours" as Section,
  },
  // {
  //   icon: star,
  //   title: "Special Hours",
  //   description: (
  //     <>
  //       <Typography variant="caption">Monday</Typography>
  //       <Typography variant="body2" sx={{ py: 1 }}>
  //         11:00 AM - 12:00 AM
  //       </Typography>
  //     </>
  //   ),
  //   isEdit: false,
  //   sectionRef: "hours" as Section,
  // },
  {
    icon: search,
    title: "Custom Store Identifier",
    description: null,
    isEdit: false,
    sectionRef: "attributes" as Section,
  },
  {
    icon: bell,
    title: "Notification contacts",
    description: null,
    isEdit: false,
    sectionRef: "attributes" as Section,
  },
];

const ListComponent: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "1" : "0",
  });
  const [selectedRef, setSelectedRef] = useState<Section>("name" as Section);
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  const [listData, setListData] = useState<ListComponentProps[] | null>(null);

  useEffect(() => {
    setListData(initialListData(listing));
  }, [listing]);

  const onHover = (item: ListComponentProps, hovered: boolean) => {
    const newListData = listData?.map((data) => {
      if (data.title === item.title) {
        return { ...item, isEdit: hovered ? true : false };
      }
      return { ...data, isEdit: false };
    });
    setListData(newListData || null);
  };

  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "1" });
  }, [isEdit]);
  return (
    <>
      {listData?.map((item: ListComponentProps, index) => {
        return (
          <Box
            key={index}
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              p: 3,
              pb: 4,
              backgroundColor: item.isEdit
                ? theme.palette.grey["100"]
                : theme.palette.secondary.main,
              cursor: "pointer",
              borderBottom:
                listData.length - 1 === index
                  ? "none"
                  : `1px solid ${theme.palette.grey["200"]}`,
            })}
            onMouseEnter={() => onHover(item, true)}
            onMouseLeave={() => onHover(item, false)}
            onClick={async () => {
              await setSelectedRef(item.sectionRef);
              setAction({ action: "1", open: true });
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: item.description ? "flex-start" : "center",
              }}
            >
              <Avatar
                sx={(theme) => ({
                  width: 40,
                  height: 40,
                  backgroundColor: theme.palette.grey["100"],
                })}
              >
                <img src={item.icon} alt={`${item.icon}`} sizes="20px" />
              </Avatar>
              <Box
                sx={() => ({
                  pl: 2,
                })}
              >
                {typeof item.title === "string" ? (
                  <Typography variant="body1">{item.title}</Typography>
                ) : (
                  item.title
                )}
                {item.description}
              </Box>
            </Box>
            {item.isEdit ? (
              <img src={pencil} alt="edit" width="20px" height="20px" />
            ) : null}
          </Box>
        );
      })}
      <Modal
        open={action.open || (isEdit && action.open ? true : false)}
        onClose={() => {
          setAction({ action: "0", open: false });
          if (isEdit && onClose) onClose();
        }}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) => {
            setAction({ action, open });
            if (onClose) onClose();
          }}
          action={action.action}
          selectedBusiness={[listing]}
          selectedRef={selectedRef}
        />
      </Modal>
    </>
  );
};

const CircularProgressWithBuffer: React.FC<CircularProgressWithBufferProps> = ({
  progress,
  buffer,
  radius = 150,
  stroke = 16.5,
  color = "#F48992",
}) => {
  const size = 330;
  const circumference = Math.PI * radius;
  const strokeDashoffset = ((1 - progress) / 100) * circumference;
  const bufferDashoffset = ((1 - buffer) / 100) * circumference;

  return (
    <Box position="relative" display="inline-flex" bottom={100}>
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size}
          r={radius}
          stroke="#CBCBCB"
          strokeWidth={stroke}
          fill="none"
        />
        <circle
          cx={size / 2}
          cy={size}
          r={radius}
          stroke="#CBCBCB"
          strokeWidth={stroke}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={bufferDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>
      <svg
        width={size}
        height={size}
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <circle
          cx={size / 2}
          cy={size}
          r={radius}
          stroke={color}
          strokeWidth={stroke}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>

      <Box
        position="absolute"
        top={320}
        left="50%"
        sx={{ transform: "translate(-50%, -50%)", textAlign: "center" }}
      >
        <Box sx={{ ...flexRowCenterCenter }}>
          <img src={arrowDownRed} alt="arrow-down-red" sizes="16px" />
          <Typography variant="body1" sx={{ color: "#E73F3F" }}>
            1.2%
          </Typography>
        </Box>
        <Typography
          sx={{ fontSize: "48px" }}
        >{`${Math.round(progress)}`}</Typography>
        <Typography variant="h5" color="textDisabled" sx={{ pb: 8 }}>
          Poor
        </Typography>
        <Button
          variant="contained"
          startIcon={<img src={boost} alt="boost" sizes="20px" />}
          sx={{ backgroundColor: "#5448CF", height: "48px", border: "none" }}
        >
          Boost Profile
        </Button>
      </Box>
    </Box>
  );
};
