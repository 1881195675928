import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface ListingsState {
  listings: any[] | null;
  count: number | null;
  loading: boolean;
  statesList: { state: string; count: number }[] | null;
  citiesList: { city: string; count: number }[] | null;
  error: string | null;
}

const initialState: ListingsState = {
  listings: null,
  count: null,
  loading: false,
  statesList: null,
  citiesList: null,
  error: null,
};

export const getListings = createAsyncThunk(
  "/location-list",
  async (
    req: { page: number; limit: number; state?: string; city?: string },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/location-list", req);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get listings"
      );
    }
  }
);

const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get listings list
      .addCase(getListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListings.fulfilled, (state, action: any) => {
        state.listings = action.payload.data;
        state.count = action.payload.totalCount;
        state.statesList = action.payload.stateFilterList;
        state.citiesList = action.payload.cityFilterList;
        state.loading = false;
      })
      .addCase(getListings.rejected, (state, action) => {
        state.listings = null;
        state.statesList = null;
        state.citiesList = null;
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default listingsSlice.reducer;
