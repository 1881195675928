import { lazy } from "react";

const unProtectedRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../pages/Login")),
  },
  {
    path: "/sign-up",
    component: lazy(() => import("../pages/SignUp")),
  },
  {
    path: "/otp-verification",
    component: lazy(() => import("../pages/OTPVerification")),
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../pages/ForgotPassword")),
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../pages/ResetPassword")),
  },
];

export default unProtectedRoutes;
