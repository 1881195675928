import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  RadioGroup,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import close from "assets/icons/close.svg";
import plusBlue from "assets/icons/plus-blue.svg";
import plus from "assets/icons/add-black.svg";
import star from "assets/icons/star-yellow.svg";
import google from "assets/icons/google.svg";
import {
  BulkReplyModalProps,
  FilterModalProps,
  IndividualReplyModalProps,
} from "interfaces/Reviews.types";
import { Button, ButtonOutline } from "components/Buttons";
import { Carousel, CreateNewTemplate } from "./Components";
import TextField from "components/TextField";
import Radio from "components/Radio";
import Checkbox from "components/Checkbox";
import ProfileImageContainer from "components/ProfileImageContainer";
import Dot from "components/Dot";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { createTemplate } from "store/reviewsSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 750,
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  maxHeight: "90%",
};

const chipsData = [
  "Name",
  "Business Address",
  "City",
  "State",
  "Pincode",
  "Area",
];

// const items = [
//   {
//     id: 1,
//     title: "Apology 1",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 2,
//     title: "Apology 2",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 3,
//     title: "Apology 3",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 4,
//     title: "Apology 4",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 5,
//     title: "Apology 5",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 6,
//     title: "Apology 6",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 7,
//     title: "Apology 7",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
// ];

export const BulkReplyModal: React.FC<BulkReplyModalProps> = forwardRef(
  ({ selectedReviews, onClose }, ref) => {
    const areReviewsSelected = selectedReviews && selectedReviews.length > 0;
    const dispatch = useDispatch<AppDispatch>();
    const [reply, setReply] = useState("");
    const [createNewTemplate, setCreateNewTemplate] = useState(false);
    const [createNewTemplateValues, setCreateNewTemplateValues] = useState({
      template_title: "",
      template_body: "",
    });

    const onClickTemplate = (item: any) => setReply(item.template_body);
    const getCreateNewTemplate = (template: {
      template_title: string;
      template_body: string;
    }) => setCreateNewTemplateValues(template);

    const submitCreateNewTemplate = () => {
      dispatch(createTemplate(createNewTemplateValues));
    };
    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          ...style,
        })}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey["100"],
            p: "20px 24px",
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          })}
        >
          {areReviewsSelected ? (
            <Box>
              <Typography variant="h5" sx={{ display: "inline" }}>
                {createNewTemplate ? "Create New Template" : "Bulk Reply"}
              </Typography>
              {!createNewTemplate ? (
                <Typography
                  variant="body2"
                  color="textDisabled"
                  sx={{ display: "inline" }}
                >
                  ({selectedReviews?.length} Reviews Selected)
                </Typography>
              ) : null}
            </Box>
          ) : (
            <Typography variant="h5" sx={{ display: "inline" }}>
              Message
            </Typography>
          )}
          <img
            src={close}
            alt="close"
            sizes="24px"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          />
        </Box>
        {areReviewsSelected ? (
          <>
            <Box
              sx={{
                overflowY: "auto",
                maxHeight: `calc(100vh - 350px)`,
                p: 3,
              }}
            >
              {createNewTemplate ? (
                <CreateNewTemplate
                  getCreateNewTemplate={getCreateNewTemplate}
                  template={createNewTemplateValues}
                />
              ) : (
                <Box>
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body1" fontWeight={600}>
                      Templates
                    </Typography>
                    <ButtonOutline
                      startIcon={<img src={plusBlue} alt="plus" />}
                      onClick={() => setCreateNewTemplate(true)}
                    >
                      Create New
                    </ButtonOutline>
                  </Box>
                  <Box sx={{ pt: 4 }}>
                    <Carousel onClickTemplate={onClickTemplate} />
                  </Box>
                  <Box sx={{ pt: 4 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
                      Reply
                    </Typography>
                    <TextField
                      placeholder="Enter reply message here..."
                      value={reply}
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => setReply(event.target.value)}
                      minRows={4}
                      fullWidth
                      variant="outlined"
                      multiline
                      sx={{ mb: 0 }}
                    />
                    <Box
                      gap={1}
                      sx={(theme) => ({
                        p: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        border: `1px solid ${theme.palette.grey["400"]}`,
                        borderTop: "none",
                        borderRadius: "0 0 8px 8px",
                        mb: 2,
                      })}
                    >
                      {chipsData.map((label, index) => (
                        <Chip
                          key={index}
                          label={label}
                          avatar={<img src={plus} alt="plus" sizes="10px" />}
                          sx={() => ({
                            fontSize: "12px",
                            fontWeight: 500,
                          })}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>

            <Divider />
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}
            >
              <Button
                sx={{ mr: 1 }}
                onClick={() =>
                  createNewTemplate ? setCreateNewTemplate(false) : onClose()
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#1650CF", border: "none" }}
                onClick={() => {
                  if (createNewTemplate) submitCreateNewTemplate();
                }}
              >
                {createNewTemplate ? "Save" : "Publish"}
              </Button>
            </Box>
          </>
        ) : (
          <Typography sx={{ p: 3 }}>
            Please select a review to respond
          </Typography>
        )}
      </Box>
    );
  }
);

export const IndividualReplyModal: React.FC<IndividualReplyModalProps> =
  forwardRef(({ selectedReview, onClose }, ref) => {
    const [reply, setReply] = useState(
      selectedReview?.reply_comment ? selectedReview?.reply_comment : ""
    );
    const [isExpanded, setIsExpanded] = useState(false);
    const [createNewTemplate, setCreateNewTemplate] = useState(false);
    const [createNewTemplateValues, setCreateNewTemplateValues] = useState({
      template_title: "",
      template_body: "",
    });
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();

    const handleToggle = () => {
      setIsExpanded((prev) => !prev);
    };

    const onClickTemplate = (item: any) => setReply(item.template_body);
    const getCreateNewTemplate = (template: {
      template_title: string;
      template_body: string;
    }) => setCreateNewTemplateValues(template);

    const submitCreateNewTemplate = () => {
      dispatch(createTemplate(createNewTemplateValues));
    };
    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          ...style,
        })}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey["100"],
            p: "20px 24px",
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          })}
        >
          <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}>
            <Typography variant="h5" sx={{ display: "inline" }}>
              {createNewTemplate ? "Create New Template" : "Reply to Review"}
            </Typography>
          </Box>
          <img
            src={close}
            alt="close"
            sizes="24px"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          />
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: `calc(100vh - 350px)`,
            maxWidth: "100%",
            boxSizing: "border-box",
            p: 3,
            pt: 3.5,
          }}
        >
          {createNewTemplate ? (
            <CreateNewTemplate
              getCreateNewTemplate={getCreateNewTemplate}
              template={createNewTemplateValues}
            />
          ) : (
            <Box width="100%">
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: 3 }}>
                  <ProfileImageContainer
                    pic={selectedReview?.reviewer_photo}
                    icon={google}
                  />
                  <Box
                    sx={{
                      width: "2px",
                      minHeight: 145,
                      backgroundColor: "lightgray",
                      my: 1,
                      ml: 2.2,
                      zIndex: 0,
                    }}
                  ></Box>
                </Box>
                <Box>
                  <Typography variant="body1">
                    {selectedReview?.reviewer_name}
                  </Typography>
                  <Tooltip title={selectedReview?.title}>
                    <Typography variant="caption" sx={{ display: "inline" }}>
                      {selectedReview?.title}
                    </Typography>
                  </Tooltip>
                  <br />
                  <Tooltip title={selectedReview?.locality}>
                    <Typography
                      variant="caption"
                      color="textDisabled"
                      sx={{ pb: 0.5 }}
                    >
                      {selectedReview?.locality}
                    </Typography>
                  </Tooltip>
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                      pt: 0.5,
                      pb: 2,
                    }}
                  >
                    <Rating
                      readOnly
                      value={parseFloat(selectedReview?.star_rating)}
                      precision={0.5}
                    />
                    <Dot />
                    <Typography variant="caption" color="textDisabled">
                      {selectedReview?.create_time
                        ? formatDistanceToNow(
                            parseISO(selectedReview?.create_time),
                            {
                              addSuffix: true,
                            }
                          )
                        : ""}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        maxHeight: isExpanded ? "none" : 80,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: isExpanded ? undefined : 3,
                        flex: 1,
                      }}
                    >
                      {isExpanded
                        ? selectedReview?.comment
                        : `${selectedReview?.comment?.substring(0, 250)}${selectedReview?.comment > 250 ? "..." : ""}`}
                      <Typography
                        variant="body2"
                        component="span"
                        onClick={handleToggle}
                        sx={{
                          ml: 1,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {selectedReview?.comment > 250
                          ? isExpanded
                            ? "Show less"
                            : "Show more"
                          : ""}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", maxWidth: "100%" }}>
                <Box sx={{ mr: 3 }}>
                  <ProfileImageContainer
                    pic={selectedReview?.reviewer_photo}
                    icon={google}
                  />
                </Box>
                <Box
                  sx={{
                    maxWidth: "calc(100% - 64px)",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "calc(100% - 64px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "capitalize" }}
                    >{`${user?.first_name} ${user?.last_name}`}</Typography>
                    <Tooltip title={selectedReview?.location}>
                      <Typography
                        variant="caption"
                        color="textDisabled"
                        sx={{ pb: 0.5 }}
                      >
                        Owner
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, pb: 1, pt: 1.5 }}
                  >
                    Replying
                  </Typography>
                  <Box>
                    <TextField
                      placeholder="Enter reply message here..."
                      value={reply}
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        setReply(
                          event.target.value.length > 2490
                            ? event.target.value?.slice(0, 2500)
                            : event.target.value
                        )
                      }
                      minRows={4}
                      fullWidth
                      variant="outlined"
                      multiline
                      sx={{ mb: 0, flexGrow: 1 }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <Typography
                              color={
                                reply.length >= 2490 ? "error" : "textDisabled"
                              }
                              variant="body2"
                              sx={{ alignSelf: "flex-end" }}
                            >
                              {reply.length}/2500
                            </Typography>
                          ),
                        },
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textDisabled"
                    sx={{ textAlign: "justify", pt: "3px" }}
                  >
                    The customer will be notified about your reply, and it will
                    be visible on your Business Profile
                  </Typography>
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "space-between",
                      pt: 3.5,
                    }}
                  >
                    <Typography variant="body1" fontWeight={600}>
                      Templates
                    </Typography>
                    <ButtonOutline
                      startIcon={<img src={plusBlue} alt="plus" />}
                      onClick={() => setCreateNewTemplate(true)}
                    >
                      Create New
                    </ButtonOutline>
                  </Box>
                  <Box sx={{ boxSizing: "border-box", pt: 4 }}>
                    <Carousel
                      onClickTemplate={onClickTemplate}
                      slidesToShow={{ lg: 2, md: 2, xs: 1 }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() =>
              createNewTemplate ? setCreateNewTemplate(false) : onClose()
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#1650CF", border: "none" }}
            disabled={!createNewTemplate && reply === ""}
            onClick={async () => {
              if (createNewTemplate) {
                try {
                  await submitCreateNewTemplate();
                  setCreateNewTemplate(false);
                } catch (err) {
                  console.log(err);
                }
              }
            }}
          >
            {createNewTemplate ? "Save" : "Reply"}
          </Button>
        </Box>
      </Box>
    );
  });

export const FilterModal: React.FC<FilterModalProps> = forwardRef(
  ({ onClose }, ref) => {
    const [selectedFilters, setSelectedFilters] = useState({
      response: "All Responses",
      sentiment: [],
      ratings: "",
      attributes: "",
      reviewType: "All",
      source: "Google",
    });
    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          ...style,
        })}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey["100"],
            p: "20px 24px",
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          })}
        >
          <Typography variant="h5" sx={{ display: "inline" }}>
            Review Filter
          </Typography>

          <img
            src={close}
            alt="close"
            sizes="24px"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          />
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: `calc(100vh - 350px)`,
            p: 3,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600, pb: 1.5 }}>
            Response
          </Typography>
          <RadioGroup
            name="radio-buttons-group"
            row
            value={selectedFilters.response}
            onChange={(event) => {
              setSelectedFilters({
                ...selectedFilters,
                response: event.target.value,
              });
            }}
            sx={{ pb: 3.5 }}
          >
            {["All Responses", "Replied", "Not Replied"].map((item, index) => (
              <FormControlLabel
                key={index}
                sx={() => ({
                  "& .MuiTypography-root": {
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                })}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
          <Typography variant="body1" sx={{ fontWeight: 600, pb: 1.5 }}>
            Sentiment
          </Typography>
          {["Positive", "Negative", "Neutral"].map((item, index) => (
            <FormControlLabel
              key={index}
              sx={() => ({
                "& .MuiTypography-root": { fontSize: "12px", fontWeight: 400 },
              })}
              control={
                <Checkbox
                  sx={(theme) => ({ color: theme.palette.grey["300"] })}
                />
              }
              label={item}
            />
          ))}
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, pb: 1.5, pt: 3.5 }}
          >
            Ratings
          </Typography>
          {[1, 2, 3, 4, 5].map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  sx={(theme) => ({ color: theme.palette.grey["300"] })}
                />
              }
              label={
                <Box sx={{ ...flexRowCenterCenter }}>
                  <Typography variant="caption" sx={{ pr: 1, fontWeight: 400 }}>
                    {item}
                  </Typography>
                  <img src={star} alt="star" />
                </Box>
              }
            />
          ))}
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, pb: 1.5, pt: 3.5 }}
          >
            Attributes
          </Typography>
          {["Overall", "Service", "Cleanliness", "Staff", "Price"].map(
            (item, index) => (
              <FormControlLabel
                key={index}
                sx={() => ({
                  "& .MuiTypography-root": {
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                })}
                control={
                  <Checkbox
                    sx={(theme) => ({ color: theme.palette.grey["300"] })}
                  />
                }
                label={item}
              />
            )
          )}
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, pb: 1.5, pt: 3.5 }}
          >
            Review Type
          </Typography>
          <RadioGroup
            name="radio-buttons-group"
            row
            value={selectedFilters.reviewType}
            onChange={(event) => {
              setSelectedFilters({
                ...selectedFilters,
                reviewType: event.target.value,
              });
            }}
            sx={{ pb: 3.5 }}
          >
            {["All", "Rating Only", "With Review Text"].map((item, index) => (
              <FormControlLabel
                key={index}
                sx={() => ({
                  "& .MuiTypography-root": {
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                })}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
          <Typography variant="body1" sx={{ fontWeight: 600, pb: 1.5 }}>
            Source
          </Typography>
          <RadioGroup
            name="radio-buttons-group"
            row
            value={selectedFilters.source}
            onChange={(event) => {
              setSelectedFilters({
                ...selectedFilters,
                source: event.target.value,
              });
            }}
          >
            {["Google", "Play Store", "MB Pro"].map((item, index) => (
              <FormControlLabel
                key={index}
                sx={() => ({
                  "& .MuiTypography-root": {
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                })}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </Box>
        <Divider />
        <Box
          sx={{ ...flexRowCenterCenter, justifyContent: "space-between", p: 3 }}
        >
          <Button
            sx={{ mr: 1, color: "#105DFB", border: "none" }}
            onClick={onClose}
          >
            Clear All
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#1650CF", border: "none" }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    );
  }
);
