import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";
import { signup } from "./signupUserSlice";

interface OTPState {
  otp: string;
  otpGenerated: boolean;
  generateOTPLoading: boolean;
  isOtpVerified: boolean;
  verifyOtpLoading: boolean;
  error: string | null;
}

const initialState: OTPState = {
  otp: "",
  otpGenerated: false,
  generateOTPLoading: false,
  isOtpVerified: false,
  verifyOtpLoading: false,
  error: null,
};

export const generateOTP = createAsyncThunk(
  "/generate-otp",
  async (mobile_number: { mobile_number: string }, thunkAPI) => {
    try {
      const response = await apiManager.post("/generate-otp", mobile_number);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "OTP generation failed"
      );
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "/verify-otp",
  async (
    {
      otp,
      userData,
      navigate,
    }: {
      otp: string;
      userData: {
        first_name: string;
        last_name: string;
        email: string;
        mobile_number: string;
        password: string;
      };
      navigate: any;
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/verify-otp", {
        email: userData.email,
        mobile_number: userData.mobile_number,
        otp_value: otp,
      });
      thunkAPI.dispatch(signup({ userData, navigate }));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "OTP verification failed"
      );
    }
  }
);

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    resetOtpState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //generate otp
      .addCase(generateOTP.pending, (state) => {
        state.generateOTPLoading = true;
        state.otpGenerated = false;
        state.error = null;
      })
      .addCase(generateOTP.fulfilled, (state) => {
        state.otpGenerated = true;
        state.isOtpVerified = false;
        state.generateOTPLoading = false;
      })
      .addCase(generateOTP.rejected, (state, action) => {
        state.generateOTPLoading = false;
        state.otpGenerated = false;
        state.error = action.payload as string;
      })
      //verify otp
      .addCase(verifyOTP.pending, (state) => {
        state.verifyOtpLoading = true;
        state.isOtpVerified = false;
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, () => initialState)
      .addCase(verifyOTP.rejected, (state, action) => {
        state.verifyOtpLoading = false;
        state.isOtpVerified = false;
        state.error = action.payload as string;
      });
  },
});

export default otpSlice.reducer;
