import {
  Box,
  Divider,
  Grid2,
  Typography,
  MenuItem
} from "@mui/material";
import TextField from "components/TextField";
import { flexRowCenterCenter } from "utils/styles";
import close from "assets/icons/close.svg";
import { forwardRef, useState } from "react";
import { Button } from "components/Buttons";
import { DropDown } from "components/Select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 680,
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  maxHeight: "90%",
};

const AddUserModal: React.FC<{ onClose?: () => void }> = forwardRef(({ onClose },ref) => {
  const [userDetails, setUserDetails] = useState({
    firstName:'',
    lastName:'',
    mobileNumber:'',
    email:'',
    role:''
  })

  return (
    <Box
    ref={ref}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        ...style,
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.grey["100"],
          p: "20px 24px",
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        })}
      >
        <Typography variant="h5">Add User</Typography>
        <img
          src={close}
          alt="close"
          sizes="24px"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Box>
      <Box
        sx={{
          p: 3,
          pb: 6,
          overflowY: "auto",
          maxHeight: `calc(100vh - 350px)`,
        }}
      >
        <Grid2 container gap={2}>
         <Grid2 size={{xs:12, md:5.8}}>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          First Name
        </Typography>
        <TextField
          value={userDetails.firstName}
          fullWidth
          size='small'
          variant="outlined"
          placeholder="Enter First Name"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setUserDetails({...userDetails, firstName: event.target.value})}
          sx={{  }}
        />
        </Grid2>
        <Grid2 size={{xs:12, md:5.8}}>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Last Name
        </Typography>
        <TextField
          value={userDetails.lastName}
          fullWidth
          size='small'
          variant="outlined"
          placeholder="Enter Last Name"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setUserDetails({...userDetails, lastName: event.target.value})}
          sx={{  }}
        />
        </Grid2>
        <Grid2 size={{xs:12, md:5.8}}>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Mobile Number
        </Typography>
        <TextField
          value={userDetails.mobileNumber}
          fullWidth
          size='small'
          variant="outlined"
          placeholder="Enter Phone Number"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setUserDetails({...userDetails, mobileNumber: event.target.value})}
          sx={{  }}
        />
        </Grid2>
        <Grid2 size={{xs:12, md:5.8}}>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Email
        </Typography>
        <TextField
          value={userDetails.email}
          fullWidth
          size='small'
          variant="outlined"
          placeholder="Enter Email"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setUserDetails({...userDetails, email: event.target.value})}
          sx={{  }}
        />
        </Grid2>
        <Grid2 size={{xs:12, md:5.8}}>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Select Role
        </Typography>
        <DropDown label='Select Role' width='100%' labelVisible={userDetails.role===''} onChange={(event)=>{
          const {
            target: { value },
          } = event;
          setUserDetails({...userDetails, role: (typeof value === 'string') ? value : ''})}}>
          <MenuItem value='manager' sx={{fontWeight: 400, fontSize: '14px'}}>Manager</MenuItem>
          <MenuItem value='cashier' sx={{fontWeight: 400, fontSize: '14px'}}>Cashier</MenuItem>
          <MenuItem value='supervisor' sx={{fontWeight: 400, fontSize: '14px'}}>Supervisor</MenuItem>
        </DropDown>
        </Grid2>
        </Grid2>
      </Box>
      <Divider sx={{}} />
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", px: 3, py:1 }}>
        <Button sx={{ mr: 1 }} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ backgroundColor: "#1650CF", border:'none' }}>
          Add
        </Button>
      </Box>
    </Box>
  );
});

export default AddUserModal;
