import { Box, Typography } from "@mui/material";

import { CircularProgressWithBufferProps } from "interfaces/Components.types";

const CircularProgressWithBuffer: React.FC<CircularProgressWithBufferProps> = ({
  progress,
  buffer,
  radius = 32,
  stroke = 6.5,
  color = "#1bc2cf",
}) => {
  const size = 100;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = ((100 - progress) / 100) * circumference;
  const bufferDashoffset = ((100 - buffer) / 100) * circumference;

  return (
    <Box position="relative" display="inline-flex">
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#f5f5f5"
          strokeWidth={stroke}
          fill="none"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#f5f5f5"
          strokeWidth={stroke}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={bufferDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>
      <svg
        width={size}
        height={size}
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={color}
          strokeWidth={stroke}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>

      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Typography variant="body1">{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithBuffer;
