import { Box, Chip, Divider, FormControlLabel, ListItemText, MenuItem, Typography, TextField as TextInput, Select as TimeSelect, IconButton, RadioGroup, Dialog, DialogTitle, DialogContent, DialogActions, Grid2, List, ListItem } from '@mui/material'
import { Button, ButtonOutline } from 'components/Buttons'
import { CategoryProps, CreateNewPostModalProps, ProductProps, ServiceProps } from 'interfaces/BusinessProfiles.types'
import React, { forwardRef, useState } from 'react'
import { flexColumnCenterCenter, flexRowCenterCenter } from 'utils/styles'
import close from "assets/icons/close.svg";
import { DropDown } from 'components/Select'
import { useDropzone } from 'react-dropzone'
import plus from "assets/icons/add-black.svg";
import fileUpload from "assets/icons/file-upload.svg";
import storeIcon from "assets/icons/local-convenience-store-blue.svg";
import photosIcon from "assets/icons/monochrome-photos-blue.svg";
import mall from "assets/icons/local-mall-blue.svg";
import factCheck from "assets/icons/fact-check-blue.svg";
import systemUpdate from "assets/icons/system-update-blue.svg";
import featuredVideo from "assets/icons/featured-video-blue.svg";
import TextField from 'components/TextField'
import Checkbox from 'components/Checkbox'
import Radio from 'components/Radio'
import Searchbar from 'components/Searchbar'
import styled from '@emotion/styled'
import checkedRound from "assets/icons/checked-round.svg";
import image from 'assets/images/nike-shoe-white.png'
import rightArrow from 'assets/icons/chevron-right.svg'
import back from 'assets/icons/back.svg'
import bluePlus from 'assets/icons/plus-blue.svg'

const StyledTextField = styled(TextInput)(() => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#f8f1f182'
    },
    '& .MuiFilledInput-root::before': {
        borderBottom: 'none',
    },
    '& .MuiFilledInput-root::after': {
        borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: '14px',
        paddingTop: '0px'
    },
    '& .MuiFilledInput-input': {
        padding: '8px !important',
    },
    '& .MuiInputBase-root': {
        padding: '0px 8px !important',
    },
}));

const TimeDropdown = styled(TimeSelect)(() => ({
    width: 'min-content',
    '& .MuiSelect-select': {
        p: 0,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 400,
        color: '#000',
    },
    '& .MuiInputBase-input': {
        padding: '0 !important',
        fontSize: '14px'
    },
    '&:before, &:after': {
        display: 'none',
    },
}));

type EditBusinessListingProps = {
    setActionOrOpen: ({ action, open }: { action?: string, open?: boolean }) => void;
    action: string | number;
};

type Step = {
    id: number;
    label: string;
    subLabel: string;
};

type AttributesData = {
    id?: number;
    title?: string;
    description?: string;
    options?: {
        value?: string,
        selectedValue?: string
    }[]
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: 750,
    minWidth: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: "90%",
};

// const initialSelectPlatformOptions = [{ title: "Google", icon: google, selected: true }, { title: "Facebook", icon: google, selected: false }];

// const chipsData = [
//     "Name",
//     "Business Address",
//     "City",
//     "State",
//     "Pincode",
//     "Area",
// ];

// type WorkingHourProps = { week: string, from: string, to: string, checked: boolean }

// const initialWorkingHours = [
//     { week: "Sunday", from: '', to: "", checked: true },
//     { week: "Monday", from: '', to: "", checked: true },
//     { week: "Tuesday", from: '', to: "", checked: true },
//     { week: "Wednesday", from: '', to: "", checked: false },
//     { week: "Thursday", from: '', to: "", checked: true },
//     { week: "Friday", from: '', to: "", checked: true },
//     { week: "Saturday", from: '', to: "", checked: true },
// ];

type FiltersProps = {
    id: number, filterName: 'state' | 'city' | 'rating' | 'label', filterOptions: { id: number, primary: string, secondary?: string }[]
}

const filters: FiltersProps[] = [
    {
        id: 1, filterName: 'state', filterOptions: [
            { id: 1, primary: 'Andhra Pradesh', secondary: '2' },
            { id: 2, primary: 'Arunachal Pradesh', secondary: '2' },
            { id: 3, primary: 'Assam', secondary: '2' },
            { id: 4, primary: 'Bihar', secondary: '2' },
            { id: 5, primary: 'Chhattisgarh', secondary: '2' },
            { id: 6, primary: 'Goa', secondary: '2' },
            { id: 7, primary: 'Gujarat', secondary: '2' },
            { id: 8, primary: 'Haryana', secondary: '2' },
        ]
    },
    {
        id: 2, filterName: 'city', filterOptions: [
            { id: 1, primary: 'Delhi', secondary: '2' },
            { id: 2, primary: 'Bangalore', secondary: '2' },
            { id: 3, primary: 'Chennai', secondary: '2' },
            { id: 4, primary: 'Kolkata', secondary: '2' },
            { id: 5, primary: 'Patna', secondary: '2' },
            { id: 6, primary: 'Jharkhand', secondary: '2' },
            { id: 7, primary: 'Hyderabad', secondary: '2' },
        ]
    },
    {
        id: 3, filterName: 'rating', filterOptions: [
            { id: 1, primary: '5' },
            { id: 2, primary: '4' },
            { id: 3, primary: '3' },
            { id: 4, primary: '2' },
            { id: 5, primary: '1' },
        ]
    },
    {
        id: 4, filterName: 'label', filterOptions: [
            { id: 1, primary: 'Ramesh', secondary: '2' },
            { id: 2, primary: 'Pankaj', secondary: '2' },
            { id: 3, primary: 'Manoj', secondary: '2' },
            { id: 4, primary: 'Offsite', secondary: '2' },
        ]
    }
]

const initialSelectedFilters = {
    state: [], city: [], rating: [], label: []
}

const initialBusinessProfiles = [
    { id: '5102384584759847987', name: 'Bata Store Mogappair', address: 'Bata Store, G7 Fortune Empire, Jubilee Hills Road', city: 'Chennai', state: 'Tamil Nadu' },
    { id: '5102384584759847988', name: 'Bata Store Mogappair', address: 'Bata Store, G7 Fortune Empire, Jubilee Hills Road', city: 'Chennai', state: 'Tamil Nadu' }
]

const iconsList = [
    { id: '1', name: 'Edit Profile', icon: storeIcon },
    { id: '2', name: 'Photos', icon: photosIcon },
    { id: '3', name: 'Edit Products', icon: mall },
    { id: '4', name: 'Edit Service', icon: factCheck },
    { id: '5', name: 'Updates', icon: systemUpdate },
    { id: '6', name: 'Labels', icon: featuredVideo }
]

const buttonsList = ['None', 'Book', 'Order online', 'Buy', 'Learn more', 'Sign up', 'Call now']

// export const CreateNewPostModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {
//     const [selectedFile, setSelectedFile] = useState<any>(null);
//     const onDrop = (acceptedFiles: any) => setSelectedFile(acceptedFiles[0]);
//     const { getRootProps, getInputProps } = useDropzone({ onDrop });
//     const [selectPlatformOptions, setSelectPlatformOptions] = useState(initialSelectPlatformOptions)

//     console.log(setSelectPlatformOptions)

//     const handleFileChange = (event: any) =>
//         setSelectedFile(event.target.files[0]);

//     return (
//         <Box
//             ref={ref}
//             sx={(theme) => ({
//                 backgroundColor: theme.palette.secondary.main,
//                 ...style,
//             })}
//         >
//             <Box
//                 sx={(theme) => ({
//                     backgroundColor: theme.palette.grey["100"],
//                     p: "20px 24px",
//                     ...flexRowCenterCenter,
//                     justifyContent: "space-between",
//                     borderTopLeftRadius: "12px",
//                     borderTopRightRadius: "12px",
//                 })}
//             >
//                 <Typography variant="h5" sx={{ display: 'inline' }}>Create New Post</Typography>
//                 <img
//                     src={close}
//                     alt="close"
//                     sizes="24px"
//                     style={{ cursor: "pointer" }}
//                     onClick={onClose}
//                 />
//             </Box>
//             <Box
//                 sx={{
//                     overflowY: "auto",
//                     maxHeight: `calc(100vh - 350px)`,
//                     p: 3
//                 }}
//             >
//                 <Typography variant='body2' color='textDisabled' sx={{ pb: 3 }}>Regular posts keep prospects engaged, informing them about updates and promotions, which boosts visibility and drives customer actions.</Typography>
//                 <Box
//                     sx={{
//                         ...flexRowCenterCenter,
//                         justifyContent: "flex-start",
//                         pb: 2,
//                     }}
//                 >
//                     <Box width="100%">
//                         <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                             Select Platform
//                         </Typography>
//                         <Autocomplete
//                             multiple
//                             id="platforms"
//                             options={selectPlatformOptions}
//                             getOptionLabel={(option) => option.title}
//                             renderTags={(value) =>
//                                 value.map((option, index) => (
//                                     <Chip
//                                         label={option.title}
//                                         key={index}
//                                         avatar={
//                                             <Avatar
//                                                 src={option.icon}
//                                                 alt={option.title}
//                                                 sx={{ width: 20, height: 20 }}
//                                             />
//                                         }
//                                     />
//                                 ))
//                             }
//                             renderOption={(props, option) => (
//                                 <li {...props}>
//                                     <Box sx={{ ...flexRowCenterCenter }}>
//                                         <img src={option.icon} alt={option.title} />
//                                         <Typography
//                                             variant="body2"
//                                             color="textDisabled"
//                                             sx={{ ml: 1 }}
//                                         >
//                                             {option.title}
//                                         </Typography>
//                                     </Box>
//                                 </li>
//                             )}
//                             defaultValue={[selectPlatformOptions[0]]}
//                             popupIcon={<DownIcon />}
//                             renderInput={(params) => (
//                                 <CustomTextField {...params} size='small' variant="outlined" />
//                             )}
//                             sx={{
//                                 mr: 3,
//                                 "& .MuiChip-deleteIcon": {
//                                     color: "black !important",
//                                 },
//                             }}
//                         />
//                     </Box>
//                     <Box width="100%">
//                         <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                             Post Type
//                         </Typography>
//                         <Select placeholder="Bulk Actions" fullWidth sx={{ ml: 0 }}><p>option</p></Select>
//                     </Box>
//                 </Box>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Description
//                 </Typography>
//                 <TextField
//                     placeholder="Write Something..."
//                     multiline
//                     variant="outlined"
//                     minRows={4}
//                     fullWidth
//                     sx={{ mb: 0 }}
//                 />
//                 <Box
//                     gap={1}
//                     sx={(theme) => ({
//                         p: 2,
//                         display: "flex",
//                         flexWrap: "wrap",
//                         border: `1px solid ${theme.palette.grey["400"]}`,
//                         borderTop: "none",
//                         borderRadius: "0 0 8px 8px",
//                         mb: 2,
//                     })}
//                 >
//                     {chipsData.map((label, index) => (
//                         <Chip
//                             key={index}
//                             label={label}
//                             avatar={<img src={plus} alt="plus" sizes="10px" />}
//                             sx={() => ({
//                                 fontSize: "14px",
//                                 fontWeight: 500,
//                             })}
//                         />
//                     ))}
//                 </Box>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Add Media
//                 </Typography>
//                 <Box
//                     {...getRootProps()}
//                     sx={(theme) => ({
//                         border: `2px dashed ${theme.palette.grey["300"]}`,
//                         borderRadius: 2,
//                         textAlign: "center",
//                         width: "100%",
//                         p: 3,
//                         boxSizing: "border-box",
//                     })}
//                 >
//                     <input {...getInputProps()} />
//                     <img src={fileUpload} alt="file-upload" sizes="32px" />
//                     <Box sx={{ ...flexRowCenterCenter }}>
//                         <Typography variant="body2" sx={{ my: 1 }}>
//                             Drag and Drop or{" "}
//                         </Typography>
//                         <Button
//                             variant="text"
//                             component="label"
//                             sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
//                         >
//                             Click to upload
//                             <input type="file" hidden onChange={handleFileChange} />
//                         </Button>
//                     </Box>
//                     <Typography variant="caption" color="textDisabled">
//                         Supported formats: CSV or XLS. Max Size: 25MB
//                     </Typography>
//                     {selectedFile && (
//                         <Typography variant="body2" sx={{ mt: 1 }}>
//                             Selected file: {selectedFile?.name}
//                         </Typography>
//                     )}
//                 </Box>
//             </Box>
//             <Divider />
//             <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
//                 <Button sx={{ mr: 1 }} onClick={onClose}>
//                     Cancel
//                 </Button>
//                 <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
//                     Publish
//                 </Button>
//             </Box>
//         </Box>
//     )
// })

// export const UploadPhotosOrVideosModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {
//     const [selectedFile, setSelectedFile] = useState<any>(null);
//     const onDrop = (acceptedFiles: any) => setSelectedFile(acceptedFiles[0]);
//     const { getRootProps, getInputProps } = useDropzone({ onDrop });
//     const [selectPlatformOptions, setSelectPlatformOptions] = useState(initialSelectPlatformOptions)

//     console.log(setSelectPlatformOptions)

//     const handleFileChange = (event: any) =>
//         setSelectedFile(event.target.files[0]);

//     return (
//         <Box
//             ref={ref}
//             sx={(theme) => ({
//                 backgroundColor: theme.palette.secondary.main,
//                 ...style,
//             })}
//         >
//             <Box
//                 sx={(theme) => ({
//                     backgroundColor: theme.palette.grey["100"],
//                     p: "20px 24px",
//                     ...flexRowCenterCenter,
//                     justifyContent: "space-between",
//                     borderTopLeftRadius: "12px",
//                     borderTopRightRadius: "12px",
//                 })}
//             >
//                 <Typography variant="h5" sx={{ display: 'inline' }}>Upload Photos or Videos</Typography>
//                 <img
//                     src={close}
//                     alt="close"
//                     sizes="24px"
//                     style={{ cursor: "pointer" }}
//                     onClick={onClose}
//                 />
//             </Box>
//             <Box
//                 sx={{
//                     overflowY: "auto",
//                     maxHeight: `calc(100vh - 350px)`,
//                     p: 3
//                 }}
//             >
//                 <Typography variant='body2' color='textDisabled' sx={{ pb: 3 }}>Regular posts keep prospects engaged, informing them about updates and promotions, which boosts visibility and drives customer actions.</Typography>
//                 <Box
//                     sx={{
//                         ...flexRowCenterCenter,
//                         justifyContent: "flex-start",
//                         pb: 2,
//                     }}
//                 >
//                     <Box width="100%">
//                         <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                             Select Platform
//                         </Typography>
//                         <Autocomplete
//                             multiple
//                             id="platforms"
//                             options={selectPlatformOptions}
//                             getOptionLabel={(option) => option.title}
//                             renderTags={(value) =>
//                                 value.map((option, index) => (
//                                     <Chip
//                                         label={option.title}
//                                         key={index}
//                                         avatar={
//                                             <Avatar
//                                                 src={option.icon}
//                                                 alt={option.title}
//                                                 sx={{ width: 20, height: 20 }}
//                                             />
//                                         }
//                                     />
//                                 ))
//                             }
//                             renderOption={(props, option) => (
//                                 <li {...props}>
//                                     <Box sx={{ ...flexRowCenterCenter }}>
//                                         <img src={option.icon} alt={option.title} />
//                                         <Typography
//                                             variant="body2"
//                                             color="textDisabled"
//                                             sx={{ ml: 1 }}
//                                         >
//                                             {option.title}
//                                         </Typography>
//                                     </Box>
//                                 </li>
//                             )}
//                             defaultValue={[selectPlatformOptions[0]]}
//                             popupIcon={<DownIcon />}
//                             renderInput={(params) => (
//                                 <CustomTextField {...params} size='small' variant="outlined" />
//                             )}
//                             sx={{
//                                 mr: 3,
//                                 "& .MuiChip-deleteIcon": {
//                                     color: "black !important",
//                                 },
//                             }}
//                         />
//                     </Box>
//                     <Box width="100%">
//                         <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                             Select Location
//                         </Typography>
//                         <Select placeholder="Bulk Actions" fullWidth sx={{ ml: 0 }} defaultValue={0}><MenuItem value={0} sx={{ display: 'none' }}>0 Locations Selected</MenuItem></Select>
//                     </Box>
//                 </Box>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Select
//                 </Typography>
//                 <Select defaultValue={1} fullWidth sx={{ ml: 0 }}><MenuItem value={1}>Additional</MenuItem></Select>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Add Media
//                 </Typography>
//                 <Box
//                     {...getRootProps()}
//                     sx={(theme) => ({
//                         border: `2px dashed ${theme.palette.grey["300"]}`,
//                         borderRadius: 2,
//                         textAlign: "center",
//                         width: "100%",
//                         p: 3,
//                         boxSizing: "border-box",
//                     })}
//                 >
//                     <input {...getInputProps()} />
//                     <img src={fileUpload} alt="file-upload" sizes="32px" />
//                     <Box sx={{ ...flexRowCenterCenter }}>
//                         <Typography variant="body2" sx={{ my: 1 }}>
//                             Drag and Drop or{" "}
//                         </Typography>
//                         <Button
//                             variant="text"
//                             component="label"
//                             sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
//                         >
//                             Click to upload
//                             <input type="file" hidden onChange={handleFileChange} />
//                         </Button>
//                     </Box>
//                     <Typography variant="caption" color="textDisabled">
//                         Supported formats: CSV or XLS. Max Size: 25MB
//                     </Typography>
//                     {selectedFile && (
//                         <Typography variant="body2" sx={{ mt: 1 }}>
//                             Selected file: {selectedFile?.name}
//                         </Typography>
//                     )}
//                 </Box>
//             </Box>
//             <Divider />
//             <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
//                 <Button sx={{ mr: 1 }} onClick={onClose}>
//                     Cancel
//                 </Button>
//                 <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
//                     Publish
//                 </Button>
//             </Box>
//         </Box>
//     )
// })

// export const UpdateDescriptionModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {

//     return (
//         <Box
//             ref={ref}
//             sx={(theme) => ({
//                 backgroundColor: theme.palette.secondary.main,
//                 ...style,
//                 maxWidth: 650
//             })}
//         >
//             <Box
//                 sx={(theme) => ({
//                     backgroundColor: theme.palette.grey["100"],
//                     p: "20px 24px",
//                     ...flexRowCenterCenter,
//                     justifyContent: "space-between",
//                     borderTopLeftRadius: "12px",
//                     borderTopRightRadius: "12px",
//                 })}
//             >
//                 <Typography variant="h5" sx={{ display: 'inline' }}>Edit Description</Typography>
//                 <img
//                     src={close}
//                     alt="close"
//                     sizes="24px"
//                     style={{ cursor: "pointer" }}
//                     onClick={onClose}
//                 />
//             </Box>
//             <Box
//                 sx={{
//                     overflowY: "auto",
//                     maxHeight: `calc(100vh - 350px)`,
//                     p: 3
//                 }}
//             >
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Select Location
//                 </Typography>
//                 <Select placeholder="Bulk Actions" fullWidth sx={{ ml: 0 }} defaultValue={0}><MenuItem value={0} sx={{ display: 'none' }}>0 Locations Selected</MenuItem></Select>
//                 <Typography variant='body2' color='textDisabled' sx={{ pb: 3 }}>Write about your business, top services you offer, your products, and competitive benefits in a minimum of 225 characters. As Google does not have a description field for Hotels and Resorts, the changes will reflect only on Facebook and Bing, if integrated.</Typography>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Description
//                 </Typography>
//                 <TextField
//                     placeholder="Write Something..."
//                     multiline
//                     variant="outlined"
//                     minRows={4}
//                     fullWidth
//                     sx={{ mb: 0 }}
//                 />
//                 <Box
//                     gap={1}
//                     sx={(theme) => ({
//                         p: 2,
//                         display: "flex",
//                         flexWrap: "wrap",
//                         border: `1px solid ${theme.palette.grey["400"]}`,
//                         borderTop: "none",
//                         borderRadius: "0 0 8px 8px",
//                         mb: 2,
//                     })}
//                 >
//                     {chipsData.map((label, index) => (
//                         <Chip
//                             key={index}
//                             label={label}
//                             avatar={<img src={plus} alt="plus" sizes="10px" />}
//                             sx={() => ({
//                                 fontSize: "14px",
//                                 fontWeight: 500,
//                             })}
//                         />
//                     ))}
//                 </Box>
//                 <Box
//                     sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}
//                 >
//                     <img
//                         src={infoIcon}
//                         alt="info-icon"
//                         sizes="40px"
//                         style={{ marginRight: "8px" }}
//                     />
//                     <Typography variant="body2" color='error'>
//                         No sales offers, links, URLs, or contact numbers
//                     </Typography>
//                 </Box>
//             </Box>
//             <Divider />
//             <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
//                 <Button sx={{ mr: 1 }} onClick={onClose}>
//                     Cancel
//                 </Button>
//                 <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
//                     Publish
//                 </Button>
//             </Box>
//         </Box>
//     )
// })

// export const UpdateWebsiteModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {

//     return (
//         <Box
//             ref={ref}
//             sx={(theme) => ({
//                 backgroundColor: theme.palette.secondary.main,
//                 ...style,
//                 maxWidth: 650
//             })}
//         >
//             <Box
//                 sx={(theme) => ({
//                     backgroundColor: theme.palette.grey["100"],
//                     p: "20px 24px",
//                     ...flexRowCenterCenter,
//                     justifyContent: "space-between",
//                     borderTopLeftRadius: "12px",
//                     borderTopRightRadius: "12px",
//                 })}
//             >
//                 <Typography variant="h5" sx={{ display: 'inline' }}>Edit Website</Typography>
//                 <img
//                     src={close}
//                     alt="close"
//                     sizes="24px"
//                     style={{ cursor: "pointer" }}
//                     onClick={onClose}
//                 />
//             </Box>
//             <Box
//                 sx={{
//                     overflowY: "auto",
//                     maxHeight: `calc(100vh - 350px)`,
//                     p: 3
//                 }}
//             >
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Select Location
//                 </Typography>
//                 <Select placeholder="Bulk Actions" fullWidth sx={{ ml: 0 }} defaultValue={0}><MenuItem value={0} sx={{ display: 'none' }}>0 Locations Selected</MenuItem></Select>
//                 <Typography variant='body2' color='textDisabled' sx={{ pb: 3 }}>Write about your business, top services you offer, your products, and competitive benefits in a minimum of 225 characters. As Google does not have a description field for Hotels and Resorts, the changes will reflect only on Facebook and Bing, if integrated.</Typography>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Website URL
//                 </Typography>
//                 <TextField
//                     placeholder="Website URL*"
//                     variant="outlined"
//                     fullWidth
//                     size='small'
//                     slotProps={{
//                         input: {
//                             endAdornment: (
//                                 <InputAdornment position="end">
//                                     <img src={bluePencil} alt="pencil" width={24} height={24} />
//                                 </InputAdornment>
//                             ),
//                         },
//                     }}
//                     sx={{ mb: 0 }}
//                 />
//                 <Typography sx={{ color: '#105DFB', fontWeight: 600 }} variant='caption'>Check URL</Typography>
//                 <Box
//                     sx={{ ...flexRowCenterCenter, justifyContent: "flex-start", pt: 2 }}
//                 >
//                     <img
//                         src={infoIcon}
//                         alt="info-icon"
//                         sizes="40px"
//                         style={{ marginRight: "8px" }}
//                     />
//                     <Typography variant="body2">
//                         Updating the Website might lead to the profile getting suspended by Google.
//                     </Typography>
//                 </Box>
//             </Box>
//             <Divider />
//             <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
//                 <Button sx={{ mr: 1 }} onClick={onClose}>
//                     Cancel
//                 </Button>
//                 <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
//                     Publish
//                 </Button>
//             </Box>
//         </Box>
//     )
// })

// export const UpdateWorkingHoursModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {
//     const [workingHours, setWorkingHours] = useState<WorkingHourProps[]>(initialWorkingHours)

//     const handleCheck = (event: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked: boolean, workingHour: WorkingHourProps) => {

//         const newWorkingHours = workingHours.map(data => {
//             if (data.week === workingHour.week) {
//                 return { ...data, checked }
//             }
//             return data
//         })
//         setWorkingHours(newWorkingHours)
//     }

//     return (
//         <Box
//             ref={ref}
//             sx={(theme) => ({
//                 backgroundColor: theme.palette.secondary.main,
//                 ...style,
//                 maxWidth: 650
//             })}
//         >
//             <Box
//                 sx={(theme) => ({
//                     backgroundColor: theme.palette.grey["100"],
//                     p: "20px 24px",
//                     ...flexRowCenterCenter,
//                     justifyContent: "space-between",
//                     borderTopLeftRadius: "12px",
//                     borderTopRightRadius: "12px",
//                 })}
//             >
//                 <Typography variant="h5" sx={{ display: 'inline' }}>Edit Working Hours</Typography>
//                 <img
//                     src={close}
//                     alt="close"
//                     sizes="24px"
//                     style={{ cursor: "pointer" }}
//                     onClick={onClose}
//                 />
//             </Box>
//             <Box
//                 sx={{
//                     overflowY: "auto",
//                     maxHeight: `calc(100vh - 350px)`,
//                     p: 3
//                 }}
//             >
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Select Location
//                 </Typography>
//                 <Select placeholder="Bulk Actions" fullWidth sx={{ ml: 0 }} defaultValue={0}><MenuItem value={0} sx={{ display: 'none' }}>0 Locations Selected</MenuItem></Select>
//                 <Typography variant='body2' color='textDisabled' sx={{ py: 3 }}>Provide your regular customer-facing hours of operation. Use Special hours to ensure the store is open all the time when the customer arrives.</Typography>
//                 {workingHours.map((item, index) => (
//                     <Box key={index} gap={3} sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', flexWrap: 'wrap', mb: 2 }}>
//                         <Typography sx={{ minWidth: 80 }} variant='body2'>{item.week}</Typography>
//                         <Box sx={{ mr: 3 }}>
//                             <Toggle checked={item.checked} label='' onChange={(event, checked) => handleCheck(event, checked, item)} />
//                         </Box>
//                         {item.checked ? <Box gap={1} sx={{ ...flexRowCenterCenter, justifyContent: 'flex-end', flexWrap: 'wrap', flexGrow: 1 }}>
//                             <CustomTextField type='time' label='From' placeholder='From' variant='outlined' size='small' sx={{ minWidth: 100, flexGrow: 1 }} />
//                             <CustomTextField type='time' label='To' variant='outlined' size='small' sx={{ minWidth: 100, flexGrow: 1 }} />
//                         </Box> : <Typography variant='body2'>Closed</Typography>}
//                     </Box>
//                 ))}
//             </Box>
//             <Divider />
//             <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
//                 <Button sx={{ mr: 1 }} onClick={onClose}>
//                     Cancel
//                 </Button>
//                 <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
//                     Publish
//                 </Button>
//             </Box>
//         </Box>
//     )
// })

// export const UpdatePrimaryCategoryModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {

//     return (
//         <Box
//             ref={ref}
//             sx={(theme) => ({
//                 backgroundColor: theme.palette.secondary.main,
//                 ...style,
//                 maxWidth: 650
//             })}
//         >
//             <Box
//                 sx={(theme) => ({
//                     backgroundColor: theme.palette.grey["100"],
//                     p: "20px 24px",
//                     ...flexRowCenterCenter,
//                     justifyContent: "space-between",
//                     borderTopLeftRadius: "12px",
//                     borderTopRightRadius: "12px",
//                 })}
//             >
//                 <Typography variant="h5" sx={{ display: 'inline' }}>Edit Primary Category</Typography>
//                 <img
//                     src={close}
//                     alt="close"
//                     sizes="24px"
//                     style={{ cursor: "pointer" }}
//                     onClick={onClose}
//                 />
//             </Box>
//             <Box
//                 sx={{
//                     overflowY: "auto",
//                     maxHeight: `calc(100vh - 350px)`,
//                     p: 3
//                 }}
//             >
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Select Location
//                 </Typography>
//                 <Select placeholder="Bulk Actions" fullWidth sx={{ ml: 0 }} defaultValue={0}><MenuItem value={0} sx={{ display: 'none' }}>0 Locations Selected</MenuItem></Select>
//                 <Typography variant='body2' color='textDisabled' sx={{ pb: 3 }}>Write about your business, top services you offer, your products, and competitive benefits in a minimum of 225 characters. As Google does not have a description field for Hotels and Resorts, the changes will reflect only on Facebook and Bing, if integrated.</Typography>
//                 <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
//                     Category
//                 </Typography>
//                 <TextField
//                     placeholder="Primary Category"
//                     variant="outlined"
//                     fullWidth
//                     size='small'
//                 />
//                 <Box
//                     sx={{ ...flexRowCenterCenter, justifyContent: "flex-start", pt: 2 }}
//                 >
//                     <img
//                         src={infoIcon}
//                         alt="info-icon"
//                         sizes="40px"
//                         style={{ marginRight: "8px" }}
//                     />
//                     <Typography variant="body2">
//                         Updating the Primary Category might lead to the profile getting suspended by Google.
//                     </Typography>
//                 </Box>
//             </Box>
//             <Divider />
//             <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
//                 <Button sx={{ mr: 1 }} onClick={onClose}>
//                     Cancel
//                 </Button>
//                 <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
//                     Publish
//                 </Button>
//             </Box>
//         </Box>
//     )
// })

export const MoreFiltersModal: React.FC<CreateNewPostModalProps> = forwardRef(({ onClose }, ref) => {
    const [selectedFilters, setSelectedFilters] = useState<{ state: string[], city: string[], rating: string[], label: string[] }>(initialSelectedFilters)
    return (
        <Box
            ref={ref}
            sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main,
                ...style,
            })}
        >
            <Box
                sx={() => ({
                    p: "20px 24px",
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                })}
            >
                <Typography variant="h5" sx={{ display: 'inline' }}>More Filters</Typography>
                <Box sx={{ ...flexRowCenterCenter }}>
                    <Typography variant='caption' sx={{ cursor: 'pointer', color: '#1D77DF', mr: 1 }} onClick={() => setSelectedFilters(initialSelectedFilters)}>Reset Filters</Typography>
                    <img
                        src={close}
                        alt="close"
                        sizes="24px"
                        style={{ cursor: "pointer" }}
                        onClick={onClose}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    overflowY: "auto",
                    maxHeight: `calc(100vh - 350px)`,
                    p: 3
                }}
            >
                {filters.map((filter) => <Box key={filter.id} pb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 600, pb: 1 }}>{`${selectedFilters[filter.filterName].length} Selected`}</Typography>
                    <DropDown
                        multiple
                        label={`Select ${filter.filterName.charAt(0).toUpperCase() + filter.filterName.slice(1)}`}
                        labelVisible={selectedFilters[filter.filterName].length < 1}
                        value={selectedFilters[filter.filterName]}
                        variant='outlined'
                        width='100%'
                        onChange={(event) => {
                            const { target: { value } } = event;
                            const newValue: any = typeof value === 'string' ? value.split(',') : value;
                            setSelectedFilters({ ...selectedFilters, [filter.filterName]: newValue });
                        }}
                        renderValue={(selected: any) => {
                            const displayValue = selected.join(', ');
                            return (
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', width: '100%' }}>
                                    {displayValue}
                                </span>
                            );
                        }}
                    >
                        {filter.filterOptions.map((filterOption) => (
                            <MenuItem key={filterOption.id} value={filterOption.primary}>
                                <Checkbox checked={selectedFilters[filter.filterName].includes(filterOption.primary)} />
                                <ListItemText
                                    primary={filterOption.primary}
                                    secondary={filterOption.secondary ? `${filterOption.secondary} Locations` : ''}
                                    primaryTypographyProps={{ style: { fontSize: '14px', fontWeight: 500 } }}
                                    secondaryTypographyProps={{ style: { fontSize: '12px', color: 'gray' } }}
                                />
                            </MenuItem>
                        ))}
                    </DropDown>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} pt={0.5}>
                        <Typography variant='caption' sx={{ cursor: 'pointer', color: '#1D77DF' }} onClick={() => setSelectedFilters({ ...selectedFilters, [filter.filterName]: [] })}>Clear</Typography>
                    </Box>
                </Box>)}
            </Box>
            <Divider />
            <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
                <Button sx={{ mr: 1 }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
                    Apply
                </Button>
            </Box>
        </Box>
    )
})

const EditProfile: React.FC<{ selectedBusinessProfiles: string[] }> = forwardRef(({ selectedBusinessProfiles }, ref) => {
    const [profileTabs, setProfileTabs] = useState([
        { id: 1, name: 'About', isActive: true },
        { id: 2, name: 'Contact', isActive: false },
        { id: 3, name: 'Location', isActive: false },
        { id: 4, name: 'Hours', isActive: false },
        { id: 5, name: 'Attributes', isActive: false }
    ])
    const initialHours = {
        Monday: { open: { hours: 1, minutes: 30 }, close: { hours: 19, minutes: 0 } },
        Tuesday: { open: { hours: 10, minutes: 30 }, close: { hours: 19, minutes: 0 } },
        Wednesday: { open: { hours: 10, minutes: 30 }, close: { hours: 19, minutes: 0 } },
        Thursday: { open: { hours: 10, minutes: 30 }, close: { hours: 19, minutes: 0 } },
        Friday: { open: { hours: 10, minutes: 30 }, close: { hours: 19, minutes: 0 } },
        Saturday: { open: { hours: 10, minutes: 30 }, close: { hours: 19, minutes: 0 } },
        Sunday: { open: null, close: null },
    };
    const steps: Step[] = [
        { id: 1, label: 'Accessibility', subLabel: 'Select product' },
        { id: 2, label: 'Amenities', subLabel: 'Select type' },
        { id: 3, label: 'Crowd', subLabel: 'Select topic' },
        { id: 4, label: 'From the business', subLabel: 'Select date & time' },
        { id: 5, label: 'Planning', subLabel: 'Select HCP' },
        { id: 6, label: 'Payments', subLabel: 'Enter location for engagement' },
        { id: 7, label: 'Offering', subLabel: 'Invite speaker' },
        { id: 8, label: 'Recycling', subLabel: 'Select HCP' },
    ];

    const initialAttributesData = [{
        id: 1,
        title: 'Accessibility',
        description: 'Accessiblity ensures your business is easy to access for everyone, including those with disabilities.',
        options: [{
            value: 'Wheel Chair accessible elevator',
            selectedValue: ''
        }, {
            value: 'Wheel Chair accessible Parking Lot', selectedValue: ''
        }, {
            value: 'Wheel Chair accessible Restroom', selectedValue: ''
        }, { value: 'Wheel Chair accessible seating', selectedValue: '' }]
    }, {
        id: 2,
        title: 'Amenities',
        description: 'Amenities are additional features or services provided by your business to enhance customer experience',
        options: [{ value: 'Gender Neutral Restroom', selectedValue: '' }, { value: 'WIFI', selectedValue: '' }, { value: 'Wheel Chair accessible Restroom', selectedValue: '' }, { value: 'Wheel Chair accessible seating', selectedValue: '' }]
    }, {
        id: 3,
        title: 'Crowd',
        description: 'Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services',
        options: [{ value: 'LGBTQ+ friendly', selectedValue: '' }]
    }, {
        id: 4,
        title: 'From the business',
        description: `Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services. If you're not the owner, confirm with the owner before adding an attribute.`,
        options: [{ value: 'Identifies as women-owned', selectedValue: '' }]
    }, {
        id: 5,
        title: 'Planning',
        description: 'Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.',
        options: [{ value: 'Appointment required', selectedValue: '' }]
    }, {
        id: 6,
        title: 'Payments',
    }, {
        id: 7,
        title: 'Offering',
    }, {
        id: 8,
        title: 'Recycling',
    }]

    const hours = Array.from({ length: 25 }, (_, index) => index.toString())
    const minutes = Array.from({ length: 61 }, (_, index) => index.toString())
    const [activeAttribute, setActiveAttribute] = useState(1)
    const [attributesData, setAttributesData] = useState<AttributesData[]>(initialAttributesData)
    const selectedAtrributeData = attributesData.filter(data => data.id === activeAttribute)[0]


    return <Box ref={ref}>
        <Typography variant='body1' sx={{ fontWeight: 600 }}>Business Information</Typography>
        <Box sx={(theme) => ({ ...flexRowCenterCenter, justifyContent: 'flex-start', pt: 5, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            {profileTabs.map(tab => (
                <Typography
                    component='a'
                    href={`#${tab.name.toLowerCase()}-section`}
                    key={tab.id}
                    variant="body2"
                    sx={(theme) => ({ textDecoration: 'none', borderBottom: tab.isActive ? '4px solid #440BFF' : '', mr: 2, p: 1, cursor: 'pointer', boxSizing: 'border-box', fontWeight: 400, color: tab.isActive ? theme.palette.primary.main : theme.palette.text.disabled })}
                    onClick={() => setProfileTabs(profileTabs.map(profileTab => ({ ...profileTab, isActive: profileTab.id === tab.id })))}
                >
                    {tab.name}
                </Typography>
            ))}
        </Box>
        <Box id='about-section' sx={(theme) => ({ py: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <Typography variant='body1' sx={{ pb: 2 }}>About your business</Typography>
            <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between', pb: 1 }}>
                <Typography variant='body2'>Business Name</Typography>
                <FormControlLabel control={<Checkbox sx={(theme) => ({ color: theme.palette.grey['300'] })} />} label='Add additional information' sx={() => ({ "& .MuiTypography-root": { fontSize: '14px', fontWeight: 200 } })} />
            </Box>
            <StyledTextField
                variant='filled'
                fullWidth
                multiline
                minRows={2} />
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Primary Business Category</Typography>
            <StyledTextField
                variant='filled'
                fullWidth
                multiline
                minRows={2}
                sx={{
                    backgroundColor: 'rgba(248, 241, 241, 0.51)',
                    '& .MuiFilledInput-root::before': {
                        borderBottom: 'none',
                    },
                    '& .MuiFilledInput-root::after': {
                        borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                        fontWeight: 400,
                        p: 1
                    }
                }} />
            <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between', pt: 4, pb: 1 }}>
                <Typography variant='body2'>Business Description</Typography>
                <FormControlLabel control={<Checkbox sx={(theme) => ({ color: theme.palette.grey['300'] })} />} label='Add additional information' sx={() => ({ "& .MuiTypography-root": { fontSize: '14px', fontWeight: 200 } })} />
            </Box>
            <StyledTextField
                variant='filled'
                fullWidth
                multiline
                minRows={5} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography variant='caption'>{ }/750</Typography>
            </Box>
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Opening Date</Typography>
            <StyledTextField
                variant='filled'
                type='date' />
        </Box>
        <Box id='contact-section' sx={(theme) => ({ py: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <Typography variant='body1' sx={{ pb: 2 }}>Contact Information</Typography>
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Phone No</Typography>
            <StyledTextField
                variant='filled'
                fullWidth />
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Website</Typography>
            <StyledTextField
                variant='filled'
                fullWidth />
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Short Name</Typography>
            <StyledTextField
                variant='filled'
                fullWidth />
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Menu Link</Typography>
            <StyledTextField
                variant='filled'
                fullWidth />
        </Box>
        <Box id='location-section' sx={(theme) => ({ py: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <Typography variant='body1' sx={{ pb: 2 }}>Location and areas</Typography>
            {selectedBusinessProfiles.length === 1 ? <>
                <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Business Location</Typography>
                <StyledTextField
                    variant='filled'
                    fullWidth
                    multiline
                    minRows={2} />
                <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Service Area</Typography>
                <StyledTextField
                    variant='filled'
                    fullWidth
                    multiline
                    minRows={4} />
            </> : <Typography variant='body2' fontWeight={400}>Select individual location to view or edit business location Service area and map</Typography>}

        </Box>
        <Box id='hours-section' sx={(theme) => ({ py: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <Typography variant='body1' sx={{ pb: 2 }}>Opening Hours</Typography>
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Business Hours</Typography>
            <Box sx={{ bgcolor: 'rgba(248, 241, 241, 0.51)', p: 1, width: 'max-content' }}>
                <Typography variant='body2'>Open in main hours</Typography>
                {Object.entries(initialHours).map(([day, values]) => (
                    <Box sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start' }}>
                        <table>
                            <body>
                                <td style={{ minWidth: 150 }}><Typography variant='body2'>{day}</Typography></td>
                                {values.open ? <td>
                                    <TimeDropdown
                                        variant='standard'
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100,
                                                    boxSizing: 'border-box',
                                                    padding: 0,
                                                    overflowY: 'auto',
                                                    scrollbarWidth: 'none',
                                                },
                                            },
                                        }}
                                        size='small'
                                        IconComponent={() => null}
                                        value={values?.open?.hours}>
                                        {hours.map(hour =>
                                            <MenuItem sx={{ fontWeight: 400, fontSize: '12px', p: '1px' }} value={hour}>{String(hour).padStart(2, '0')}</MenuItem>
                                        )}
                                    </TimeDropdown>
                                    :
                                    <TimeDropdown
                                        variant='standard'
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100,
                                                    boxSizing: 'border-box',
                                                    padding: 0,
                                                    overflowY: 'auto',
                                                    scrollbarWidth: 'none',
                                                },
                                            },
                                        }}
                                        size='small'
                                        IconComponent={() => null}
                                        value={values?.open?.minutes}>
                                        {minutes.map(minute =>
                                            <MenuItem sx={{ fontWeight: 400, fontSize: '12px', p: '1px' }} value={minute}>{String(minute).padStart(2, '0')}</MenuItem>
                                        )}
                                    </TimeDropdown>

                                </td> : <td><Typography variant='body2'>Closed</Typography></td>}

                                {values.close ?
                                    <>
                                        <td>-</td>
                                        <td>
                                            <TimeDropdown
                                                variant='standard'
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100,
                                                            boxSizing: 'border-box',
                                                            padding: 0,
                                                            overflowY: 'auto',
                                                            scrollbarWidth: 'none',
                                                        },
                                                    },
                                                }}
                                                size='small'
                                                IconComponent={() => null}
                                                value={values?.close?.hours}>
                                                {hours.map(hour =>
                                                    <MenuItem sx={{ fontWeight: 400, fontSize: '12px', p: '1px' }} value={hour}>{String(hour).padStart(2, '0')}</MenuItem>
                                                )}
                                            </TimeDropdown>
                                            :
                                            <TimeDropdown
                                                variant='standard'
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 100,
                                                            boxSizing: 'border-box',
                                                            padding: 0,
                                                            overflowY: 'auto',
                                                            scrollbarWidth: 'none',
                                                        },
                                                    },
                                                }}
                                                size='small'
                                                IconComponent={() => null}
                                                value={values?.close?.minutes}>
                                                {minutes.map(minute =>
                                                    <MenuItem sx={{ fontWeight: 400, fontSize: '12px', p: '1px' }} value={minute}>{String(minute).padStart(2, '0')}</MenuItem>
                                                )}
                                            </TimeDropdown>

                                        </td>
                                    </> : null}
                            </body>
                        </table>

                    </Box>
                ))}
            </Box>
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Special Hours</Typography>
            <StyledTextField
                variant='filled'
                fullWidth />
            <Typography variant='body2' sx={{ pt: 4, pb: 2 }}>Add more hours</Typography>
            <Box gap={1} sx={{ display: 'flex' }}>
                {['Breakfast', 'Lunch', 'Dinner'].map((label, index) => (
                    <Chip
                        key={index}
                        label={label}
                        avatar={<img src={plus} alt="plus" style={{ width: 16, height: 16 }} />}
                        sx={(theme) => ({
                            fontSize: "14px",
                            fontWeight: 500,
                            backgroundColor: 'transparent',
                            border: `1px solid ${theme.palette.grey['500']}`
                        })}
                    />
                ))}
            </Box>
        </Box>
        <Box id='attributes-section' sx={() => ({ py: 3 })}>
            <Typography variant='body1' sx={{ pb: 2 }}>Attributes</Typography>
            <Box sx={{ display: 'flex' }}>
                <Box sx={(theme) => ({ borderRight: `1px solid ${theme.palette.grey['200']}`, p: 3, pb: 0 })}>
                    {steps.map((step, index) => {
                        const isActive = activeAttribute === step.id
                        const isChecked = attributesData[step.id - 1]?.options?.every(item => item.selectedValue)
                        return (
                            <Box key={step.id} onClick={() => setActiveAttribute(step.id)} sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', position: 'relative', mb: 3, cursor: 'pointer' }}>
                                {isChecked ? <Box sx={{
                                    ...flexRowCenterCenter,
                                    boxSizing: 'border-box',
                                    height: '34px', minHeight: 34, maxHeight: 34,
                                    width: '34px', minWidth: 34, maxWidth: 34
                                }}>
                                    <img src={checkedRound} alt='checked' sizes="24px" />
                                </Box> : <Box sx={(theme) => ({
                                    ...flexRowCenterCenter,
                                    height: '24px', minHeight: 24, maxHeight: 24,
                                    width: '24px', minWidth: 24, maxWidth: 24,
                                    border: `5px solid ${theme.palette.secondary.main}`,
                                    borderRadius: '50%',
                                    backgroundColor: isActive ? '#1650CF' : theme.palette.grey['300'],
                                    color: !isActive ? theme.palette.text.disabled : theme.palette.secondary.main,
                                    fontWeight: 600,
                                    zIndex: 1,
                                    fontSize: '14px'
                                })}
                                >
                                    {step.id}
                                </Box>}
                                <Box ml={4} sx={{ maxWidth: 144, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <Typography variant='body2' color={isActive ? 'primary' : 'textDisabled'} sx={{ fontWeight: 600 }}>{step.label}</Typography>
                                    <Typography variant='caption' color='textDisabled'>{step.subLabel}</Typography>
                                </Box>
                                {steps.length - 1 !== index ? <Box sx={{
                                    position: 'absolute',
                                    width: '1px',
                                    height: '100%',
                                    backgroundColor: 'lightgray',
                                    top: '38px',
                                    left: 17,
                                    zIndex: 0,
                                }}></Box> : null}
                            </Box>
                        )
                    })}
                </Box>
                {selectedAtrributeData ? <Box p={3}>
                    <Typography variant="body1" sx={{ fontWeight: 600, pb: 0.5 }}>{selectedAtrributeData.title}</Typography>
                    <Typography variant="caption" color='textDisabled'>{selectedAtrributeData.description ? selectedAtrributeData.description : 'No data available'}</Typography>
                    {selectedAtrributeData?.options?.map((option, index) => (
                        <Box key={index} sx={{ pt: 2 }}>
                            <Typography variant="body2" sx={{ pb: 0.5 }}>{option.value}</Typography>
                            <RadioGroup
                                name="radio-buttons-group"
                                row
                                value={option.selectedValue}
                                onChange={(event) => {
                                    const newData = attributesData.map(data => {
                                        if (data.id === selectedAtrributeData.id) {
                                            return ({
                                                ...data, options: data.options?.map(dataOption => {
                                                    if (dataOption.value === option.value) {
                                                        return ({ ...dataOption, selectedValue: event.target.value })
                                                    } return dataOption
                                                })
                                            })
                                        } return data
                                    })
                                    setAttributesData(newData)
                                }}
                            >
                                <FormControlLabel sx={(theme) => ({ color: theme.palette.text.disabled, "& .MuiTypography-root": { fontSize: '12px', fontWeight: 400 } })} value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel sx={(theme) => ({ color: theme.palette.text.disabled, "& .MuiTypography-root": { fontSize: '12px', fontWeight: 400 } })} value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    ))}
                </Box> : null}
            </Box>
        </Box>
    </Box>
})

const EditPhotos: React.FC = forwardRef((props, ref) => {
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const onDrop = (acceptedFiles: File[]) => {
        const previews = acceptedFiles.map(file => URL.createObjectURL(file));
        setSelectedFiles((prev: File[]) => [...prev, ...previews]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const fileArray = Array.from(files);
            const previews = fileArray.map(file => URL.createObjectURL(file));
            setSelectedFiles((prev: any) => [...prev, ...previews]);
        }
    };

    const handleRemoveFile = (index: number) => {
        setSelectedFiles((prev: any) => prev.filter((_: any, i: any) => i !== index));
    };


    return <Box ref={ref}>
        <Typography variant='body1' sx={(theme) => ({ fontWeight: 600, pb: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>Add photos and videos</Typography>
        <Box sx={{ ...flexRowCenterCenter, justifyContent: 'flex-end' }}>
            <Box sx={{ ...flexRowCenterCenter, pt: 2 }}>
                <FormControlLabel control={<Checkbox sx={(theme) => ({ color: theme.palette.grey['300'] })} />} label='Add additional information' sx={() => ({ "& .MuiTypography-root": { fontSize: '14px', fontWeight: 200 } })} />
            </Box>
        </Box>
        <Box sx={{ my: 3, mx: 5 }}>
            <Typography variant='body2' fontWeight={600}>Add Media</Typography>
            <Box
                {...getRootProps()}
                sx={(theme) => ({
                    border: `2px dashed ${theme.palette.grey["300"]}`,
                    borderRadius: 2,
                    textAlign: "center",
                    width: "100%",
                    p: 3,
                    boxSizing: "border-box",
                })}
            >
                <input {...getInputProps()} />
                <img src={fileUpload} alt="file-upload" sizes="32px" />
                <Box sx={{ ...flexRowCenterCenter }}>
                    <Typography variant="body2" sx={{ my: 1 }}>
                        Drag and Drop or{" "}
                    </Typography>
                    <label htmlFor='picker'>
                        <Button
                            variant="text"
                            sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
                        >
                            Click to upload
                        </Button>
                    </label>
                    <input id='picker' type="file" hidden onChange={handleFileChange} multiple />
                </Box>
                <Typography variant="caption" color="textDisabled">
                    Supported formats: Images or videos. Max Size: 25MB
                </Typography>
            </Box>
        </Box>
        <Typography variant='body2'>Selected pictures for upload</Typography>
        <Box>
            <Box gap={1} sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', flexWrap: 'wrap', pt: 3 }}>
                {selectedFiles?.map((preview: any, index: any) => (
                    <Box key={index} mx={1} my={1} position="relative" sx={{ width: 100, height: 100 }}>
                        <img
                            src={preview}
                            alt='Pic Thumbnail'
                            style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }}
                        />
                        <IconButton
                            onClick={() => handleRemoveFile(index)}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bgcolor: 'white',
                                '&:hover': { bgcolor: 'grey.200' },
                            }}
                        >
                            <img src={close} alt='remove' style={{ width: 10, height: 10 }} />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        </Box>
    </Box>
})

const AddUpdate: React.FC = forwardRef((props, ref) => {
    const [tabs, setTabs] = useState([
        { id: 1, name: 'Add Update', isActive: true },
        { id: 2, name: 'Add Offer', isActive: false },
        { id: 3, name: 'Add Event', isActive: false }
    ])
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const onDrop = (acceptedFiles: File[]) => {
        const previews = acceptedFiles.map(file => URL.createObjectURL(file));
        setSelectedFiles((prev: File[]) => [...prev, ...previews]);
    };
    console.log(selectedFiles)
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const fileArray = Array.from(files);
            const previews = fileArray.map(file => URL.createObjectURL(file));
            setSelectedFiles((prev: any) => [...prev, ...previews]);
        }
    };

    return <Box ref={ref}>
        <Typography variant='body1' sx={() => ({ fontWeight: 600, pb: 3 })}>Add Update</Typography>
        <Box sx={(theme) => ({ pb: 2, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            {tabs.map(tab => (
                <Typography
                    component='a'
                    href={`#${tab.name.toLowerCase()}-section`}
                    key={tab.id}
                    variant="body2"
                    sx={(theme) => ({ textDecoration: 'none', borderBottom: tab.isActive ? '4px solid #440BFF' : '', mr: 2, p: 1, cursor: 'pointer', boxSizing: 'border-box', fontWeight: 400, color: tab.isActive ? theme.palette.primary.main : theme.palette.text.disabled })}
                    onClick={() => setTabs(tabs.map(updateTab => ({ ...updateTab, isActive: updateTab.id === tab.id })))}
                >
                    {tab.name}
                </Typography>
            ))}
        </Box>
        {tabs[0].isActive ? (
            <Box>
                <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between', pt: 2 }}>
                    <Typography variant='body1' fontWeight={400}>Add update about the business</Typography>
                    <Box sx={{ ...flexRowCenterCenter }}>
                        <FormControlLabel control={<Checkbox sx={(theme) => ({ color: theme.palette.grey['300'] })} />} label='Add additional information' sx={() => ({ "& .MuiTypography-root": { fontSize: '14px', fontWeight: 200 } })} />
                    </Box>
                </Box>
                <Box sx={{ my: 3, mx: 5 }}>
                    <Typography variant='body2' fontWeight={600}>Add Media</Typography>
                    <Box
                        {...getRootProps()}
                        sx={(theme) => ({
                            border: `2px dashed ${theme.palette.grey["300"]}`,
                            borderRadius: 2,
                            textAlign: "center",
                            width: "100%",
                            p: 3,
                            boxSizing: "border-box",
                        })}
                    >
                        <input {...getInputProps()} />
                        <img src={fileUpload} alt="file-upload" sizes="32px" />
                        <Box sx={{ ...flexRowCenterCenter }}>
                            <Typography variant="body2" sx={{ my: 1 }}>
                                Drag and Drop or{" "}
                            </Typography>
                            <label htmlFor='picker'>
                                <Button
                                    variant="text"
                                    sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
                                >
                                    Click to upload
                                </Button>
                            </label>
                            <input id='picker' type="file" hidden onChange={handleFileChange} multiple />
                        </Box>
                        <Typography variant="caption" color="textDisabled">
                            Supported formats: Images or videos. Max Size: 25MB
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Add a description</Typography>
                    <StyledTextField multiline minRows={2} variant='filled' fullWidth />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                        <Typography variant='caption'>0/1500</Typography>
                    </Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Add a button</Typography>
                    <DropDown value='None'>
                        {buttonsList.map((button) => <MenuItem sx={{ fontWeight: 400, fontSize: '12px' }} value={button}>{button}</MenuItem>)}
                    </DropDown>
                    <Box sx={{ pt: 1 }}>
                        <TextField variant='outlined' placeholder='Add link for your button' size='small' fullWidth />
                    </Box>
                </Box>
            </Box>
        ) : tabs[1].isActive ? (
            <Box>
                <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between', pt: 2 }}>
                    <Typography variant='body1' fontWeight={400}>Add offer</Typography>
                    <Box sx={{ ...flexRowCenterCenter }}>
                        <FormControlLabel control={<Checkbox sx={(theme) => ({ color: theme.palette.grey['300'] })} />} label='Add additional information' sx={() => ({ "& .MuiTypography-root": { fontSize: '14px', fontWeight: 200 } })} />
                    </Box>
                </Box>
                <Box sx={{ my: 3, mx: 5 }}>
                    <Typography variant='body2' fontWeight={600}>Add Media</Typography>
                    <Box
                        {...getRootProps()}
                        sx={(theme) => ({
                            border: `2px dashed ${theme.palette.grey["300"]}`,
                            borderRadius: 2,
                            textAlign: "center",
                            width: "100%",
                            p: 3,
                            boxSizing: "border-box",
                        })}
                    >
                        <input {...getInputProps()} />
                        <img src={fileUpload} alt="file-upload" sizes="32px" />
                        <Box sx={{ ...flexRowCenterCenter }}>
                            <Typography variant="body2" sx={{ my: 1 }}>
                                Drag and Drop or{" "}
                            </Typography>
                            <label htmlFor='picker'>
                                <Button
                                    variant="text"
                                    sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
                                >
                                    Click to upload
                                </Button>
                            </label>
                            <input id='picker' type="file" hidden onChange={handleFileChange} multiple />
                        </Box>
                        <Typography variant="caption" color="textDisabled">
                            Supported formats: Images or videos. Max Size: 25MB
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Offer Title (Example: 20% off in store or online)</Typography>
                    <StyledTextField variant='filled' fullWidth />
                    <Box gap={2} sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', py: 3 }}>
                        <Box>
                            <Typography variant='body2' sx={{ pb: 1 }}>Start Date</Typography>
                            <StyledTextField
                                variant='filled'
                                type='date' />
                        </Box>
                        <Box>
                            <Typography variant='body2' sx={{ pb: 1 }}>End Date</Typography>
                            <StyledTextField
                                variant='filled'
                                type='date' />
                        </Box>
                    </Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>More details about offer</Typography>
                    <StyledTextField multiline minRows={2} variant='filled' fullWidth />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                        <Typography variant='caption'>0/1500</Typography>
                    </Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Voucher code (optional)</Typography>
                    <StyledTextField multiline minRows={2} variant='filled' fullWidth sx={{ mb: 3 }} />
                    <Typography variant='body2' sx={{ pb: 1 }}>Link to redeem offer (optional)</Typography>
                    <StyledTextField multiline minRows={2} variant='filled' fullWidth sx={{ mb: 3 }} />
                    <Typography variant='body2' sx={{ pb: 1 }}>Terms and conditions (optional)</Typography>
                    <StyledTextField multiline minRows={2} variant='filled' fullWidth />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                        <Typography variant='caption'>0/5000</Typography>
                    </Box>
                </Box>
            </Box>
        ) : tabs[2].isActive ? (
            <Box>
                <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between', pt: 2 }}>
                    <Typography variant='body1' fontWeight={400}>Add event</Typography>
                    <Box sx={{ ...flexRowCenterCenter }}>
                        <FormControlLabel control={<Checkbox sx={(theme) => ({ color: theme.palette.grey['300'] })} />} label='Add additional information' sx={() => ({ "& .MuiTypography-root": { fontSize: '14px', fontWeight: 200 } })} />
                    </Box>
                </Box>
                <Box sx={{ my: 3, mx: 5 }}>
                    <Typography variant='body2' fontWeight={600}>Add Media</Typography>
                    <Box
                        {...getRootProps()}
                        sx={(theme) => ({
                            border: `2px dashed ${theme.palette.grey["300"]}`,
                            borderRadius: 2,
                            textAlign: "center",
                            width: "100%",
                            p: 3,
                            boxSizing: "border-box",
                        })}
                    >
                        <input {...getInputProps()} />
                        <img src={fileUpload} alt="file-upload" sizes="32px" />
                        <Box sx={{ ...flexRowCenterCenter }}>
                            <Typography variant="body2" sx={{ my: 1 }}>
                                Drag and Drop or{" "}
                            </Typography>
                            <label htmlFor='picker'>
                                <Button
                                    variant="text"
                                    sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
                                >
                                    Click to upload
                                </Button>
                            </label>
                            <input id='picker' type="file" hidden onChange={handleFileChange} multiple />
                        </Box>
                        <Typography variant="caption" color="textDisabled">
                            Supported formats: Images or videos. Max Size: 25MB
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Event Title (Example: Sale of the week)</Typography>
                    <StyledTextField variant='filled' fullWidth />
                    <Box sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', pt: 3, pb: 1 }}>
                        <Box>
                            <Typography variant='body2' sx={{ pb: 1 }}>Start Date</Typography>
                            <StyledTextField
                                variant='filled'
                                type='date' />
                        </Box>
                        <Box>
                            <Typography variant='body2' sx={{ pb: 1 }}>Start Time (optional)</Typography>
                            <StyledTextField
                                variant='filled'
                                type='time' />
                        </Box>
                    </Box>
                    <Box sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', py: 3 }}>
                        <Box>
                            <Typography variant='body2' sx={{ pb: 1 }}>End Date</Typography>
                            <StyledTextField
                                variant='filled'
                                type='date' />
                        </Box>
                        <Box>
                            <Typography variant='body2' sx={{ pb: 1 }}>End Time (optional)</Typography>
                            <StyledTextField
                                variant='filled'
                                type='time' />
                        </Box>
                    </Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Event Details</Typography>
                    <StyledTextField multiline minRows={2} variant='filled' fullWidth />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                        <Typography variant='caption'>0/1500</Typography>
                    </Box>
                    <Typography variant='body2' sx={{ pb: 1 }}>Add a button</Typography>
                    <DropDown value='None'>
                        {buttonsList.map((button) => <MenuItem sx={{ fontWeight: 400, fontSize: '12px' }} value={button}>{button}</MenuItem>)}
                    </DropDown>
                    <Box sx={{ pt: 1 }}>
                        <TextField variant='outlined' placeholder='Add link for your button' size='small' fullWidth />
                    </Box>
                </Box>
            </Box>
        ) : null}
    </Box>
})

const EditProducts: React.FC<{ selectedBusinessProfiles: string[] }> = forwardRef(({ selectedBusinessProfiles }, ref) => {
    const [tabs, setTabs] = useState([
        { id: 1, name: 'Listed Products', isActive: true },
        { id: 2, name: 'Edit Product', isActive: false },
        { id: 3, name: 'Add Product', isActive: false }
    ])

    const [products, setProducts] = useState<ProductProps[]>([
        { id: 1, status: 'not approved', description: 'Product Description will be coming here, however every is covered in two lines', currentPrice: 5400, mrp: 5600, image, isSpecial: true },
        { id: 2, status: 'pending', description: 'Product Description will be coming here, however every is covered in two lines', currentPrice: 5400, mrp: 5600, image, isSpecial: false }
    ])
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const onDrop = (acceptedFiles: any) => setSelectedFile(acceptedFiles[0]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (event: any) =>
        setSelectedFile(event.target.files[0]);

    const removeProduct = (currentProduct: ProductProps) => {
        setProducts(products.filter((product) => product.id !== currentProduct.id))
        setOpenConfirmation(false)
    }

    const onClickTab = (tab: any) => {
        if ((selectedBusinessProfiles.length !== 1 || products.length == 0) && tab.id === 2) return
        setTabs(tabs.map(updateTab => ({ ...updateTab, isActive: updateTab.id === tab.id })))
    }
    return (
        <Box ref={ref}>
            <Typography variant='body1' sx={() => ({ fontWeight: 600, pb: 3 })}>Add Products</Typography>
            <Box sx={(theme) => ({ pb: 2, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
                {tabs.map(tab => (
                    <Typography
                        component='a'
                        href={`#${tab.name.toLowerCase()}-section`}
                        key={tab.id}
                        variant="body2"
                        sx={(theme) => ({ textDecoration: 'none', borderBottom: tab.isActive ? '4px solid #440BFF' : '', mr: 2, p: 1, cursor: 'pointer', boxSizing: 'border-box', fontWeight: 400, color: tab.isActive ? theme.palette.primary.main : theme.palette.text.disabled })}
                        onClick={() => onClickTab(tab)}
                    >
                        {tab.name}
                    </Typography>
                ))}
            </Box>
            {selectedBusinessProfiles.length === 1 && products.length > 0 ? <Box sx={{ py: 3 }}>
                <Box sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start' }}>
                    <DropDown defaultValue='all-products' sx={{ mr: 5 }}>
                        <MenuItem value='all-products' sx={{ fontSize: '14px', fontWeight: 400 }}>All Products</MenuItem>
                    </DropDown>
                    <ButtonOutline sx={{ borderRadius: '4px' }}>Add Product</ButtonOutline>
                </Box>
                {tabs[0].isActive ? <Box gap={2} sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start', flexWrap: 'wrap', py: 4 }}>
                    {products?.map((product) => (
                        <Box sx={(theme) => ({ borderRadius: '8px', border: `1px solid ${theme.palette.grey['300']}` })}>
                            <Box sx={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', borderRadius: '8px 8px 0px 0px', width: 180, height: 100, p: 1, boxSizing: 'border-box' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Box>
                                            <Chip sx={(theme) => ({ backgroundColor: theme.palette.secondary.main, fontSize: '10px', textTransform: 'uppercase', height: 20, mb: 1 })} label={product.status} />
                                        </Box>
                                        {product.isSpecial ? <Chip sx={(theme) => ({ backgroundColor: theme.palette.grey['700'], color: theme.palette.secondary.main, fontSize: '10px', fontWeight: 600, height: 20, width: 60, borderRadius: '3px', textTransform: 'capitalize' })} label='Special' /> : null}
                                    </Box>
                                    <IconButton onClick={() => setOpenConfirmation(true)} sx={{ height: 15, width: 15 }}>
                                        <img src={close} alt='close' style={{ width: 14, height: 14 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box sx={{ p: 1, boxSizing: 'border-box', maxWidth: 180, top: -100 }}>
                                <Typography variant='caption' sx={{ maxWidth: 180, display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}>{product.description}</Typography>
                                <Box pt={2}>
                                    <Typography variant='caption' color='success' mr={2}>Rs. {product.currentPrice.toFixed(2)}</Typography>
                                    <Typography variant='caption' color='textDisabled'>Rs. {product.mrp}</Typography>
                                </Box>
                            </Box>
                            <Dialog
                                sx={{ '& .MuiDialog-paper': { width: 300, borderRadius: '11px', textAlign: 'center' } }}
                                maxWidth="xs"
                                open={openConfirmation}
                            >
                                <DialogTitle>Warning</DialogTitle>
                                <DialogContent>
                                    <Typography variant='body2'>Once the product listing is deleted, cannot be reverted</Typography>
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                                    <Button autoFocus sx={{ mr: 4 }} onClick={() => setOpenConfirmation(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant='contained' sx={{ border: 'none', backgroundColor: 'rgba(22, 80, 207, 1)' }} onClick={() => removeProduct(product)}>Proceed</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    ))}
                </Box> :
                    <Grid2 gap={3} container sx={{ pt: 3 }}>
                        <Grid2 size={{ xs: 12, lg: 6.7 }}>
                            <Box sx={{ width: '100%', minWidth: 250 }}>
                                <Typography variant='body2'>Product Name</Typography>
                                <TextField variant='outlined' fullWidth size='small' sx={{ mb: 0.2 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography variant='caption'>0/58</Typography>
                                </Box>
                                <Typography variant='body2' mb={1}>Select a category</Typography>
                                <DropDown label='Select' labelVisible={true} width='100%'>
                                    <MenuItem></MenuItem>
                                </DropDown>
                                <Typography variant='body2' mt={2}>Product price (INR) - optional</Typography>
                                <TextField variant='outlined' fullWidth size='small' />
                                <Typography variant='body2'>Product description - optional</Typography>
                                <TextField variant='outlined' fullWidth size='small' sx={{ mb: 0.2 }} multiline minRows={3} />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography variant='caption'>0/1000</Typography>
                                </Box>
                                <Typography variant='body2'>Product landing page URL  - optional</Typography>
                                <TextField variant='outlined' fullWidth size='small' sx={{ mb: 0.2 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography variant='caption'>0/1,500</Typography>
                                </Box>
                                <Box>
                                    <FormControlLabel control={<Checkbox />} label={
                                        <Box maxWidth={220}>
                                            <Typography sx={{ fontSize: '12px' }}>Mark as Special</Typography>
                                            <Typography variant='caption' color='textDisabled'>Product marked as Special as shown on the top of the page</Typography>
                                        </Box>
                                    } />
                                </Box>
                            </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 12, lg: 4.7 }}>
                            <Box py={3} sx={{ width: '100%' }}>
                                <Box sx={(theme) => ({ borderRadius: '15px', border: `1px solid ${theme.palette.grey['400']}` })}>
                                    <Box
                                        {...getRootProps()}
                                        sx={() => ({
                                            textAlign: "center",
                                            width: "100%",
                                            p: 3,
                                            boxSizing: "border-box",
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <img src={fileUpload} alt="file-upload" sizes="32px" />
                                        <Box sx={{ ...flexRowCenterCenter }}>
                                            <Typography variant="caption" sx={{ my: 1, whiteSpace: 'none' }}>
                                                Drag and Drop or{" "}
                                            </Typography>
                                            <Button
                                                variant="text"
                                                component="label"
                                                sx={{ textTransform: "none", border: "none", color: "#1650CF", fontSize: '12px', whiteSpace: 'none', pl: 0.5, pr: 0 }}
                                            >
                                                Click to upload
                                                <input type="file" hidden onChange={handleFileChange} />
                                            </Button>
                                        </Box>
                                        <Typography variant="caption" color="textDisabled">
                                            Supported formats: Images or videos. Max Size: 25MB
                                        </Typography>
                                        {selectedFile && (
                                            <Typography variant="body2" sx={{ mt: 1 }}>
                                                Selected file: {selectedFile?.name}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid2>
                    </Grid2>}
            </Box> : <Box sx={{ textAlign: 'center', pt: 5 }}>
                <Typography variant='body2' sx={{ pb: 2 }}>Select individual listing to see the listed products</Typography>
                <Typography variant='body2' sx={{ pb: 2 }}>Or</Typography>
                <Typography variant='body2' sx={{ pb: 2 }}>No product listed</Typography>
                <Button sx={{ color: '#1650CF', border: '1px solid #1650CF', width: 120 }}>Add new</Button>
            </Box>}
        </Box>
    )
})

const EditServices: React.FC = forwardRef((props, ref) => {
    const [categories, setCategories] = useState<CategoryProps[]>([
        { id: 1, category: 'primary', company: 'software', services: [{ id: 1, name: 'Website Development', priceId: 1, price: 500, description: '' }] },
        { id: 2, category: 'additional', company: 'software', services: [{ id: 1, name: 'Website Development', priceId: 1, price: 500, description: '' }] },
    ])
    const [services, setServices] = useState(['Website Development', 'Mobile app development', 'Website Development and Service Management', 'Software development'])
    const [openDeleteCategory, setOpenDeleteCategory] = useState(false)
    const [openDeleteService, setOpenDeleteService] = useState(false)
    const onDeleteCategory = (currentCategory: CategoryProps) => {
        setCategories(categories.filter(category => category.id !== currentCategory.id))
        setOpenDeleteCategory(false)
    }
    const [selectedService, setSelectedService] = useState<ServiceProps | null>(null)
    const [addCategory, setAddCategory] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<CategoryProps | null>(null)
    console.log(setServices)
    const priceMenu = [
        { id: 1, name: 'No Price' },
        { id: 2, name: 'Free' },
        { id: 3, name: 'Fixed' },
        { id: 4, name: 'From' }
    ]
    const onDeleteService = (currentCategory: CategoryProps, currentService: ServiceProps) => {
        const newCategories = categories.map((category) => {
            if (category.id === currentCategory.id) {
                return { ...category, services: category.services.filter(service => service.id !== currentService.id) }
            }
            return category
        }
        )
        setCategories(newCategories)
        setOpenDeleteService(false)
        setSelectedCategory(null)
        setSelectedService(null)
    }
    if (selectedCategory && selectedService) return <Box ref={ref} >
        <Typography variant='body1' sx={(theme) => ({ fontWeight: 600, pb: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <IconButton onClick={() => setSelectedService(null)}>
                <img src={back} alt='back-button' />
            </IconButton>
            Edit Services
        </Typography>
        <Box pt={2}>
            <Typography variant='caption'>Service</Typography>
            <Typography variant='body2'>{selectedService.name}</Typography>
        </Box>
        <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between', py: 2, pr: 4 }}>
            <Box>
                <Typography variant='caption' sx={{ display: 'block', }}>Price</Typography>
                <Box mt={1} mb={2} width={250}>
                    <DropDown value={selectedService.priceId} width='100%'>
                        {priceMenu.map(item => <MenuItem key={item.id} value={item.id} sx={{ fontSize: '12px', fontWeight: 400 }}>{item.name}</MenuItem>)}
                    </DropDown>
                </Box>
            </Box>
            <Box>
                <Typography variant='caption' sx={{ display: 'block' }}>Servie price (INR)</Typography>
                <TextField variant='outlined' size='small' value={selectedService.price} />
            </Box>
        </Box>
        <Box pr={4}>
            <Typography variant='caption' sx={{ display: 'block' }}>Service Description</Typography>
            <TextField variant='outlined' multiline minRows={4} sx={{ mb: 0.3 }} fullWidth value={selectedService.description} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography variant='caption' sx={{ fontSize: '10px' }}>0/300</Typography>
            </Box>
            <Button sx={{ color: '#1650CF', border: 'none', mt: 3 }} onClick={() => setOpenDeleteService(true)}>Delete Service</Button>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: 550, borderRadius: '11px', } }}
                maxWidth="md"
                open={openDeleteService}
            >
                <DialogTitle sx={{ fontSize: '16px' }}>Delete Service?</DialogTitle>
                <DialogContent>
                    <Typography variant='body2' color='textDisabled'>{selectedService.name} will be removed from all categories and will no longer be visible on your Business Profile</Typography>
                </DialogContent>
                <DialogActions sx={{ pb: 2 }}>
                    <Button sx={{ border: 'none' }} autoFocus onClick={() => setOpenDeleteService(false)}>
                        Cancel
                    </Button>
                    <Button sx={{ border: 'none', color: 'rgba(22, 80, 207, 1)' }} onClick={() => onDeleteService(selectedCategory, selectedService)}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </Box>
    if (selectedCategory) return <Box ref={ref}>
        <Typography variant='body1' sx={(theme) => ({ fontWeight: 600, pb: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <IconButton onClick={() => setSelectedCategory(null)}>
                <img src={back} alt='back-button' />
            </IconButton>
            Add Services
        </Typography>
        <Box py={2} sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>{selectedCategory.category} Category</Typography>
            <Typography variant='body1' sx={{ textTransform: 'capitalize', fontWeight: 400 }}>{selectedCategory.company} Company</Typography>
        </Box>
        {/* <List sx={{ p: 0 }}>
            {selectedCategory.services.map(service =>
                <ListItem sx={(theme) => ({ p: 0, borderBottom: `1px solid ${theme.palette.grey['200']}`, ...flexRowCenterCenter, justifyContent: 'space-between' })}>
                    <Typography variant='body2'>{service.name} </Typography>
                    <IconButton onClick={() => {
                        setSelectedService(service)
                    }}>
                        <img src={rightArrow} alt='right-arrow' style={{ width: 30, height: 30 }} />
                    </IconButton>
                </ListItem>)}
        </List> */}
        {selectedCategory.category === 'primary' ? <Box gap={1} sx={{ display: 'flex', flexWrap: 'wrap', py: 4 }}>
            {services.map((service, index) => <Chip key={index} label={service} icon={<img src={plus} alt='plus' />} sx={(theme) => ({ backgroundColor: 'transparent', border: `1px solid ${theme.palette.grey['300']}`, borderRadius: '4px' })} />)}
        </Box> : null}

        <Typography variant='caption' sx={{ display: 'block', mt: 4 }}>Don’t see a service you offer? Create your own</Typography>
        <Box sx={{ minWidth: '60%', maxWidth: 300, display: 'flex' }}>
            <Box width='100%'>
                <TextField variant='outlined' size='small' fullWidth sx={{ mb: 0.2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant='caption'>0/58</Typography>
                </Box>
            </Box>
            <IconButton sx={{ height: 34, width: 34, mt: 1 }}><img src={close} alt='close' style={{ width: 16, height: 16 }} /></IconButton>
        </Box>

        <Button startIcon={<img src={bluePlus} alt='plus' />} sx={{ color: '#1650CF', border: 'none', my: 1, textTransform: 'unset' }}>Add custom service</Button>
    </Box>
    if (addCategory) return <Box ref={ref}>
        <Typography variant='body1' sx={(theme) => ({ fontWeight: 600, pb: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
            <IconButton onClick={() => setAddCategory(false)}>
                <img src={back} alt='back-button' />
            </IconButton>
            Add a business category
        </Typography>
        <Box pt={2}>
            <Typography variant='caption'>Enter a business category</Typography>
            <Box>
                <TextField variant='outlined' size='small' sx={{ minWidth: '60%' }} />
            </Box>
        </Box>
        <Typography variant='caption' sx={{ display: 'block', mt: 4 }}>Don’t see a service you offer? Create your own</Typography>
        <Button startIcon={<img src={bluePlus} alt='plus' />} sx={{ color: '#1650CF', border: 'none', my: 2 }}>Add more services</Button>
    </Box>
    return <Box ref={ref}>
        <Typography variant='body1' sx={(theme) => ({ fontWeight: 600, pb: 3, borderBottom: `1px solid ${theme.palette.grey['200']}` })}>Add Services</Typography>
        {categories.map(category => <Box>
            <Box py={2} sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey['200']}` })}>
                <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>{category.category} Category</Typography>
                <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between' }}>
                    <Typography variant='body1' sx={{ textTransform: 'capitalize', fontWeight: 400, height: 37, ...flexColumnCenterCenter }}>{category.company} Company</Typography>
                    {category.category === 'primary' ? null : <Button sx={{ color: '#1650CF', border: 'none' }} onClick={() => setOpenDeleteCategory(true)}>Delete</Button>}
                </Box>
            </Box>
            <List sx={{ p: 0 }}>
                {category.services.map(service =>
                    <ListItem sx={(theme) => ({ p: 0, borderBottom: `1px solid ${theme.palette.grey['200']}`, ...flexRowCenterCenter, justifyContent: 'space-between' })}>
                        <Typography variant='body2'>{service.name} </Typography>
                        <IconButton onClick={() => {
                            setSelectedCategory(category)
                            setSelectedService(service)
                        }}>
                            <img src={rightArrow} alt='right-arrow' style={{ width: 30, height: 30 }} />
                        </IconButton>
                    </ListItem>)}
            </List>
            <Button startIcon={<img src={bluePlus} alt='plus' />} sx={{ color: '#1650CF', border: 'none', my: 3 }} onClick={() => setSelectedCategory(category)}>Add more services</Button>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: 550, borderRadius: '11px', } }}
                maxWidth="md"
                open={openDeleteCategory}
            >
                <DialogTitle sx={{ fontSize: '16px' }}>Delete Category?</DialogTitle>
                <DialogContent>
                    <Typography variant='body2' color='textDisabled'>Removing a category will also remove these services from your Business Profile on Google</Typography>
                </DialogContent>
                <DialogActions sx={{ pb: 2 }}>
                    <Button sx={{ border: 'none' }} autoFocus onClick={() => setOpenDeleteCategory(false)}>
                        Cancel
                    </Button>
                    <Button sx={{ border: 'none', color: 'rgba(22, 80, 207, 1)' }} onClick={() => onDeleteCategory(category)}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>)}
        <Box sx={{ ...flexRowCenterCenter, pt: 5 }}>
            <Button sx={{ color: '#1650CF', textTransform: 'unset', px: 2 }} onClick={() => setAddCategory(true)}>Add another business category</Button>
        </Box>
    </Box>
})

export const EditBusinessListing: React.FC<EditBusinessListingProps> = forwardRef(({ setActionOrOpen, action }, ref) => {
    const [selectedBusinessProfiles, setSelectedBusinessProfiles] = useState<string[]>([])
    const [currentAction, setCurrentAction] = useState(action)

    const getComponent = () => {
        switch (currentAction) {
            case '1':
                return <EditProfile selectedBusinessProfiles={selectedBusinessProfiles} />
            case '2':
                return <EditPhotos />
            case '3':
                return <EditProducts selectedBusinessProfiles={selectedBusinessProfiles} />
            case '4':
                return <EditServices />
            case '5':
                return <AddUpdate />
            default:
                return <></>
        }
    }

    return (
        <Box
            ref={ref}
            sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main,
                ...style,
                maxWidth: 850,
                minWidth: 370,
                width: '75%',
                maxHeight: '95%'
            })}
        >
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.grey["100"],
                    p: "20px 24px",
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                })}
            >
                <Typography variant="h5" sx={{ display: 'inline' }}>Edit Business Listing</Typography>
                <img
                    src={close}
                    alt="close"
                    sizes="24px"
                    style={{ cursor: "pointer" }}
                    onClick={() => setActionOrOpen({ open: false })}
                />
            </Box>
            <Box
                sx={{
                    overflowY: "auto",
                    maxHeight: `calc(100vh - 350px)`,
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Box gap={2} sx={(theme) => ({ borderRight: `1px solid ${theme.palette.grey['200']}`, p: 3, display: 'flex', flexDirection: 'column' })}>
                        {iconsList.map(item => (
                            <MenuItem
                                key={item.id}
                                sx={{ ...flexColumnCenterCenter, p: 0.5, boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16)', borderRadius: 1, border: currentAction == item.id ? '1px solid #4B52FF' : '', cursor: 'pointer' }}
                                onClick={() => setCurrentAction(item.id)}
                            >
                                <img src={item.icon} alt={item.name} style={{ width: 35, height: 35 }} />
                                <Typography variant='caption' color='textDisabled' sx={{ whiteSpace: 'nowrap', fontSize: '10px' }}>{item.name}</Typography>
                            </MenuItem>
                        ))}
                    </Box>
                    <Box width='100%' sx={{ boxSizing: 'border-box' }}>
                        <Box width='100%' sx={(theme) => ({ p: 3, borderBottom: `1px solid ${theme.palette.grey['200']}`, boxSizing: 'border-box' })}>
                            <Typography variant='body1' sx={{ fontWeight: 600, pb: 1 }}>Selected Business Profiles</Typography>
                            <DropDown
                                width='100%'
                                multiple
                                label={`${selectedBusinessProfiles.length} Locations Selected`}
                                labelVisible={selectedBusinessProfiles.length < 1}
                                variant='outlined'
                                value={selectedBusinessProfiles}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    const newValue: any = typeof value === 'string' ? value.split(',') : value;
                                    setSelectedBusinessProfiles(newValue.filter((val: any) => val !== undefined));
                                }}
                                renderValue={() => {
                                    return (
                                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', width: '100%' }}>
                                            {`${selectedBusinessProfiles.length} Locations Selected`}
                                        </span>
                                    );
                                }}
                            >
                                <Box sx={{ m: 1, ml: 1.5, mr: 5 }}>
                                    <Searchbar fullWidth />
                                </Box>
                                {initialBusinessProfiles.map((profile) => (
                                    <MenuItem key={profile.id} value={profile.id}>
                                        <Checkbox checked={selectedBusinessProfiles.indexOf(profile.id) > -1} />
                                        <ListItemText
                                            primary={profile.name}
                                            secondary={
                                                <Box sx={{ ...flexRowCenterCenter, justifyContent: 'space-between' }}>
                                                    <Typography variant='caption' color='textDisabled' sx={{ fontWeight: 400, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        <span style={{ paddingRight: '8px' }}>{profile.id}</span>
                                                        <span style={{ paddingRight: '8px' }}>{profile.address}</span>
                                                        <span style={{ paddingRight: '8px' }}>{profile.city}</span>
                                                        <span style={{ paddingRight: '8px' }}>{profile.state}</span>
                                                    </Typography>
                                                </Box>
                                            }
                                            primaryTypographyProps={{ style: { fontSize: '14px', fontWeight: 500 } }}
                                        />
                                    </MenuItem>
                                ))}
                            </DropDown>
                        </Box>
                        <Box p={3}>
                            {getComponent()}
                        </Box>
                    </Box>
                </Box>

            </Box>
            <Divider />
            <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
                <Button sx={{ mr: 1 }} onClick={() => setActionOrOpen({ open: false })}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{ backgroundColor: "#1650CF", border: 'none' }}>
                    Save
                </Button>
            </Box>
        </Box>
    )
})