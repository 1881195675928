import { Box, Grid2, Typography } from "@mui/material";

import { flexColumnCenterCenter, flexRowCenterCenter } from "utils/styles";
import store from "assets/icons/store.svg";
import search from "assets/icons/search.svg";
import eye from "assets/icons/eye.svg";
import dropdown from "assets/icons/dropdown.svg";
import increment from "assets/icons/increment.svg";
import decrement from "assets/icons/decrement.svg";
import {
  CallsComponent,
  ChatsComponent,
  DiscoveryCard,
  RatingsComponent,
  SearchAnalyticsCard,
} from "pages/Insights/Components";

const cardsData = [
  {
    title: "Top stores",
    icon: store,
    value: (
      <Typography variant="h2">
        120
        <span style={{ fontWeight:400, fontSize:'18px'}}>
          /200
        </span>
      </Typography>
    ),
    descriptionValue: "4 stores",
    description: "increased",
    percentageChange: 15,
  },
  {
    title: "Search Keywords",
    icon: search,
    value: "2.0 M",
    descriptionValue: "120k",
    description: "searches",
    percentageChange: 8,
  },
  {
    title: "Views",
    icon: eye,
    value: "4.0 M",
    descriptionValue: "200k",
    description: "views this",
    percentageChange: -3,
  },
];

const Discovery = () => {
  // const matches = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <Box>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, md: 8 }}>
          <Box width="100%">
            <Box
              gap={3}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                flexWrap: "wrap",
                mb: 3,
              }}
            >
              {cardsData?.map((data, index) => (
                <Box key={index}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #DBDBDB",
                    p: "16px 24px",
                    minWidth: 290,
                    height: 160,
                    flexGrow: 1,
                    boxSizing: "border-box",
                    ...flexColumnCenterCenter,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" sx={{ textAlign: "left" }}>
                      {data?.title}
                    </Typography>
                    <img src={data?.icon} alt={data?.title} sizes="32px" />
                  </Box>
                  <Box width="100%">
                    {typeof data?.value === 'string' ? <Typography variant="h2" sx={{ textAlign: "left" }}>
                      {data?.value}
                    </Typography> : <Box sx={{textAlign:'left'}}>{data?.value}</Box>}
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ textAlign: "left" }}
                      >
                        <strong>{data?.descriptionValue}</strong>{" "}
                        <span>{data?.description}</span>
                      </Typography>
                      <Box sx={{ ...flexRowCenterCenter }}>
                        <img
                          src={
                            data?.percentageChange >= 0 ? increment : decrement
                          }
                          alt={
                            data?.percentageChange >= 0
                              ? "increment"
                              : "decrement"
                          }
                        />
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          sx={{ pl: 1 }}
                          color={
                            data?.percentageChange >= 0 ? "success" : "error"
                          }
                        >
                          {Math.abs(data?.percentageChange)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #DBDBDB",
                p: "32px 24px",
                flexGrow: 1,
                mb: 3,
              }}
            >
              <Box
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography variant="h5">Discovery</Typography>
                <img src={dropdown} alt="dropdown" />
              </Box>
              <DiscoveryCard />
            </Box>

            <SearchAnalyticsCard size={{ xs: 12 }} />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <CallsComponent />
          <Box mb={3}>
            <ChatsComponent />
          </Box>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
              mb: 3,
            }}
          >
            <RatingsComponent />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Discovery;
