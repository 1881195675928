import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface AccountsState {
  accountsList: any[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: AccountsState = {
  accountsList: null,
  loading: false,
  error: null,
};

export const getAccountsList = createAsyncThunk<
  any[],
  void,
  { rejectValue: string }
>("/account-list", async (_, thunkAPI) => {
  try {
    const response = await apiManager.get("/account-list");
    return response.data.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || "Failed to get accounts list"
    );
  }
});

const userManagementSlice = createSlice({
  name: "user-management",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get accounts list
      .addCase(getAccountsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccountsList.fulfilled, (state, action) => {
        state.accountsList = action.payload;
        state.loading = false;
      })
      .addCase(getAccountsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userManagementSlice.reducer;
