import React, { useState } from "react";
import { DateRange } from "react-date-range";
import { addDays, addMonths, addWeeks, addYears, format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css";
import { Box, List, ListItem, Typography } from "@mui/material";
import { flexRowCenterCenter } from "utils/styles";
import { Button } from "components/Buttons";
import calendar from "assets/icons/calendar.svg";
import arrowRightShort from "assets/icons/arrow-right-short.svg";

const presets = [
  { id: 1, name: "Last 7 Days" },
  { id: 2, name: "Last 30 Days" },
  { id: 3, name: "Last 12 Weeks" },
  { id: 4, name: "Last 1 Year" },
  { id: 5, name: "Month to Date" },
  { id: 6, name: "Quarter to Date" },
  { id: 7, name: "Year to Date" },
];

const getStartDate = (id: number) => {
  const today = new Date()
  const currentMonth = today.getMonth()
  const currentYear = today.getFullYear()
  const quarterDate = addMonths(today, -3)
  const quarterMonth = quarterDate.getMonth()
  const quarterYear = quarterDate.getFullYear()
  switch (id) {
    case 1:
      return addDays(today, -6);
    case 2:
      return addDays(today, -30);
    case 3:
      return addWeeks(today, -12);
    case 4:
      return addYears(today, -1);
    case 5:
      return new Date(currentYear, currentMonth, 1);
    case 6:
      return new Date(quarterYear, quarterMonth, 1);
    case 7:
      return new Date(currentYear, 0, 1);
    default:
      return today;
  }
};

const CustomDateRangePicker: React.FC = () => {
  const [activeTab, setActiveTab] = useState<null | {
    id: number;
    name: string;
  }>(null);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className="custom-date-range-picker">
      <div>
        <Button
          variant="outlined"
          sx={{ py: 1, pl: 1, pr: "9.2px", whiteSpace: 'nowrap' }}
          onClick={() => setShowCalendar(!showCalendar)}
          startIcon={<img src={calendar} alt="calendar" sizes="24px" />}
        >{`${format(range[0]?.startDate, "MMM dd, yyyy")} - ${format(range[0]?.endDate, "MMM dd, yyyy")}`}</Button>

        {showCalendar && (
          <Box
            sx={(theme) => ({
              ...flexRowCenterCenter,
              height: 500,
              position: "absolute",
              zIndex: 10,
              right: 10,
              mt: 1,
              borderRadius: "12px",
              border: `1px solid ${theme.palette.grey["200"]}`,
              backgroundColor: "#ffffff",
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)'
            })}
          >
            <List
              sx={() => ({
                flexGrow: 1,
                position: "relative",
                height: 478,
                py: 0,
                my: 2.5,
                borderRadius: "12px 0 0 12px",
              })}
            >
              {presets.map((preset: any) => (
                <ListItem
                  onClick={() => {
                    setActiveTab(preset);
                    setRange([
                      {
                        startDate: getStartDate(preset.id),
                        endDate: new Date(),
                        key: preset.id,
                      },
                    ]);
                  }}
                  sx={(theme) => ({
                    backgroundColor:
                      activeTab?.id === preset?.id
                        ? theme.palette.grey["100"]
                        : null,
                    cursor: "pointer",
                  })}
                  key={preset.id}
                >
                  <Box sx={{ py: 0.5 }}>
                    <Typography variant="body2">{preset.name}</Typography>
                    <Box
                      sx={(theme) => ({
                        ...flexRowCenterCenter,
                        justifyContent: "space-between",
                        color: theme.palette.text.disabled,
                      })}
                    >
                      <Typography variant="caption">
                        {format(getStartDate(preset.id), "dd-MM-yyyy")}
                      </Typography>
                      <img
                        src={arrowRightShort}
                        alt="arrow-right-short"
                        sizes="16px"
                        style={{ padding: "0px 22px" }}
                      />
                      <Typography variant="caption">
                        {format(new Date(), "dd-MM-yyyy")}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Box
              sx={(theme) => ({
                borderLeft: `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              <Box
                sx={{ ...flexRowCenterCenter, textAlign: "left", p: 2, pb: 0 }}
              >
                <Typography variant="body2" sx={{ width: "50%", pl: "5px" }}>
                  Start Date
                </Typography>
                <Typography variant="body2" sx={{ width: "50%", pl: "5px" }}>
                  End Date
                </Typography>
              </Box>
              <Box px={2}>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item: any) => setRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  className="calendar"
                  months={2}
                  direction="horizontal"
                  monthDisplayFormat="MMMM yyyy"
                  dateDisplayFormat="yyyy-MM-dd"
                />
              </Box>
              <Box
                sx={(theme) => ({
                  ...flexRowCenterCenter,
                  justifyContent: "flex-end",
                  borderTop: `1px solid ${theme.palette.grey["200"]}`,
                  py: 2,
                  pr: 2,
                })}
              >
                <Button>Clear</Button>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#1650CF", border: "none", ml: 1 }}
                  onClick={() => setShowCalendar(false)}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

export default CustomDateRangePicker;
