import React from 'react'
import { Checkbox as CheckboxComponent, CheckboxProps } from '@mui/material'

const Checkbox = (props:CheckboxProps) => {
    return (
        <CheckboxComponent
            sx={(theme) => ({
                color: theme.palette.primary.main,
            })}
            size="small"
            {...props}
        />
    )
}

export default Checkbox