import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Modal,
  Pagination,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomDateRangePicker from "components/DateRangePicker";
import React, { useEffect, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import ArrowLongUp from "assets/icons/arrowLongUp";
import star from "assets/icons/filled-star.svg";
import google from "assets/icons/google.svg";
import share from "assets/icons/share.svg";
import pencil from "assets/icons/pencil.svg";
import filter from "assets/icons/filters-lines.svg";
import trash from "assets/icons/trash.svg";
import settings from "assets/icons/settings.svg";
import { BorderLinearProgress } from "pages/Insights/Components";
import Checkbox from "components/Checkbox";
import ProfileImageContainer from "components/ProfileImageContainer";
import Dot from "components/Dot";
import { Button } from "components/Buttons";
import Select, { DropDown } from "components/Select";
import Searchbar from "components/Searchbar";
import { BulkReplyModal, FilterModal, IndividualReplyModal } from "./Modals";
import { Review } from "interfaces/Reviews.types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getReviews } from "store/reviewsSlice";
import { formatDistanceToNow, parseISO } from "date-fns";

const ratingsData = [
  { rating: 5, num: 100, color: "#5EC37F" },
  { rating: 4, num: 80, color: "#AD95FB" },
  { rating: 3, num: 60, color: "#FFC564" },
  { rating: 2, num: 40, color: "#608DF1" },
  { rating: 1, num: 20, color: "#E45858" },
];

const tabs = [
  { id: 1, label: "All" },
  { id: 2, label: "Replied" },
  { id: 3, label: "Unreplied" },
];

const Reviews = () => {
  const { reviewsList, count, error } = useSelector(
    (state: RootState) => state.reviews
  );
  const dispatch = useDispatch<AppDispatch>();
  const [reviewsData, setReviewsData] = useState<any[] | null>(reviewsList);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedReviews, setSelectedReviews] = useState<Review[] | null>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openBulkReplyModal, setOpenBulkReplyModal] = useState(false);
  const [individualReplyModal, setIndividualReplyModal] = useState<{
    open: boolean;
    review: Review | null;
  }>({ open: false, review: null });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({
    page: 1,
    rowsPerPage: 10,
  });

  useEffect(() => {
    dispatch(
      getReviews({ page: pagination.page, limit: pagination.rowsPerPage })
    );
  }, [pagination]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  useEffect(() => {
    setReviewsData(reviewsList);
  }, [reviewsList]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setReviewsData(
      reviewsData
        ? reviewsData.map((data) => {
            return { ...data, checked: event.target.checked };
          })
        : null
    );
    if (event.target.checked) {
      setSelectedReviews(reviewsData);
    } else setSelectedReviews([]);
  };

  const onSelectReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    selected: Review
  ) => {
    setReviewsData(
      reviewsData
        ? reviewsData.map((data) => {
            if (data.id === selected.id) {
              return { ...data, checked: event.target.checked };
            }
            return data;
          })
        : null
    );
    setSelectedReviews((prevSelected) => {
      if (prevSelected?.some((data) => data.id === selected.id)) {
        return prevSelected.filter((data) => data.id !== selected.id);
      } else {
        return prevSelected ? [...prevSelected, selected] : [selected];
      }
    });
  };

  // const handleEdit = (currentReview: Review) => {
  //   const updatedReview = reviewsData.map((data => {
  //     if (data.id === currentReview.id) {
  //       if(currentReview?.reply?.reply===''){
  //         delete data.reply
  //         return data
  //       }
  //       return { ...data, reply: data.reply ? { ...data.reply, isEdit: !currentReview.reply?.isEdit } : { time: '1 sec ago', reply: '', isEdit: true } }
  //     } return data
  //   }))
  //   setReviewsData(updatedReview)
  // }

  const handleDelete = (currentReview: Review) => {
    const updatedReview = reviewsData
      ? reviewsData.map((data) => {
          if (data.id === currentReview.id && data.reply) {
            delete data.reply;
          }
          return data;
        })
      : null;
    setReviewsData(updatedReview);
  };

  // const onChangeReply = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currentReview: Review) => {
  //   const updatedReview = reviewsData.map((data => {
  //     if (data.id === currentReview.id && data.reply) {
  //       return { ...data, reply: { ...data.reply, reply: event.target.value } }
  //     } return data
  //   }))
  //   setReviewsData(updatedReview)
  // }

  const onChangeTab = (tab: any) => {
    setActiveTab(tab);
    setSelectedReviews([]);
    switch (tab.id) {
      case 1:
        setReviewsData(reviewsList);
        return;
      case 2:
        setReviewsData(
          reviewsList ? reviewsList.filter((data) => data.reply_comment) : []
        );
        return;
      case 3:
        setReviewsData(
          reviewsList ? reviewsList.filter((data) => !data.reply_comment) : []
        );
        return;
      default:
        return;
    }
  };

  const handleBulkReply = () => setOpenBulkReplyModal(true);

  return (
    <Box
      sx={{ width: "100%", p: 3, boxSizing: "border-box", textAlign: "left" }}
    >
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 4,
        }}
      >
        <Typography variant="h5">Reviews</Typography>
        <CustomDateRangePicker />
      </Box>
      {error ? (
        error
      ) : (
        <>
          <Box
            gap={2}
            sx={(theme) => ({
              ...flexRowCenterCenter,
              justifyContent: "flex-start",
              flexWrap: "wrap",
              pb: 4,
              mb: 4,
              borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            })}
          >
            <Box
              sx={(theme) => ({
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
                px: 3,
                borderRight: `1px solid ${theme.palette.grey["200"]}`,
                minWidth: 188,
                width: "calc(100% / 3 - 16px)",
                boxSizing: "border-box",
              })}
            >
              <Box>
                <Typography variant="body1" sx={{ pb: 3 }}>
                  Total Reviews
                </Typography>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    pb: 0.5,
                  }}
                >
                  <Typography variant="h1">20.0k</Typography>
                  <Chip
                    sx={{
                      backgroundColor: "#E8F6ED",
                      color: "#137A5F",
                      pl: 1,
                      "& .MuiChip-label": { pl: 0.5 },
                    }}
                    label="5%"
                    icon={<ArrowLongUp color="#137A5F" />}
                  />
                </Box>
                <Typography variant="body2" sx={{ pb: 0.5 }}>
                  Growth in reviews on this year
                </Typography>
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                ...flexRowCenterCenter,
                px: 3,
                borderRight: `1px solid ${theme.palette.grey["200"]}`,
                minWidth: 225,
                width: "calc(100% / 3 - 16px)",
                boxSizing: "border-box",
              })}
            >
              <Box>
                <Typography variant="body1" sx={{ pb: 3 }}>
                  Average Rating
                </Typography>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                    pb: 0.5,
                  }}
                >
                  <Typography variant="h1" sx={{ pr: 1 }}>
                    4.0
                  </Typography>
                  <Rating readOnly value={4} precision={0.5} />
                </Box>
                <Typography variant="body2" sx={{ pb: 0.5 }}>
                  Growth in reviews on this year
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                ...flexRowCenterCenter,
                minWidth: 250,
                width: "calc(100% / 3 - 16px)",
                boxSizing: "border-box",
                px: 3,
              }}
            >
              <Box>
                {ratingsData.map((rating, index) => (
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                      pb: 0.5,
                    }}
                    key={index}
                  >
                    <img src={star} alt="star" />
                    <Typography variant="body1" sx={{ pl: 1, pr: 2 }}>
                      {rating.rating}
                    </Typography>
                    <BorderLinearProgress
                      value={rating.num}
                      bgcolor={rating.color}
                      variant="determinate"
                      sx={{ minWidth: 180, mr: 2 }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {rating.num}%
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              gap={2}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Checkbox
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                  })}
                  value={selectAll}
                  onChange={handleSelectAll}
                />
                {tabs.map((tab) => (
                  <Typography
                    key={tab.id}
                    variant="body1"
                    sx={{
                      borderBottom:
                        tab.id === activeTab.id ? "4px solid #440BFF" : "",
                      mx: 2,
                      p: 1,
                      cursor: "pointer",
                      boxSizing: "border-box",
                    }}
                    onClick={() => onChangeTab(tab)}
                  >
                    {tab.label}
                  </Typography>
                ))}
              </Box>
              <Box
                gap={2}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                <Select defaultValue={1}>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value={1}
                  >
                    Most relevant
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value={2}
                  >
                    Newest
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value={3}
                  >
                    Highest rating
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value={4}
                  >
                    Lowest rating
                  </MenuItem>
                </Select>
                <Button
                  sx={{ borderRadius: "4px" }}
                  onClick={() => setOpenFilterModal(true)}
                  endIcon={<img src={filter} alt="filter" />}
                  size="medium"
                >
                  Filter
                </Button>
                <Button
                  sx={{
                    color: "#1650CF",
                    borderColor: "#1650CF",
                    borderRadius: "4px",
                    whiteSpace: "nowrap",
                  }}
                  size="medium"
                  onClick={handleBulkReply}
                >
                  Bulk Reply
                </Button>
                <Searchbar sx={{ ml: 0 }} />
                <IconButton>
                  <img src={settings} alt="settings" sizes="37px" />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600, pt: 3 }}>
                {count} Reviews
              </Typography>
              {reviewsData?.map((data: any) => (
                <Box
                  key={data.id}
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "flex-start",
                    py: 4,
                    borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                  })}
                >
                  <Checkbox
                    checked={data.checked}
                    onChange={(event) => onSelectReview(event, data)}
                  />
                  <Box sx={{ mr: 3 }}>
                    <ProfileImageContainer
                      pic={data.reviewer_photo}
                      icon={google}
                    />
                  </Box>
                  <Box sx={{ maxWidth: "80%" }}>
                    <Typography variant="body1">
                      {data.reviewer_name}
                    </Typography>
                    <Tooltip title={data.title}>
                      <Typography variant="caption" sx={{ display: "inline" }}>
                        {data.title}
                      </Typography>
                    </Tooltip>
                    <br />
                    <Tooltip title={data.locality}>
                      <Typography
                        variant="caption"
                        color="textDisabled"
                        sx={{ pb: 0.5 }}
                      >
                        {data.locality}
                      </Typography>
                    </Tooltip>
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        justifyContent: "flex-start",
                        pt: 0.5,
                        pb: 2,
                      }}
                    >
                      <Rating
                        readOnly
                        value={parseFloat(data.star_rating)}
                        precision={0.5}
                      />
                      <Dot />
                      <Typography variant="caption" color="textDisabled">
                        {data?.create_time
                          ? formatDistanceToNow(parseISO(data?.create_time), {
                              addSuffix: true,
                            })
                          : ""}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ pb: 2 }}>
                      {data.comment}
                    </Typography>

                    {data.reply_comment ? (
                      <Box>
                        <Box
                          sx={(theme) => ({
                            borderLeft: `4px solid ${theme.palette.grey["200"]}`,
                            pl: 1,
                            mb: 2,
                          })}
                        >
                          <Box
                            sx={{
                              ...flexRowCenterCenter,
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography variant="body2">
                              Response from the owner
                            </Typography>
                            <Dot />
                            <Typography variant="caption" color="textDisabled">
                              {data?.reply_time
                                ? formatDistanceToNow(
                                    parseISO(data?.reply_time),
                                    {
                                      addSuffix: true,
                                    }
                                  )
                                : ""}
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="textDisabled">
                            {data.reply_comment}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            ...flexRowCenterCenter,
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="text"
                            sx={{ border: "none", fontSize: "12px", mr: 1 }}
                            startIcon={<img src={pencil} alt="edit" />}
                            onClick={() =>
                              setIndividualReplyModal({
                                review: data,
                                open: true,
                              })
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            sx={{ border: "none", fontSize: "12px" }}
                            startIcon={<img src={trash} alt="delete" />}
                            onClick={() => handleDelete(data)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Button
                        variant="text"
                        sx={{ border: "none" }}
                        startIcon={<img src={share} alt="reply" />}
                        onClick={() =>
                          setIndividualReplyModal({ review: data, open: true })
                        }
                      >
                        Reply
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            gap={2}
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              flexWrap: "wrap",
              pt: 4,
            }}
          >
            <Box
              gap={2}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <DropDown
                label="Rows per rage"
                width={100}
                onChange={(e: any) =>
                  setPagination({ ...pagination, rowsPerPage: e.target.value })
                }
                value={pagination.rowsPerPage}
              >
                {[10, 50, 100].map((rows, index) => (
                  <MenuItem
                    value={rows}
                    key={index}
                    sx={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    {rows}
                  </MenuItem>
                ))}
              </DropDown>
              <Typography variant="body2">
                Showing {pagination?.rowsPerPage} of {count}{" "}
              </Typography>
            </Box>

            <Pagination
              count={count ? Math.round(count / pagination?.rowsPerPage) : 0}
              variant="outlined"
              shape="rounded"
              page={pagination?.page}
              onChange={handleChangePage}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#222124",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  color: "#1976d2",
                  borderColor: "#1976d2",
                  backgroundColor: "#ffffff",
                  fontWeight: 500,
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            />
          </Box>
        </>
      )}
      <Modal
        open={openBulkReplyModal}
        onClose={() => setOpenBulkReplyModal(false)}
      >
        <BulkReplyModal
          selectedReviews={selectedReviews}
          onClose={() => setOpenBulkReplyModal(false)}
        />
      </Modal>
      <Modal
        open={individualReplyModal.open}
        onClose={() =>
          setIndividualReplyModal({ ...individualReplyModal, open: false })
        }
      >
        <IndividualReplyModal
          selectedReview={individualReplyModal.review}
          onClose={() =>
            setIndividualReplyModal({ ...individualReplyModal, open: false })
          }
        />
      </Modal>
      <Modal open={openFilterModal} onClose={() => setOpenFilterModal(false)}>
        <FilterModal onClose={() => setOpenFilterModal(false)} />
      </Modal>
    </Box>
  );
};

export default Reviews;
