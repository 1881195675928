import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import TextField, { CustomTextField } from "components/TextField";
import { flexRowCenterCenter } from "utils/styles";
import { ReactComponent as DownIcon } from "assets/icons/down.svg";
import google from "assets/icons/google.svg";
import close from "assets/icons/close.svg";
import plus from "assets/icons/add-black.svg";
import infoIcon from "assets/icons/info-icon.svg";
import fileUpload from "assets/icons/file-upload.svg";
import { useState } from "react";
import { Button } from "components/Buttons";
import { useDropzone } from "react-dropzone";
import Select from "components/Select";

const selectPlatformOptions = [{ title: "Google", icon: google }];

const chipsData = [
  "Name",
  "Business Address",
  "City",
  "State",
  "Pincode",
  "Area",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 750,
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  maxHeight: "90%",
};

const CreatePostModal: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const [postTitle, setPostTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const onDrop = (acceptedFiles: any) => setSelectedFile(acceptedFiles[0]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileChange = (event: any) =>
    setSelectedFile(event.target.files[0]);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        ...style,
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.grey["100"],
          p: "20px 24px",
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        })}
      >
        <Typography variant="h5">Create New Post</Typography>
        <img
          src={close}
          alt="close"
          sizes="24px"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Box>
      <Box
        sx={{
          p: 3,
          pb: 6,
          overflowY: "auto",
          maxHeight: `calc(100vh - 350px)`,
        }}
      >
        <Box
          sx={{ pb: 2, ...flexRowCenterCenter, justifyContent: "flex-start" }}
        >
          <img
            src={infoIcon}
            alt="info-icon"
            sizes="40px"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body2">
            Regular posts keep prospects engaged, informing them about updates
            and promotions, which boosts visibility and drives customer actions.
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Post Title
        </Typography>
        <TextField
          value={postTitle}
          fullWidth
          variant="outlined"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setPostTitle(event.target.value)}
          sx={{ mb: 2 }}
        />
        <Box
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            pb: 2,
          }}
        >
          <Box width="100%">
            <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
              Select Platform
            </Typography>
            <Autocomplete
              multiple
              id="platforms"
              options={selectPlatformOptions}
              getOptionLabel={(option) => option.title}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.title}
                    {...getTagProps({ index })}
                    avatar={
                      <Avatar
                        src={option.icon}
                        alt={option.title}
                        sx={{ width: 20, height: 20 }}
                      />
                    }
                  />
                ))
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ ...flexRowCenterCenter }}>
                    <img src={option.icon} alt={option.title} />
                    <Typography
                      variant="body2"
                      color="textDisabled"
                      sx={{ ml: 1 }}
                    >
                      {option.title}
                    </Typography>
                  </Box>
                </li>
              )}
              defaultValue={[selectPlatformOptions[0]]}
              popupIcon={<DownIcon />}
              renderInput={(params) => (
                <CustomTextField {...params} size='small' variant="outlined"/>
              )}
              sx={{
                mr: 3,
                "& .MuiChip-deleteIcon": {
                  color: "black !important",
                },
              }}
            />
          </Box>
          <Box width="100%">
            <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
              Post Type
            </Typography>
            <Select placeholder="Bulk Actions"><p>option</p></Select>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Description
        </Typography>
        <TextField
          placeholder="Write Something..."
          multiline
          variant="outlined"
          minRows={4}
          fullWidth
          sx={{ mb: 0 }}
        />
        <Box
          gap={1}
          sx={(theme) => ({
            p: 2,
            display: "flex",
            flexWrap: "wrap",
            border: `1px solid ${theme.palette.grey["400"]}`,
            borderTop: "none",
            borderRadius: "0 0 8px 8px",
            mb: 2,
          })}
        >
          {chipsData.map((label, index) => (
            <Chip
              key={index}
              label={label}
              avatar={<img src={plus} alt="plus" sizes="10px" />}
              sx={() => ({
                fontSize: "14px",
                fontWeight: 500,
              })}
            />
          ))}
        </Box>
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          Add Media
        </Typography>
        <Box
          {...getRootProps()}
          sx={(theme) => ({
            border: `2px dashed ${theme.palette.grey["300"]}`,
            borderRadius: 2,
            textAlign: "center",
            width: "100%",
            p: 3,
            boxSizing: "border-box",
          })}
        >
          <input {...getInputProps()} />
          <img src={fileUpload} alt="file-upload" sizes="32px" />
          <Box sx={{ ...flexRowCenterCenter }}>
            <Typography variant="body2" sx={{ my: 1 }}>
              Drag and Drop or{" "}
            </Typography>
            <Button
              variant="text"
              component="label"
              sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
            >
              Click to upload
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Box>
          <Typography variant="caption" color="textDisabled">
            Supported formats: Images or videos. Max Size: 25MB
          </Typography>
          {selectedFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected file: {selectedFile?.name}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider sx={{}} />
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end", p: 3 }}>
        <Button sx={{ mr: 1 }} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ backgroundColor: "#1650CF" }}>
          Publish
        </Button>
      </Box>
    </Box>
  );
};

export default CreatePostModal;
