import {
  Box,
  Grid2,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { CardComponent } from "./Components";
import { flexRowCenterCenter } from "utils/styles";
import { theme } from "utils/theme";

const InsightsLoader = () => {
  const matches = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <Grid2 container spacing={3}>
      <CardComponent
        content={
          <>
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 2 }}
              width={180}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 1 }}
              width={130}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 3 }}
              width={50}
              height={20}
            />
            <Box
              gap={1}
              sx={() => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              })}
            >
              {[150, 200, 180, 140, 110, 230].map((height, index) => (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: "4px" }}
                  key={index}
                  width={40}
                  height={height}
                />
              ))}
            </Box>
          </>
        }
      />
      <CardComponent
        content={
          <>
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 2 }}
              width={180}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 1 }}
              width={130}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 3 }}
              width={50}
              height={20}
            />
            <TableContainer
              sx={{
                "& .MuiTableCell-root": { p: 1 },
              }}
            >
              <Table aria-label="keyword table">
                <TableBody>
                  {[1, 2, 3, 4, 5, 6].map((row, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            sx={{ borderRadius: "4px" }}
                            width={150}
                            height={20}
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            sx={{ borderRadius: "4px" }}
                            width={100}
                            height={20}
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            sx={{ borderRadius: "4px" }}
                            width={180}
                            height={20}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      />
      <CardComponent
        content={
          <>
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 2 }}
              width={50}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 1 }}
              width={130}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "4px", mb: 3 }}
              width={150}
              height={20}
            />
            <Box sx={{ ...flexRowCenterCenter }}>
              <div
                style={{
                  position: "relative",
                  width: "200px",
                  height: "100px",
                  overflow: "hidden",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={200}
                  height={400}
                  sx={{
                    borderRadius: "50%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    background: "transparent",
                    border: "10px solid #ccc",
                    zIndex: 1,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    height: "60%",
                    backgroundColor: "white",
                    zIndex: 2,
                  }}
                />
              </div>
            </Box>
          </>
        }
      />
      {matches && (
        <CardComponent
          content={
            <>
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "4px", mb: 2 }}
                width={180}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "4px", mb: 1 }}
                width={130}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "4px", mb: 3 }}
                width={50}
                height={20}
              />
              <Box
                gap={1}
                sx={() => ({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                })}
              >
                {[150, 200, 180, 140, 110, 230].map((height, index) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: "4px" }}
                    key={index}
                    width={40}
                    height={height}
                  />
                ))}
              </Box>
            </>
          }
        />
      )}
      <Grid2
        size={{ xs: 12, lg: 8 }}
        sx={{
          p: 3,
          border: "1px solid #dbdbdb",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "4px" }}
            width={200}
            height={30}
          />

          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "4px" }}
            width={180}
            height={30}
          />
        </Box>
        <Box
          gap={3}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            py: 5,
          }}
        >
          <Box gap={1} sx={{ width: "50%", display: "flex", flexWrap: "wrap" }}>
            {[1, 2, 3, 4].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                sx={{ borderRadius: "4px" }}
                width="49%"
                height={100}
              />
            ))}
          </Box>
          <Box sx={{ width: "50%" }}>
            {[1, 2, 3].map((item) => (
              <Box key={item} mb={5}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: "4px", mb: 1 }}
                  width={180}
                  height={10}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: "4px", mb: 1 }}
                  width="100%"
                  height={10}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Grid2>
      {!matches && (
        <CardComponent
          content={
            <>
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "4px", mb: 2 }}
                width={180}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "4px", mb: 1 }}
                width={130}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "4px", mb: 3 }}
                width={50}
                height={20}
              />
              <Box
                gap={1}
                sx={() => ({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                })}
              >
                {[150, 200, 180, 140, 110, 230].map((height, index) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: "4px" }}
                    key={index}
                    width={40}
                    height={height}
                  />
                ))}
              </Box>
            </>
          }
        />
      )}
    </Grid2>
  );
};

export default InsightsLoader;
