export const getHealthStatus = (value:number) =>{
    switch (true) {
        case value <= 80:
            return 'Poor'
        case (value >=81 && value <=85):
            return 'Good'
        case value >85:
            return 'Excellent'
        default:
            return ''
    }
}