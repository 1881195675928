import {
  Box,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import {
  BorderLinearProgress,
  ChatsComponent,
} from "pages/Insights/Components";
import google from "assets/icons/google.svg";
import johnDoe from "assets/images/johnDoe.png";
import blackDanny from "assets/images/blackDanny.png";
import andrewJames from "assets/images/andrewJames.png";
import estherHoward from "assets/images/estherHoward.png";
import { flexRowCenterCenter } from "utils/styles";
import ProfileImageContainer from "components/ProfileImageContainer";

const chatPerformanceData = [
  { duration: 25, color: "#76AEF6", description: "First Response time" },
  { duration: 5, color: "#F1C58F", description: "Response time" },
  { duration: 2, color: "#E2807E", description: "Wait time" },
  { duration: 1, color: "#56B398", description: "Resolution time" },
];
const tableData = [
  {
    business: "Nike Store T Nagar",
    openMessages: 123,
    google: 2,
    facebook: 2,
    sulekha: 2,
    responseTime: 12,
    replied: 2,
    notReplied: 2,
  },
  {
    business: "Nike Store Annanagar",
    openMessages: 78,
    google: 3,
    facebook: 3,
    sulekha: 3,
    responseTime: 32,
    replied: 3,
    notReplied: 3,
  },
  {
    business: "Nike Store Ambattur",
    openMessages: 235,
    google: 4,
    facebook: 4,
    sulekha: 4,
    responseTime: 22,
    replied: 4,
    notReplied: 4,
  },
  {
    business: "Nike Store VR Mall",
    openMessages: 89,
    google: 3,
    facebook: 1,
    sulekha: 1,
    responseTime: 21,
    replied: 11,
    notReplied: 1,
  },
  {
    business: "Nike Store Ambattur",
    openMessages: 110,
    google: 9,
    facebook: 2,
    sulekha: 2,
    responseTime: 9,
    replied: 2,
    notReplied: 2,
  },
];
const Chat = () => {
  return (
    <Box width="100%" sx={{ textAlign: "left" }}>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
          <ChatsComponent />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: 3,
              pb: 2,
              flexGrow: 1,
              minHeight: 340,
            }}
          >
            <MessagesCard />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: 3,
              pb: 2,
              flexGrow: 1,
              minHeight: 340,
              height: "100%",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Chat Performance Trends
            </Typography>
            <Typography variant="h2" sx={{ pb: 2 }}>
              32m
            </Typography>
            <Box
              gap={5}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {chatPerformanceData.map((data, index) => (
                <Box key={index} sx={{ flexGrow: 1, width: "calc(50% - 48px)" }}>
                  <Typography variant="h5">{data.duration}m</Typography>
                  <BorderLinearProgress
                    value={(data.duration * 100) / 60}
                    variant="determinate"
                    bgcolor={data.color}
                    sx={{ my: 1 }}
                  />
                  <Typography variant="body2" color="textDisabled">
                    {data.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          borderRadius: "8px",
          border: "1px solid #DBDBDB",
          p: 3,
          pb: 2,
          flexGrow: 1,
          my: 3,
        }}
      >
        <Typography variant="h5" sx={{ pb: 2 }}>
          Message Performance by Branches
        </Typography>
        <TableContainer
          sx={{
            pb: 3,
            "& .MuiTableCell-root": {
              color: "#222124",
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#F6F6F6",
                }}
              >
                <TableCell color="primary">Business</TableCell>
                <TableCell color="primary" sx={{ textAlign: "right" }}>
                  Open Messages
                </TableCell>
                <TableCell color="primary" sx={{ textAlign: "right" }}>
                  Google
                </TableCell>
                <TableCell color="primary" sx={{ textAlign: "right" }}>
                  Facebook
                </TableCell>
                <TableCell color="primary" sx={{ textAlign: "right" }}>
                  Sulekha
                </TableCell>
                <TableCell color="primary" sx={{ textAlign: "right" }}>
                  Response Time
                </TableCell>
                <TableCell color="primary" sx={{ textAlign: "right" }}>
                  Replied
                </TableCell>
                <TableCell
                  color="primary"
                  sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                >
                  Not Replied
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {row.business}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.openMessages}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.google}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.facebook}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.sulekha}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.responseTime}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.replied}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {row.notReplied}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const messagesData = [
  {
    name: "John Doe",
    message: "What’s driving the AI-first customer service is unavoidable...",
    time: "1:20 AM",
    profilePic: johnDoe,
    icon: google,
  },
  {
    name: "Black Danny",
    message: "Hi do you have latest nike collection",
    time: "2hr ago",
    profilePic: blackDanny,
    icon: google,
  },
  {
    name: "Andrew James",
    message: "Hi do you have latest nike collection",
    time: "28 May",
    profilePic: andrewJames,
    icon: google,
  },
  {
    name: "Esther Howard",
    message: "Hi do you have latest nike collection",
    time: "24 May",
    profilePic: estherHoward,
    icon: google,
  },
];
const MessagesCard: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <Typography variant="h5">Messages</Typography>
        <Typography variant="body2">View all</Typography>
      </Box>
      {messagesData.map((data, index) => (
        <Box key={index}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            py: 1.5,
            borderBottom:
              index === messagesData.length - 1 ? "" : "1px solid #DBDBDB",
          }}
        >
          <Box sx={{ ...flexRowCenterCenter }}>
            <ProfileImageContainer pic={data.profilePic} icon={data.icon} />
            <Box ml={2}>
              <Typography variant="body1">{data.name}</Typography>
              <Typography variant="caption" color="textDisabled">
                {data.message}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="caption"
            color="textDisabled"
            sx={{
              textAlign: "right",
              display: "block",
              pb: 1.5,
            }}
          >
            {data.time}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default Chat;
