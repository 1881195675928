import {
  Avatar,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import notFound from "assets/images/search-result-not-found.png";
import { Button, ButtonOutline } from "components/Buttons";
import plus from "assets/icons/add-white.svg";
import filter from "assets/icons/filters-lines.svg";
import download from "assets/icons/download.svg";
import Searchbar from "components/Searchbar";
import AddUserModal from "./AddUserModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getAccountsList } from "store/userManagementSlice";

// type UserType = {
//   id: number;
//   name: string;
//   role: string;
//   mobile: number;
//   email: string;
// };

// const users: UserType[] = [
//   {
//     id: 1,
//     name: "Jane Cooper",
//     role: "Manager",
//     mobile: 86680100987,
//     email: "Janecooper@bata.com",
//   },
//   {
//     id: 2,
//     name: "Jane Cooper",
//     role: "Manager",
//     mobile: 86680100987,
//     email: "Janecooper@bata.com",
//   },
// ];

const UserManagement = () => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const { accountsList, loading, error } = useSelector(
    (state: RootState) => state.userManagement
  );
  const dispatch = useDispatch<AppDispatch>();
  console.log(loading, error);

  useEffect(() => {
    dispatch(getAccountsList());
  }, []);
  return (
    <Box
      width="100%"
      height="100%"
      sx={{ boxSizing: "border-box", textAlign: "left", p: 3 }}
    >
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        <Typography variant="h3" sx={{ whiteSpace: "nowrap" }}>
          User Management
        </Typography>
        <Button
          variant="contained"
          sx={{ bgcolor: "#1650CF", border: "none", whiteSpace: "nowrap" }}
          startIcon={<img src={plus} alt="plus" />}
          onClick={() => setOpenAddUser(true)}
        >
          Add User
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          borderRadius: "8px",
          border: `1px solid ${theme.palette.grey["300"]}`,
          p: 3,
          minHeight: "75%",
        })}
      >
        <Box
          gap={2}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Searchbar sx={{ ml: 0 }} />
          <Box
            gap={2}
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Button
              sx={{ minWidth: 120 }}
              endIcon={<img src={filter} alt="filter" />}
            >
              Filter
            </Button>
            <ButtonOutline startIcon={<img src={download} alt="download" />}>
              Download
            </ButtonOutline>
          </Box>
        </Box>
        {error ? (
          <Box sx={{ ...flexRowCenterCenter, textAlign: "center", p: 14 }}>
            <Box sx={{ maxWidth: 570 }}>
              <img src={notFound} alt="not-found" />
              <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
                {error}
              </Typography>
            </Box>
          </Box>
        ) : accountsList?.length === 0 ? (
          <Box sx={{ ...flexRowCenterCenter, textAlign: "center", p: 14 }}>
            <Box sx={{ maxWidth: 570 }}>
              <img src={notFound} alt="not-found" />
              <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
                No Matching data found
              </Typography>
              <Typography variant="body2" color="textDisabled" sx={{ pb: 3 }}>
                Please search again
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            gap={3}
            sx={{ ...flexRowCenterCenter, justifyContent: "flex-start", mt: 3 }}
          >
            <TableContainer
              sx={(theme) => ({
                "& .MuiTableCell-root": {
                  color: theme.palette.primary.main,
                },
              })}
            >
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#F6F6F6",
                    }}
                  >
                    <TableCell color="primary">Name</TableCell>
                    <TableCell color="primary">Role</TableCell>
                    <TableCell color="primary">Mobile</TableCell>
                    <TableCell color="primary">Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountsList?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box
                          sx={{
                            ...flexRowCenterCenter,
                            justifyContent: "flex-start",
                          }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#EBF1FD", color: "#105DFB", mr: 1 }}
                          >
                            {row.accountName
                              .split(" ")
                              .map((word: any) => word[0])
                              .join("")}
                          </Avatar>
                          {row.accountName}
                        </Box>
                      </TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>{row.mobile}</TableCell>
                      <TableCell>{row.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <Modal open={openAddUser} onClose={() => setOpenAddUser(false)}>
        <AddUserModal onClose={() => setOpenAddUser(false)} />
      </Modal>
    </Box>
  );
};

export default UserManagement;
