export const flexRowCenterCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const flexColumnCenterCenter = {
  ...flexRowCenterCenter,
  flexDirection: "column",
};

export const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 750,
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  maxHeight: "90%",
};
