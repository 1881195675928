import {  Select as SelectComponent, FormControl, OutlinedInput, SelectProps, TextFieldProps } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import TextField from 'components/TextField';

type DropDownProps = {
  label?: string,
  labelVisible?:boolean,
  width?: string | number
} & SelectProps;

export const DropDown:React.FC<DropDownProps> = ({ label, labelVisible, width=200, children, ...props }) => {
  const formControlRef = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState<number>(220);

  useEffect(() => {
    if (formControlRef.current) {
      setMenuWidth(formControlRef.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl ref={formControlRef} variant="outlined" sx={{ width, minWidth:width, maxWidth:width }}>
          {labelVisible && (
        <label style={{ position: 'absolute', top: '10px', left: '14px', pointerEvents: 'none', fontSize: '14px', maxWidth: '60%', whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'ellipsis' }}>
          {label}
        </label>
      )}
            <SelectComponent
              {...props}
              labelId="select-label"
              size='small'
              autoWidth
              IconComponent={DownIcon}
              SelectDisplayProps={{style:{
                fontWeight:400,
                fontSize:'14px'
              }}}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    width: menuWidth,
                    boxSizing:'border-box'
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              input={
                <OutlinedInput
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 14px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c4c4c4',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c4c4c4',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c4c4c4',
                      borderWidth: '1px'
                    },
                    '& .MuiSelect-icon':{
                      top:'inherit',
                      width:20
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: '0',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 400,
                    },
                  }}
                />
              }
            >
               {children}
            </SelectComponent>
          </FormControl>
  )
}

const Select = ({slotProps,sx,children,...props}:TextFieldProps) =>{
  return (
    <TextField
    id="select"
    size="small"
    select
    slotProps={{
      select: {
        IconComponent: (props) => (
          <DownIcon {...props} style={{ width: 20, fontWeight: 100 }} />
        ),
      },
      ...slotProps
    }}
    variant="outlined"
    sx={{
      my: 1,
      minWidth: "100px",
      ml: 1,
      "& .MuiSelect-icon": {
        top: "inherit",
      },
    ...sx}}
    {...props}
  >
    {children}
  </TextField>)
}

export default Select