import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import onlineShoeShopping from "assets/images/online-shoe-shopping.png";
import { Button } from "components/Buttons";
import plus from "assets/icons/add-white.svg";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
const services: any = [];

const Services: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "4" : "0",
  });
  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "4" });
  }, [isEdit]);
  if (services.length === 0)
    return (
      <Box width="100%" height="100%" sx={{ boxSizing: "border-box" }}>
        <Box
          sx={(theme) => ({
            borderRadius: "8px",
            border: `1px solid ${theme.palette.grey["300"]}`,
            ...flexRowCenterCenter,
            textAlign: "center",
            p: 14,
          })}
        >
          <Box sx={{ maxWidth: 570 }}>
            <img src={onlineShoeShopping} alt="online-shoe-shopping" />
            <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
              Services
            </Typography>
            <Typography variant="body2" color="textDisabled" sx={{ pb: 3 }}>
              Google Services encompass a wide array of online tools and
              platforms developed by Google to enhance productivity,
              communication, and connectivity
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#1650CF", border: "none" }}
              startIcon={<img src={plus} alt="plus" sizes="20px" />}
              onClick={() => setAction({ action: "4", open: true })}
            >
              Services
            </Button>
          </Box>
        </Box>
        <Modal
          open={action.open || (isEdit && action.open ? true : false)}
          onClose={() => {
            setAction({ action: "0", open: false });
            if (isEdit && onClose) onClose();
          }}
        >
          <EditBusinessListing
            setActionOrOpen={({ action = "0", open = true }) => {
              setAction({ action, open });
              if (onClose) onClose();
            }}
            action={action.action}
          />
        </Modal>
      </Box>
    );
  return <Box width="100%"></Box>;
};

export default Services;
