import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface forgotOrResetPasswordState {
  resetUrl: string | null;
  forgotPasswordLoading: boolean;
  resetPasswordLoading: boolean;
  success: string | null;
  error: string | null;
}

const initialState: forgotOrResetPasswordState = {
  resetUrl: null,
  forgotPasswordLoading: false,
  resetPasswordLoading: false,
  success: null,
  error: null,
};

export const forgotPassword = createAsyncThunk(
  "/forgot-password",
  async (email: { email: string }, thunkAPI) => {
    try {
      const response = await apiManager.post("/forgot-password", email);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Invalid email"
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/reset-password",
  async (
    reset: { email: string; refresh_token: string; newPassword: string },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/reset-password", reset);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Invalid token or expired token"
      );
    }
  }
);

const forgotOrResetPasswordSlice = createSlice({
  name: "forgotOrResetPassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.resetUrl = action.payload.data;
        state.success = action.payload.message;
        state.error = null;
        state.forgotPasswordLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.success = null;
        state.error = action.payload as string;
        state.forgotPasswordLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordLoading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.success = action.payload.message;
        state.error = null;
        state.resetPasswordLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.success = null;
        state.error = action.payload as string;
        state.resetPasswordLoading = false;
      });
  },
});

export default forgotOrResetPasswordSlice.reducer;
