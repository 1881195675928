import styled from '@emotion/styled';
import { Avatar, Box } from '@mui/material';
import React from 'react'

const ProfileImageContainerComponent = styled(Box)({
    position: "relative",
    width: 40,
    height: 40,
    display: "inline-block",
  });
  
  const IconOverlay = styled(Box)({
    position: "absolute",
    bottom: -10,
    right: -5,
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
  });

type ProfileImageContainerProps = {
    pic?:string,
    icon?:string
}

const ProfileImageContainer:React.FC<ProfileImageContainerProps> = ({pic, icon}) => {
  return (
    <ProfileImageContainerComponent>
    <Avatar
      src={pic}
      alt="pic"
      sx={{ width: 40, height: 40 }}
    />
    <IconOverlay>
      <img
        src={icon}
        style={{ width: "24px", height: "24px" }}
      />
    </IconOverlay>
  </ProfileImageContainerComponent>
  )
}

export default ProfileImageContainer