import { Button as ButtonComponent, ButtonProps } from "@mui/material";
import React from "react";

export const Button: React.FC<ButtonProps> = ({
  children,
  sx = {},
  ...rest
}) => {
  return (
    <ButtonComponent
      color="primary"
      sx={{
        textTransform: "capitalize",
        borderRadius: "8px",
        border: `1px solid #c4c4c4`,
        fontWeight: 500,
        minWidth: "min-content",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};

export const ButtonOutline: React.FC<ButtonProps> = ({
  children,
  sx={},
  ...rest
}) => {
  return (
    <ButtonComponent
      variant="outlined"
      sx={{
        textTransform: "capitalize",
        color: "#1650CF",
        borderColor: "#1650CF",
        borderRadius: "8px",
        fontWeight: 600,
        minWidth: "min-content",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};
