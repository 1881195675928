// import { Box } from "@mui/material";
import React, { useState } from "react";

import Navbar from "components/Navbar";
import { MainLayoutProps } from "interfaces/Layout.types";

const MainLayout = (props: MainLayoutProps) => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };
  return (
    <Navbar
      openDrawer={openDrawer}
      toggleDrawer={toggleDrawer}
      content={props?.content}
    />
  );
};

export default MainLayout;
