import { Box, Grid2, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { flexColumnCenterCenter } from "utils/styles";
import macBookPro from "assets/images/macBookPro.png";
import { theme } from "utils/theme";

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return <Box
    sx={{
      flexGrow: 1,
      minHeight: "100vh",
    }}
  >
    <Grid2 container>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          ...flexColumnCenterCenter,
          backgroundColor: "primary.main",
          p: "40px",
          minHeight: !matches ? "100vh" : "min-content",
        }}
      >
        <img
          src={macBookPro}
          alt="mac"
          style={{
            borderRadius: "8px",
            maxWidth: matches ? "50%" : "100%",
          }}
        />
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            mb: "10px",
            mt: "20px",
          }}
        >
          Connect with every application
        </Typography>
        <Typography variant="h5" color="secondary">
          Everything you need in an easily customizable dashboard.
        </Typography>
      </Grid2>
      {children}
    </Grid2>
  </Box>
};

export default AuthLayout;
