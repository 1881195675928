import {
  Box,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  RadioGroup,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import plus from "assets/icons/add-black.svg";
import plusBlue from "assets/icons/plus-blue.svg";
import info from "assets/icons/info.svg";
import bin from "assets/icons/bin.svg";
import Checkbox from "components/Checkbox";
import Radio from "components/Radio";
import Select from "components/Select";
import {
  GoogleMap,
  Libraries,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { ButtonOutline } from "components/Buttons";
import { StyledTextField as CustomTextField } from "../Bulkactions/Profile";
import { ReactComponent as DownIcon } from "assets/icons/down.svg";
import Toggle from "components/Toggle";
import { flexRowCenterCenter } from "utils/styles";

const hoursOptions = [
  { id: 1, time: "24 hours" },
  { id: 2, time: "00:00" },
  { id: 3, time: "00:30" },
  { id: 4, time: "01:00" },
  { id: 5, time: "01:30" },
  { id: 6, time: "02:00" },
  { id: 7, time: "02:30" },
  { id: 8, time: "03:00" },
  { id: 9, time: "03:30" },
  { id: 10, time: "04:00" },
  { id: 11, time: "04:30" },
  { id: 12, time: "05:00" },
  { id: 13, time: "05:30" },
  { id: 14, time: "06:00" },
  { id: 15, time: "06:30" },
  { id: 16, time: "07:00" },
  { id: 17, time: "07:30" },
  { id: 19, time: "08:00" },
  { id: 19, time: "08:30" },
  { id: 20, time: "09:00" },
  { id: 21, time: "09:30" },
  { id: 22, time: "10:00" },
  { id: 23, time: "10:30" },
  { id: 24, time: "11:00" },
  { id: 25, time: "11:30" },
  { id: 26, time: "12:00" },
  { id: 27, time: "12:30" },
  { id: 28, time: "13:00" },
  { id: 29, time: "13:30" },
  { id: 30, time: "14:00" },
  { id: 31, time: "14:30" },
  { id: 32, time: "15:00" },
  { id: 33, time: "15:30" },
  { id: 34, time: "16:00" },
  { id: 35, time: "16:30" },
  { id: 36, time: "17:00" },
  { id: 37, time: "17:30" },
  { id: 38, time: "18:00" },
  { id: 39, time: "18:30" },
  { id: 40, time: "19:00" },
  { id: 41, time: "19:30" },
  { id: 42, time: "20:00" },
  { id: 43, time: "20:30" },
  { id: 44, time: "21:00" },
  { id: 45, time: "21:30" },
  { id: 46, time: "22:00" },
  { id: 47, time: "22:30" },
  { id: 48, time: "23:00" },
  { id: 49, time: "23:30" },
  { id: 50, time: "24 hours" },
];

const initialWorkingHoursData = [
  {
    week: "Monday",
    isClosed: false,
    times: [
      {
        open: "00:00",
        close: "00:00",
        is24Hours: false,
      },
      {
        open: "00:00",
        close: "00:00",
        is24Hours: false,
      },
    ],
  },
  {
    week: "Tuesday",
    isClosed: false,
    times: [
      {
        open: "00:00",
        close: "17:00",
        is24Hours: false,
      },
    ],
  },
  {
    week: "Wednesday",
    isClosed: false,
    times: [
      {
        open: "00:00",
        close: "17:00",
        is24Hours: false,
      },
    ],
  },
  {
    week: "Thursday",
    isClosed: false,
    times: [
      {
        open: "00:00",
        close: "17:00",
        is24Hours: false,
      },
    ],
  },
  {
    week: "Friday",
    isClosed: false,
    times: [
      {
        open: "00:00",
        close: "17:00",
        is24Hours: false,
      },
    ],
  },
  {
    week: "Saturday",
    isClosed: true,
    times: [
      {
        open: "00:00",
        close: "17:00",
        is24Hours: false,
      },
    ],
  },
  {
    week: "Sunday",
    isClosed: true,
    times: [
      {
        open: "00:00",
        close: "17:00",
        is24Hours: false,
      },
    ],
  },
];

export const WorkingHours = () => {
  const [selectedHourType, setSelectedHourType] = useState("1");
  const [workingHoursData, setWorkingHoursData] = useState(
    initialWorkingHoursData
  );

  const deleteTime = (i: number, j: number) => {
    const newWorkinHoursData = workingHoursData.map((hour, ind) => {
      if (ind === i)
        return {
          ...hour,
          times: hour.times.filter((time, index) => index !== j),
        };
      return hour;
    });
    setWorkingHoursData(newWorkinHoursData);
  };

  const addTime = (i: number) => {
    const newWorkinHoursData = workingHoursData.map((hour, ind) => {
      if (ind === i)
        return {
          ...hour,
          times: [
            ...hour.times,
            {
              open: "00:00",
              close: "00:00",
              is24Hours: false,
            },
          ],
        };
      return hour;
    });
    setWorkingHoursData(newWorkinHoursData);
  };

  const toggleClose = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newWorkingHoursData = workingHoursData.map((hour, ind) => {
      if (ind === i)
        return {
          ...hour,
          isClosed: e.target.checked,
        };
      return hour;
    });
    setWorkingHoursData(newWorkingHoursData);
  };

  const onChangeTime = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number,
    j: number,
    type: "open" | "close"
  ) => {
    const newWorkinHoursData = workingHoursData.map((hour, ind) => {
      if (ind === i)
        return {
          ...hour,
          times: hour.times.map((time, index) => {
            if (index === j) {
              if (e.target.value === "24 hours") {
                return { ...time, is24Hours: true, open: e.target.value };
              }
              return { ...time, [type]: e.target.value, is24Hours: false };
            }
            return time;
          }),
        };
      return hour;
    });
    setWorkingHoursData(newWorkinHoursData);
  };

  return (
    <Box>
      <Typography variant="body2" sx={{ py: 1, fontWeight: 500 }}>
        Hours
      </Typography>
      <Typography variant="body2" sx={{ pb: 2 }}>
        Set main business hours or mark your business as closed.
      </Typography>
      <RadioGroup
        name="hours-group"
        value={selectedHourType}
        onChange={(event) => {
          setSelectedHourType(event.target.value);
        }}
      >
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="1" id="1" />
          <Box>
            <Typography
              component="label"
              htmlFor="1"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Open with main hours
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="1"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Show when your business is open
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="2" id="2" />
          <Box>
            <Typography
              component="label"
              htmlFor="2"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Open with no main hours
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="2"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Don’t show any business hours
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="3" id="3" />
          <Box>
            <Typography
              component="label"
              htmlFor="3"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Temporarily closed
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="3"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Show that your business will open again in the future
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Radio value="4" id="4" />
          <Box>
            <Typography
              component="label"
              htmlFor="4"
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              Permanently closed
            </Typography>
            <br />
            <Typography
              component="label"
              htmlFor="4"
              variant="caption"
              sx={{ cursor: "pointer" }}
            >
              Show that your business no longer exists
            </Typography>
          </Box>
        </Box>
      </RadioGroup>
      {selectedHourType === "1" && (
        <TableContainer sx={{ pt: 2, maxWidth: 490 }}>
          <Table sx={{ border: "none" }}>
            {workingHoursData.map((item, i) => (
              <React.Fragment key={i}>
                {item.times.map((time, j) => (
                  <TableRow key={j}>
                    <TableCell
                      sx={{ borderBottom: "none", p: 0.1, width: 180 }}
                    >
                      {j === 0 ? (
                        <Box>
                          <Typography variant="body2">{item.week}</Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => toggleClose(e, i)}
                                checked={item.isClosed}
                              />
                            }
                            label="Closed"
                            sx={{
                              "& .MuiTypography-root": {
                                fontWeight: 400,
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                    </TableCell>
                    {!item.isClosed && (
                      <>
                        <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                          <Select
                            label="Opens at"
                            value={time.open}
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                                style: { color: "#424242" },
                              },
                            }}
                            sx={{ minWidth: 120 }}
                            onChange={(e) => {
                              onChangeTime(e, i, j, "open");
                            }}
                          >
                            {hoursOptions.map((hour) => (
                              <MenuItem
                                key={hour.id}
                                value={hour.time}
                                sx={{ fontWeight: 400 }}
                              >
                                {hour.time}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                          {!time.is24Hours && (
                            <Select
                              label="Closes at"
                              value={time.close}
                              slotProps={{
                                inputLabel: {
                                  shrink: true,
                                  style: { color: "#424242" },
                                },
                              }}
                              sx={{ minWidth: 120 }}
                              onChange={(e) => {
                                onChangeTime(e, i, j, "close");
                              }}
                            >
                              {hoursOptions.map((hour) => (
                                <MenuItem
                                  key={hour.id}
                                  value={hour.time}
                                  sx={{ fontWeight: 400 }}
                                >
                                  {hour.time}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none", p: 0.1 }}>
                          {j === 0 ? (
                            !time.is24Hours ? (
                              <IconButton onClick={() => addTime(i)}>
                                <img
                                  src={plus}
                                  alt="plus"
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            ) : null
                          ) : (
                            <IconButton onClick={() => deleteTime(i, j)}>
                              <img src={bin} alt="bin" width={24} height={24} />
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export const Map = () => {
  const [showAddress, setShowAddress] = useState(true);
  const [addAddressLine, setAddAddressLine] = useState(false);
  const [placeDetails, setPlaceDetails] = useState({
    country: "India",
    streetAddress1: "",
    streetAddress2: "",
    streetAddress3: "",
    city: "",
    pincode: "",
    state: "",
  });

  console.log({ setShowAddress, setPlaceDetails });
  const libraries: Libraries = ["places"];
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Typography variant="body2" color="textDisabled">
        If customers visit your business, add an address and adjust the pin on
        the map to its location.
      </Typography>
      <FormControlLabel
        control={<Toggle onChange={(e) => setShowAddress(e.target.checked)} />}
        label="Show business address to customers"
        checked={showAddress}
        sx={{
          "& .MuiTypography-root": { fontWeight: 400, fontSize: "14px", ml: 1 },
          m: 2,
          ml: 0,
        }}
      />
      <Box gap={2} sx={{ display: "flex" }}>
        <Box width="100%">
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            Country/Region
          </Typography>
          <Box sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}>
            <CustomTextField
              variant="filled"
              sx={{
                mb: 1,
                "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
              }}
              value={placeDetails.country}
              disabled
              fullWidth
            />
            <IconButton id="info" onClick={handleClick}>
              <img src={info} alt="info" />
            </IconButton>
            <Menu
              id="info-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "info",
              }}
            >
              <Box sx={{ p: 1, maxWidth: 300 }}>
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  This can't be edited
                </Typography>
                <br />
                <Typography variant="caption">
                  To change your country / region, you'll have to create a new
                  Business Profile on Google.
                </Typography>
              </Box>
            </Menu>
          </Box>

          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            Street Address
          </Typography>
          <CustomTextField
            variant="filled"
            sx={{
              mb: 1,
              "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
            }}
            value={placeDetails.streetAddress1}
            fullWidth
            onChange={(e) =>
              setPlaceDetails({
                ...placeDetails,
                streetAddress1: e.target.value,
              })
            }
          />
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            Street Address 2 (optional)
          </Typography>
          <CustomTextField
            variant="filled"
            sx={{
              mb: 1,
              "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
            }}
            value={placeDetails.streetAddress2}
            fullWidth
          />
          {addAddressLine ? (
            <>
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                Street Address 3 (optional)
              </Typography>
              <CustomTextField
                variant="filled"
                sx={{
                  mb: 1,
                  "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
                }}
                value={placeDetails.streetAddress3}
                fullWidth
              />
            </>
          ) : (
            <>
              <ButtonOutline
                sx={{ border: "none" }}
                startIcon={<img src={plusBlue} alt="plus" />}
                onClick={() => setAddAddressLine(true)}
              >
                Add address line (optional)
              </ButtonOutline>
              <br />
            </>
          )}
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            TownCity
          </Typography>
          <CustomTextField
            variant="filled"
            sx={{
              mb: 1,
              "& .MuiInputBase-root": { height: 40, borderRadius: 0.5 },
            }}
            value={placeDetails.city}
            fullWidth
          />
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            State
          </Typography>
          <CustomTextField
            select
            fullWidth
            value={placeDetails.state}
            slotProps={{
              select: {
                IconComponent: (props) => (
                  <DownIcon {...props} style={{ width: 20, fontWeight: 100 }} />
                ),
              },
            }}
            variant="filled"
            size="small"
            sx={{
              ml: 0,
              mb: 0,
              backgroundColor: "#f8f1f182",
              border: "none",
              height: 40,
            }}
          >
            <MenuItem sx={{ fontWeight: 400, fontSize: "14px" }} value={1}>
              Andhra Pradesh
            </MenuItem>
          </CustomTextField>
        </Box>
        <Box width="100%">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: 250 }}
              center={{ lat: 37.7749, lng: -122.4194 }}
              zoom={10}
            >
              <Marker position={{ lat: 37.7749, lng: -122.4194 }} />
            </GoogleMap>
          )}
        </Box>
      </Box>
    </Box>
  );
};
