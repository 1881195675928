import { Box, Grid2, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import videoAdvertising from "assets/images/video-advertising.png";
import { Button } from "components/Buttons";
import plus from "assets/icons/add-white.svg";
import eye from "assets/icons/filled-eye.svg";
import play from "assets/icons/video-button.svg";
import video from "assets/icons/video-camera.svg";
import google from "assets/icons/google.svg";
import { format } from "date-fns";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Post: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "2" : "0",
  });
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "2" });
  }, [isEdit]);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [imageLoadedStates, setImageLoadedStates] = useState<{
    [key: number]: boolean;
  }>({});

  const handleImageLoad = (index: number) => {
    setImageLoadedStates((prevState) => ({ ...prevState, [index]: true }));
  };

  const handlePlay = (index: number) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        return;
      }
      videoElement.pause();
    }
  };

  return (
    <>
      {listing?.photos?.length === 0 ? (
        <Box width="100%" height="100%" sx={{ boxSizing: "border-box" }}>
          <Box
            sx={(theme) => ({
              borderRadius: "8px",
              border: `1px solid ${theme.palette.grey["300"]}`,
              ...flexRowCenterCenter,
              textAlign: "center",
              p: 14,
            })}
          >
            <Box sx={{ maxWidth: 570 }}>
              <img src={videoAdvertising} alt="video-advertising" />
              <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
                Add new Photo/Video
              </Typography>
              <Typography variant="body2" color="textDisabled" sx={{ pb: 3 }}>
                Upload a post and seamlessly update it across all shops
                simultaneously. Streamline your content management with
                synchronized, multi-shop updates.
              </Typography>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#1650CF", border: "none" }}
                startIcon={<img src={plus} alt="plus" sizes="20px" />}
                onClick={() => setAction({ action: "4", open: true })}
              >
                Photo/Video
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box width="100%">
          <Typography variant="h1" sx={{ pb: 2 }}>
            {listing?.photos?.length} &nbsp;Posts
          </Typography>
          <Grid2 container>
            {listing?.photos?.map((post: any, index: number) => (
              <Grid2
                size={{ xs: 12, md: 6, lg: 4 }}
                key={index}
                sx={{ pr: 3, pb: 3 }}
              >
                {post.mediaFormat === "VIDEO" ? (
                  <Box
                    sx={{
                      borderRadius: "8px",
                      width: "100%",
                      height: 286,
                      maxHeight: 286,
                      overflow: "hidden",
                    }}
                  >
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      style={{
                        borderRadius: "8px",
                        width: "100%",
                        height: 286,
                        maxHeight: 286,
                      }}
                    >
                      <source src={post?.sourceUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Button
                      sx={{
                        position: "relative",
                        bottom: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlay(index)}
                    >
                      <img src={play} alt="play-button" />
                    </Button>
                  </Box>
                ) : (
                  <>
                    <img
                      src={post?.googleUrl}
                      alt="post-image"
                      style={{
                        borderRadius: "8px",
                        width: "100%",
                        height: 286,
                        display: imageLoadedStates[index] ? "block" : "none",
                      }}
                      onLoad={() => handleImageLoad(index)}
                    />
                    {!imageLoadedStates[index] && (
                      <Box
                        sx={(theme) => ({
                          ...flexRowCenterCenter,
                          height: 286,
                          width: "100%",
                          mb: 2,
                          backgroundColor: theme.palette.grey["200"],
                        })}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            width: "100%",
                            borderRadius: "8px",
                          }}
                        >
                          Image Failed to Load
                        </Typography>
                      </Box>
                    )}
                  </>
                )}

                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    pt: 2,
                  }}
                >
                  <Box sx={{ ...flexRowCenterCenter }}>
                    <img src={eye} alt="eye" sizes="24px" />
                    <Typography
                      variant="caption"
                      color="textDisabled"
                      sx={{ pl: 1, pr: 3 }}
                    >
                      --
                    </Typography>
                    <img src={video} alt="video" sizes="20px" />
                    <Typography
                      variant="caption"
                      color="textDisabled"
                      sx={{ pl: 1 }}
                    >
                      --
                    </Typography>
                  </Box>
                  <img src={google} alt="logo" sizes="32px" />
                </Box>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                    pt: 1,
                  }}
                >
                  <Typography variant="caption" color="textDisabled">
                    Date: {format(post.created_at, "dd/MM/yyyy")}
                  </Typography>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      )}
      <Modal
        open={action.open || (isEdit && action.open ? true : false)}
        onClose={() => {
          setAction({ action: "0", open: false });
          if (isEdit && onClose) onClose();
        }}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) => {
            setAction({ action, open });
            if (onClose) onClose();
          }}
          action={action.action}
        />
      </Modal>
    </>
  );
};

export default Post;
