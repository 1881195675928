import { Radio as RadioComponent, RadioProps, SxProps } from '@mui/material'

const Radio = (props: RadioProps, sxProps: SxProps) => {
    return (<RadioComponent sx={{
        color: 'grey',
        '&.Mui-checked': {
            color: '#1442A7',
        }, ...sxProps
    }} {...props}  />)
}

export default Radio