import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface SignupUserState {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    mobile_number: string;
    password: string;
  } | null;
  isSignedUp: boolean;
  loading: boolean;
  error: string | null;
  isMobileNumberChanged: boolean;
  changeMobileNumberLoading: boolean;
}

const initialState: SignupUserState = {
  user: null,
  isSignedUp: false,
  loading: false,
  error: null,
  isMobileNumberChanged: false,
  changeMobileNumberLoading: false,
};

export const signup = createAsyncThunk(
  "/signup",
  async (
    data: {
      userData: {
        first_name: string;
        last_name: string;
        email: string;
        mobile_number: string;
        password: string;
      };
      navigate: any;
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/sign-up", data.userData);
      // await thunkAPI.dispatch(generateOTP({ email: userData.email }));
      data.navigate("/login");
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Signup failed"
      );
    }
  }
);

export const changeMobileNumber = createAsyncThunk(
  "/change-mobile-number",
  async (credentials: { email: string; mobile_number: string }, thunkAPI) => {
    try {
      const response = await apiManager.post(
        "/change-mobile-number",
        credentials
      );
      return { ...response.data, mobile_number: credentials.mobile_number };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to change mobile number"
      );
    }
  }
);

const signupUserSlice = createSlice({
  name: "signupUser",
  initialState,
  reducers: {
    resetUserState: () => initialState,
    addSignupUser: (state, action) => {
      const { first_name, last_name, email, mobile_number, password } =
        action.payload;
      state.user = { first_name, last_name, email, mobile_number, password };
    },
  },
  extraReducers: (builder) => {
    builder
      //signup
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, () => initialState)
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      //change mobile number
      .addCase(changeMobileNumber.pending, (state) => {
        state.changeMobileNumberLoading = true;
        state.isMobileNumberChanged = false;
        state.error = null;
      })
      .addCase(changeMobileNumber.fulfilled, (state, action) => {
        const { mobile_number } = action.payload;
        if (state.user) {
          state.user.mobile_number = mobile_number;
        }
        state.changeMobileNumberLoading = false;
        state.isMobileNumberChanged = true;
      })
      .addCase(changeMobileNumber.rejected, (state, action) => {
        state.changeMobileNumberLoading = false;
        state.isMobileNumberChanged = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetUserState, addSignupUser } = signupUserSlice.actions;

export default signupUserSlice.reducer;
