import {
  Box,
  Divider,
  Grid2,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  BorderLinearProgress,
  RatingsComponent,
} from "pages/Insights/Components";
import { flexRowCenterCenter } from "utils/styles";
import positive from "assets/icons/positive.svg";
import negative from "assets/icons/negative.svg";
import neutral from "assets/icons/neutral.svg";
import google from "assets/icons/google.svg";
import johnDoe from "assets/images/johnDoe.png";
import blackDanny from "assets/images/blackDanny.png";
import andrewJames from "assets/images/andrewJames.png";
import estherHoward from "assets/images/estherHoward.png";
import ProfileImageContainer from "components/ProfileImageContainer";

const sentimentsData = [
  { title: "Overall", type: "positive", value: 75 },
  { title: "Cleanliness", type: "positive", value: 75 },
  { title: "Overall", type: "negative", value: 75 },
  { title: "Service", type: "neutral", value: 75 },
  { title: "Staff", type: "positive", value: 75 },
];
const tableData = [
  {
    business: "Nike Store Ambattur",
    rating: 5,
    reviews: 12,
    replied: 19,
    notReplied: 0,
  },
  {
    business: "Nike Store VR Mall",
    rating: 5,
    reviews: 12,
    replied: 12,
    notReplied: 1,
  },
  {
    business: "Nike Store Annanagar",
    rating: 5,
    reviews: 12,
    replied: 16,
    notReplied: 2,
  },
  {
    business: "Nike Store Alwarpet",
    rating: 5,
    reviews: 12,
    replied: 2,
    notReplied: 1,
  },
  {
    business: "Nike Store T Nagar",
    rating: 5,
    reviews: 12,
    replied: 14,
    notReplied: 0,
  },
];
const trendsData = [
  { name: "Jan", value: 5.4 },
  { name: "Feb", value: 3 },
  { name: "Mar", value: 4.9 },
  { name: "Apr", value: 3 },
  { name: "May", value: 4 },
  { name: "Jun", value: 3.5 },
  { name: "Jul", value: 2 },
  { name: "Aug", value: 3.5 },
];
const Reviews = () => {
  return (
    <Box width="100%" sx={{ textAlign: "left" }}>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, lg: 4 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
            }}
          >
            <RatingsComponent showDivider={false} />
            <Divider sx={{ my: 3 }} />
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                fontWeight: 500,
              }}
            >
              <RepliedCard
                title="Reviews replied"
                value={2000}
                color="#FF5A1F"
              />
              <RepliedCard
                title="Reviews not replied"
                value={300}
                color="#A1A0A3"
              />
            </Box>
          </Box>
        </Grid2>
        <Grid2
          size={{ xs: 12, lg: 8 }}
          sx={{
            borderRadius: "8px",
            border: "1px solid #DBDBDB",
            p: "32px 24px",
            flexGrow: 1,
          }}>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Review Sentiments</Typography>
            <Typography variant="body2">View all</Typography>
          </Box>
          <Box
            gap={2}
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-start",
              py: 3,
            }}
          >
            <ColorCircle title="Positive" value={200} sentiment="positive" />
            <ColorCircle title="Neutral" value={23} sentiment="neutral" />
            <ColorCircle title="Negative" value={110} sentiment="negative" />
          </Box>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <SentimentCard />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 4 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
            }}
          >
            <ReviewsCard />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 8 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
            }}
          >
            <ReviewsTable
              title="Top Branches by reviews"
              tableData={tableData}
            />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 4 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
            }}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Review Trends
            </Typography>
            <Typography variant="h2">4.5</Typography>
            <Typography variant="body2" sx={{ pb: 2.5 }}>
              Overall
            </Typography>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={trendsData}
                margin={{ top: 30, right: 0, left: -15, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="10 5" vertical={false} />
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                <YAxis domain={[0, 4]} axisLine={false} tickLine={false} />
                <Tooltip />
                <Bar
                  dataKey="value"
                  fill="#8fa3fa"
                  barSize={16}
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 8 }}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
            }}
          >
            <ReviewsTable
              title="Bottom Branches by reviews"
              tableData={tableData}
            />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Reviews;

const RepliedCard: React.FC<{
  title: string;
  value: number;
  color: string;
}> = ({ title, value, color }) => (
  <Box>
    <Box
      sx={{
        ...flexRowCenterCenter,
      }}
    >
      <div
        style={{
          width: "16px",
          height: "16px",
          backgroundColor: color,
        }}
      />
      <Typography
        variant="body2"
        color="textDisabled"
        sx={{ pb: "3px", pl: 1 }}
      >
        {title}
      </Typography>
    </Box>
    <Typography variant="h6" sx={{ display: "inline", pl: 3 }}>
      {value}
    </Typography>
    <Typography
      variant="body2"
      color="textDisabled"
      sx={{ display: "inline", ml: 1 }}
    >
      Shops
    </Typography>
  </Box>
);

const ColorCircle: React.FC<{
  title: string;
  value: number;
  sentiment: string;
}> = ({ title, value, sentiment }) => (
  <Box>
    <Box
      sx={{
        ...flexRowCenterCenter,
      }}
    >
      <div
        style={{
          width: "13px",
          height: "13px",
          backgroundColor:
            sentiment === "positive"
              ? "#1CB697"
              : sentiment === "negative"
                ? "#FA7F65"
                : "#EEAD65",
          borderRadius: "100%",
        }}
      />
      <Typography variant="body2" color="textDisabled" sx={{ pl: 1 }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="h6" sx={{ pl: "21px" }}>
      {value}
    </Typography>
  </Box>
);

const SentimentCard: React.FC = () => (
  <>
    {sentimentsData.map((data, index) => {
      const typeDetails =
        data.type.toLowerCase() === "positive"
          ? { color: "#1CB697", icon: positive }
          : data.type.toLowerCase() === "negative"
            ? { color: "#FA7F65", icon: negative }
            : { color: "#EEAD65", icon: neutral };
      return (
        <Box key={index}
          sx={{
            flexGrow: 1,
            px: 2.5,
            py: 1,
            borderRight:
              index === sentimentsData.length - 1
                ? "none"
                : "1px solid #E6E6E6",
          }}
        >
          <Typography variant="body2" color="textDisabled">
            {data.title}
          </Typography>
          <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
            {data.type}
          </Typography>
          <img src={typeDetails.icon} alt="type" />
          <BorderLinearProgress
            variant="determinate"
            bgcolor={typeDetails.color}
            value={data.value}
          />
        </Box>
      );
    })}
  </>
);

const reviewsData = [
  {
    name: "John Doe",
    store: "Nike Store",
    time: "1:20 AM",
    rating: 5,
    profilePic: johnDoe,
    icon: google,
  },
  {
    name: "Black Danny",
    store: "Nike Store",
    time: "2hr ago",
    rating: 4,
    profilePic: blackDanny,
    icon: google,
  },
  {
    name: "Andrew James",
    store: "Nike Store",
    time: "28 May",
    rating: 4,
    profilePic: andrewJames,
    icon: google,
  },
  {
    name: "Esther Howard",
    store: "Nike Store",
    time: "24 May",
    rating: 4,
    profilePic: estherHoward,
    icon: google,
  },
];
export const ReviewsCard: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          pb: 2,
        }}
      >
        <Typography variant="h5">Reviews</Typography>
        <Typography variant="body2">View all</Typography>
      </Box>
      {reviewsData.map((data, index) => (
        <Box key={index}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            flexWrap: "wrap",
            py: 1.5,
            borderBottom:
              index === reviewsData.length - 1 ? "" : "1px solid #DBDBDB",
          }}
        >
          <Box sx={{ ...flexRowCenterCenter }}>
            <ProfileImageContainer pic={data.profilePic} icon={data.icon} />
            <Box ml={2}>
              <Typography variant="body1">{data.name}</Typography>
              <Typography variant="caption" color="textDisabled">
                {data.store}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="caption"
              color="textDisabled"
              sx={{
                textAlign: "right",
                display: "block",
                pb: 1.5,
              }}
            >
              {data.time}
            </Typography>
            <Rating value={data.rating} readOnly precision={0.5} />
          </Box>
        </Box>
      ))}
    </>
  );
};

const ReviewsTable: React.FC<{
  title: string;
  tableData: {
    business: string;
    rating: number;
    reviews: number;
    replied: number;
    notReplied: number;
  }[];
}> = ({ title, tableData }) => {
  return (
    <Box>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          pb: 2.5,
        }}
      >
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2">View all</Typography>
      </Box>
      <TableContainer
        sx={{
          "& .MuiTableCell-root": {
            color: "#222124",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#F6F6F6",
              }}
            >
              <TableCell color="primary">Business</TableCell>
              <TableCell color="primary">Rating</TableCell>
              <TableCell color="primary" sx={{ textAlign: "right" }}>
                Reviews
              </TableCell>
              <TableCell color="primary" sx={{ textAlign: "right" }}>
                Replied
              </TableCell>
              <TableCell
                color="primary"
                sx={{ textAlign: "right", whiteSpace: "nowrap" }}
              >
                Not Replied
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {row.business}
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                    }}
                  >
                    <Rating
                      value={row.rating}
                      precision={0.1}
                      readOnly
                      sx={{ mr: 1 }}
                    />
                    {row.rating.toFixed(1)}
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>{row.reviews}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {row.replied}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {row.notReplied}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
