import axios from "axios";

const apiManager = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  noAuth: false,
});

export const setupApiManager = (getState: any) => {
  apiManager.interceptors.request.use(
    (config) => {
      const state = getState();
      const token = state.user.token;

      if (token) config.headers["Authorization"] = `Bearer ${token}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default apiManager;
