import {
  Box,
  Grid2,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import sort from "assets/icons/sort.svg";
import styled from "@emotion/styled";
import { flexRowCenterCenter } from "utils/styles";
import download from "assets/icons/download.svg";
import {
  BorderLinearProgress,
  CallsComponent,
} from "pages/Insights/Components";
import Searchbar from "components/Searchbar";
import { ButtonOutline } from "components/Buttons";

const CustomTableSortLabel = styled(TableSortLabel)(() => ({
  "&.Mui-active": {
    color: "#222124",
  },
}));

interface CallData {
  location: string;
  connects: number;
  connectPercentage: number;
  notConnected: number;
  missed: number;
  avgTalkTime: string;
}

const callData: CallData[] = [
  {
    location: "Nike Store T Nagar",
    connects: 120,
    connectPercentage: 87,
    notConnected: 12,
    missed: 2,
    avgTalkTime: "2m 50s",
  },
  {
    location: "Nike Store Anna Nagar",
    connects: 20,
    connectPercentage: 98,
    notConnected: 32,
    missed: 3,
    avgTalkTime: "3m 47s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 34,
    connectPercentage: 78,
    notConnected: 22,
    missed: 1,
    avgTalkTime: "4m 00s",
  },
  {
    location: "Nike Store VR Mall",
    connects: 46,
    connectPercentage: 84,
    notConnected: 21,
    missed: 3,
    avgTalkTime: "1m 50s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 89,
    connectPercentage: 91,
    notConnected: 9,
    missed: 9,
    avgTalkTime: "2m 50s",
  },
  {
    location: "Nike Store Anna Nagar",
    connects: 143,
    connectPercentage: 78,
    notConnected: 8,
    missed: 11,
    avgTalkTime: "3m 47s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 90,
    connectPercentage: 91,
    notConnected: 11,
    missed: 7,
    avgTalkTime: "1m 50s",
  },
  {
    location: "Nike Store VR Mall",
    connects: 45,
    connectPercentage: 78,
    notConnected: 14,
    missed: 2,
    avgTalkTime: "4m 00s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 87,
    connectPercentage: 10,
    notConnected: 34,
    missed: 12,
    avgTalkTime: "2m 50s",
  },
  {
    location: "Nike Store Anna Nagar",
    connects: 47,
    connectPercentage: 78,
    notConnected: 22,
    missed: 9,
    avgTalkTime: "4m 00s",
  },
  {
    location: "Nike Store T Nagar",
    connects: 120,
    connectPercentage: 87,
    notConnected: 12,
    missed: 2,
    avgTalkTime: "2m 50s",
  },
  {
    location: "Nike Store Anna Nagar",
    connects: 20,
    connectPercentage: 98,
    notConnected: 32,
    missed: 3,
    avgTalkTime: "3m 47s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 34,
    connectPercentage: 78,
    notConnected: 22,
    missed: 1,
    avgTalkTime: "4m 00s",
  },
  {
    location: "Nike Store VR Mall",
    connects: 46,
    connectPercentage: 84,
    notConnected: 21,
    missed: 3,
    avgTalkTime: "1m 50s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 89,
    connectPercentage: 91,
    notConnected: 9,
    missed: 9,
    avgTalkTime: "2m 50s",
  },
  {
    location: "Nike Store Anna Nagar",
    connects: 143,
    connectPercentage: 78,
    notConnected: 8,
    missed: 11,
    avgTalkTime: "3m 47s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 90,
    connectPercentage: 91,
    notConnected: 11,
    missed: 7,
    avgTalkTime: "1m 50s",
  },
  {
    location: "Nike Store VR Mall",
    connects: 45,
    connectPercentage: 78,
    notConnected: 14,
    missed: 2,
    avgTalkTime: "4m 00s",
  },
  {
    location: "Nike Store Ambattur",
    connects: 87,
    connectPercentage: 10,
    notConnected: 34,
    missed: 12,
    avgTalkTime: "2m 50s",
  },
  {
    location: "Nike Store Anna Nagar",
    connects: 47,
    connectPercentage: 78,
    notConnected: 22,
    missed: 9,
    avgTalkTime: "4m 00s",
  },
];

const Calls = () => {
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof CallData>("connects");
  const [searchText, setSearchText] = useState("");

  const handleRequestSort = (property: keyof CallData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const filteredData = callData.filter((row) =>
    row.location.toLowerCase().includes(searchText.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
    return 0;
  });

  return (
    <Box width="100%" sx={{ textAlign: "left" }}>
      <Grid2 container spacing={3}>
        <Grid2
          size={{ xs: 12, lg: 8 }}
          sx={{
            border: "1px solid #d0d5dd",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap='wrap'
            gap={1}
            mb={2}
          >
            <Typography variant="h5" sx={{whiteSpace: 'nowrap'}}>Business Calls</Typography>
            <Box sx={{ ...flexRowCenterCenter, justifyContent: 'flex-start'}}>
              <Searchbar
                value={searchText}
                onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchText(e.target.value)}
                sx={{mt:0, ml:0}}
              />
              <ButtonOutline
                startIcon={<img src={download} alt="download" />}
                sx={{ ml: 1 }}
              >
                Download
              </ButtonOutline>
            </Box>
          </Box>
          <TableContainer
            sx={{
              "& .MuiTableCell-root": {
                color: "#222124",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#F6F6F6",
                  }}
                >
                  <TableCell color="primary">Locations</TableCell>
                  <TableCell
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "connects" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "connects"}
                      direction={orderBy === "connects" ? order : "asc"}
                      onClick={() => handleRequestSort("connects")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Connects
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    sortDirection={
                      orderBy === "connectPercentage" ? order : false
                    }
                  >
                    <CustomTableSortLabel
                      active={orderBy === "connectPercentage"}
                      direction={
                        orderBy === "connectPercentage" ? order : "asc"
                      }
                      onClick={() => handleRequestSort("connectPercentage")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Connects%
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "notConnected" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "notConnected"}
                      direction={orderBy === "notConnected" ? order : "asc"}
                      onClick={() => handleRequestSort("notConnected")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Not connected
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "missed" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "missed"}
                      direction={orderBy === "missed" ? order : "asc"}
                      onClick={() => handleRequestSort("missed")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Missed
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                    sortDirection={orderBy === "avgTalkTime" ? order : false}
                  >
                    <CustomTableSortLabel
                      active={orderBy === "avgTalkTime"}
                      direction={orderBy === "avgTalkTime" ? order : "asc"}
                      onClick={() => handleRequestSort("avgTalkTime")}
                      IconComponent={() => <img src={sort} alt="sort" />}
                    >
                      Avg. Talk Time
                    </CustomTableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{whiteSpace: 'nowrap'}}>{row.location}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.connects}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.connectPercentage}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.notConnected}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.missed}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.avgTalkTime}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              pt: 4,
            }}
          >
            <Typography variant="body2">
              Showing {rowsPerPage} of {callData.length}{" "}
            </Typography>
            <Pagination
              count={callData.length / rowsPerPage}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#222124",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  color: "#1976d2",
                  borderColor: "#1976d2",
                  backgroundColor: "#ffffff",
                  fontWeight: 500,
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            />
          </Box>
        </Grid2>
        <Grid2 size={{ sm: 12, lg: 4 }}>
          <CallsComponent />
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDBDB",
              p: "32px 24px",
              flexGrow: 1,
              mb: 3,
            }}
          >
            <Typography variant="h5">Busy Hours</Typography>
            <Typography variant="h2" sx={{ color: "#FA7F65", pt: 2, pb: 3.5 }}>
              17:00 - 19:00
            </Typography>
            <Box
              sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Total calls
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#646464", fontWeight: 500 }}
              >
                4,089/ 76%
              </Typography>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={76}
              bgcolor="#95E0BA"
            />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Calls;
