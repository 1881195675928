import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface ReviewsState {
  reviewsList: any[] | null;
  count: number | null;
  loading: boolean;
  error: string | null;
  templates: any[] | null;
  templatesLoading: boolean;
}

const initialState: ReviewsState = {
  reviewsList: null,
  count: null,
  loading: false,
  error: null,
  templates: null,
  templatesLoading: false,
};

export const getReviews = createAsyncThunk(
  "/review-list",
  async (req: { page: number; limit: number }, thunkAPI) => {
    try {
      const response = await apiManager.post("/review-list", req);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get reviews list"
      );
    }
  }
);

export const getTemplates = createAsyncThunk<
  any[],
  void,
  { rejectValue: string }
>("/templates", async (_, thunkAPI) => {
  try {
    const response = await apiManager.get("/template-list");
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(
      error.response?.data.message || "Failed to get templates"
    );
  }
});

export const createTemplate = createAsyncThunk(
  "/create-template",
  async (
    template: { template_title: string; template_body: string },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/create-template", template);
      const { dispatch } = thunkAPI;
      dispatch(getTemplates());
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data.message || "Failed to create template"
      );
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get reviews list
      .addCase(getReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReviews.fulfilled, (state, action: any) => {
        state.reviewsList = action.payload.data;
        state.count = action.payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      //get templates
      .addCase(getTemplates.pending, (state) => {
        state.templatesLoading = true;
        state.error = null;
      })
      .addCase(getTemplates.fulfilled, (state, action: any) => {
        state.templates = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getTemplates.rejected, (state, action: any) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export default reviewsSlice.reducer;
