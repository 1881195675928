import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  REHYDRATE,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "store/auth/userSlice";
import signupUserReducer from "store/auth/signupUserSlice";
import otpReducer from "store/auth/otpSlice";
import userManagementReducer from "store/userManagementSlice";
import listingsReducer from "store/listingsSlice";
import listingDetailsReducer from "store/listingDetailsSlice";
import reviewsReducer from "store/reviewsSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import forgotPasswordReducer from "store/auth/forgotAndResetPasswordSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "isLoggedIn", "token"],
};

const signupUserPersistConfig = {
  key: "signupUser",
  storage,
  whitelist: ["user"],
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedSignupUserReducer = persistReducer(
  signupUserPersistConfig,
  signupUserReducer
);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    signupUser: persistedSignupUserReducer,
    otp: otpReducer,
    forgotPassword: forgotPasswordReducer,
    userManagement: userManagementReducer,
    reviews: reviewsReducer,
    listings: listingsReducer,
    listingDetails: listingDetailsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
