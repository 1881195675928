import React, { forwardRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Chip, Typography } from "@mui/material";
import { CarouselItem } from "interfaces/Reviews.types";
import TextField from "components/TextField";
import plus from "assets/icons/add-black.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getTemplates } from "store/reviewsSlice";

// const items = [
//   {
//     id: 1,
//     title: "Apology 1",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 2,
//     title: "Apology 2",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 3,
//     title: "Apology 3",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 4,
//     title: "Apology 4",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 5,
//     title: "Apology 5",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 6,
//     title: "Apology 6",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
//   {
//     id: 7,
//     title: "Apology 7",
//     description:
//       "We're sorry for your experience and are taking steps to address it. Please  for a resolution.",
//   },
// ];

const chipsData = [
  "Name",
  "Business Address",
  "City",
  "State",
  "Pincode",
  "Area",
];

export const Carousel: React.FC<{
  onClickTemplate: (item: CarouselItem) => void;
  slidesToShow?: { lg: number; md: number; xs: number };
  maxWidthofCarousel?: string;
}> = forwardRef(
  (
    {
      onClickTemplate,
      slidesToShow = { lg: 3, md: 2, xs: 1 },
      maxWidthofCarousel = "95%",
    },
    ref
  ) => {
    const { templates } = useSelector((state: RootState) => state.reviews);
    const dispatch = useDispatch<AppDispatch>();
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow.lg,
      slidesToScroll: slidesToShow.lg,
      arrows: false,
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: slidesToShow.md,
            slidesToScroll: slidesToShow.md,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: slidesToShow.xs,
            slidesToScroll: slidesToShow.xs,
          },
        },
      ],
    };

    useEffect(() => {
      dispatch(getTemplates());
    }, []);

    return (
      <Box ref={ref}>
        <Slider {...settings}>
          {templates?.map((item) => (
            <Box
              key={item.id}
              onClick={() => onClickTemplate(item)}
              sx={(theme) => ({
                border: `1px solid ${theme.palette.grey["200"]}`,
                p: 2,
                borderRadius: "4px",
                maxWidth: maxWidthofCarousel,
                boxSizing: "border-box",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer",
              })}
            >
              <Typography variant="body1" sx={{ pb: 0.5, fontWeight: 600 }}>
                {item.template_title}
              </Typography>
              <Typography
                variant="body2"
                color="text.disabled"
                sx={{
                  maxHeight: 80,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  textOverflow: "ellipsis",
                }}
              >
                {item.template_body}
              </Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }
);

type TemplateProps = {
  template_title: string;
  template_body: string;
};

export const CreateNewTemplate: React.FC<{
  getCreateNewTemplate: (template: TemplateProps) => void;
  template: TemplateProps;
}> = ({ getCreateNewTemplate, template }) => {
  const onClickTemplate = (item: any) =>
    getCreateNewTemplate({
      template_title: item.template_title,
      template_body: item.template_body,
    });

  return (
    <Box>
      <Typography variant="body1">Saved Templates</Typography>
      <Box sx={{ pt: 1 }}>
        <Carousel onClickTemplate={onClickTemplate} />
      </Box>
      <Typography variant="body2" sx={{ fontWeight: 600, pb: 1, pt: 4 }}>
        Template Name
      </Typography>
      <TextField
        placeholder="Enter new template name here.."
        value={template.template_title}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) =>
          getCreateNewTemplate({
            ...template,
            template_title: event.target.value,
          })
        }
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
      />
      <Typography variant="body2" sx={{ fontWeight: 600, pb: 1, pt: 1.5 }}>
        Reply
      </Typography>
      <TextField
        placeholder="Enter reply message here..."
        value={template.template_body}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) =>
          getCreateNewTemplate({
            ...template,
            template_body:
              event.target.value.length > 2490
                ? event.target.value?.slice(0, 2500)
                : event.target.value,
          })
        }
        multiline
        variant="outlined"
        minRows={4}
        fullWidth
        sx={{ mb: 0 }}
      />
      <Box
        gap={1}
        sx={(theme) => ({
          p: 2,
          display: "flex",
          flexWrap: "wrap",
          border: `1px solid ${theme.palette.grey["400"]}`,
          borderTop: "none",
          borderRadius: "0 0 8px 8px",
          mb: 2,
        })}
      >
        {chipsData.map((label, index) => (
          <Chip
            key={index}
            label={label}
            avatar={<img src={plus} alt="plus" sizes="10px" />}
            sx={() => ({
              fontSize: "14px",
              fontWeight: 500,
            })}
          />
        ))}
      </Box>
    </Box>
  );
};
