import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import logo from "assets/icons/logo.svg";
import insights from "assets/icons/insights.svg";
import reviews from "assets/icons/reviews.svg";
import bulkUploadTracker from "assets/icons/bulk-upload-tracker.svg";
import businessProfiles from "assets/icons/business-profiles.svg";
import contacts from "assets/icons/contacts.svg";
import duplicatedListings from "assets/icons/duplicated-listings.svg";
import QA from "assets/icons/q&a.svg";
import rankTracker from "assets/icons/rank-tracker.svg";
import userManagement from "assets/icons/user-management.svg";
import switchTenant from "assets/icons/switch-tenant.svg";
import platforms from "assets/icons/platforms.svg";
import { SidebarProps } from "interfaces/Components.types";
import { flexColumnCenterCenter } from "utils/styles";
import { RootState } from "store";
import { useSelector } from "react-redux";

const Sidebar: React.FC<SidebarProps> = ({ open }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);

  const handleListItemClick = (index: number) => {
    setActiveIndex(index);
    navigate(sidebarItems[index]?.path);
  };

  const sidebarItems = [
    { text: "Insights", icon: insights, path: "/insights" },
    {
      text: "Business Profiles",
      icon: businessProfiles,
      path: "/business-profiles",
    },
    { text: "Reviews", icon: reviews, path: "/reviews" },
    { text: "Q&A", icon: QA, path: "/qa" },
    {
      text: "Duplicated Listings",
      icon: duplicatedListings,
      path: "/duplicated-listings",
    },
    {
      text: "Bulk Upload Tracker",
      icon: bulkUploadTracker,
      path: "/bulk-upload-tracker",
    },
    { text: "Rank Tracker", icon: rankTracker, path: "/rank-tracker" },
    { text: "User Management", icon: userManagement, path: "/user-management" },
    { text: "Contacts", icon: contacts, path: "/contacts" },
    { text: "Settings", icon: platforms, path: "/settings" },
    { text: "Switch Tenant", icon: switchTenant, path: "/switch-tenant" },
  ];

  useEffect(() => {
    const activeTabIndex = sidebarItems
      .map((item, index) => {
        if (item.path.includes(window.location.pathname)) return index;
        return null;
      })
      .filter((item) => item !== null);
    setActiveIndex(activeTabIndex[0]);
  });

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? 285 : 72,
        flexShrink: 0,
        "& .MuiList-root": { ...flexColumnCenterCenter, p: 0 },
        "& .MuiListItemIcon-root": {
          pl: 0,
          minWidth: 35,
        },
        "& .MuiDrawer-paper": (theme) => ({
          width: open ? 285 : 72,
          transition: "width 0.3s ease",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          fontSize: "14px",
          borderRight: "1px solid #e6e6e6",
        }),
        padding: 0,
        margin: 0,
      }}
    >
      <List>
        <ListItem
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            height: "63.99px",
            pl: open ? 5 : 3,
            pr: open ? 5 : 2,
          })}
        >
          <ListItemIcon sx={{ fontSize: "40px", p: "8px", mr: 1 }}>
            <img src={logo} alt="logo" width={31} height={39} />
          </ListItemIcon>
          <ListItemText>My Business Pro</ListItemText>
        </ListItem>
        <Box my={1}>
          {sidebarItems.map((item, index) => (
            <ListItem
              key={index}
              onClick={() => {
                if (user?.connected) handleListItemClick(index);
              }}
              sx={(theme) => ({
                paddingLeft: open ? 2 : 1,
                paddingRight: open ? 2 : 1,
                my: 1,
                flexGrow: 1,
                borderRadius: "5px",
                width: open ? 236 : 32,
                backgroundColor:
                  activeIndex === index ? "#1442A7" : "transparent",
                color:
                  activeIndex === index
                    ? theme.palette.secondary.main
                    : theme.palette.grey["500"],
                cursor: user?.connected ? "pointer" : "no-drop",
              })}
            >
              <ListItemIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  fontSize: "40px",
                  px: "8px",
                })}
              >
                <img
                  src={item.icon}
                  alt={item.text}
                  style={{ height: "20px" }}
                />
              </ListItemIcon>
              {open && <ListItemText primary={item.text} />}
            </ListItem>
          ))}
        </Box>
      </List>
    </Drawer>
  );
};

export default Sidebar;
