import { createTheme } from "@mui/material/styles";

// Define the theme
export const theme = createTheme({
  palette: {
    primary: {
      main: "#222124",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#222124",
    },
    text: {
      primary: "#222124",
      secondary: "#ffffff",
      disabled: "#7F7F7F",
    },
    success: { main: "#1D954B" },
    error: { main: "#B90808" },
  },
  typography: {
    allVariants: {
      fontFamily: "Inter",
      fontWeight: 400,
    },
    h1: {
      fontSize: "30px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "28px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "22px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "18px",
      fontWeight: 400,
    },
    body1: {
      fontSize: "16px",
      fontWeight: 600,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
});
