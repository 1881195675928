import { Box, FormControlLabel, IconButton, Typography } from "@mui/material";
import { Button } from "components/Buttons";
import Checkbox from "components/Checkbox";
import React, { forwardRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { flexRowCenterCenter } from "utils/styles";
import close from "assets/icons/close.svg";
import fileUpload from "assets/icons/file-upload.svg";

const PhotosOrVidoes: React.FC = forwardRef((props, ref) => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = (acceptedFiles: File[]) => {
    const previews = acceptedFiles.map((file) => URL.createObjectURL(file));
    setSelectedFiles((prev: File[]) => [...prev, ...previews]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const previews = fileArray.map((file) => URL.createObjectURL(file));
      setSelectedFiles((prev: any) => [...prev, ...previews]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prev: any) =>
      prev.filter((_: any, i: any) => i !== index)
    );
  };

  return (
    <Box ref={ref}>
      <Typography
        variant="body1"
        sx={(theme) => ({
          fontWeight: 600,
          p: 2,
          borderBottom: `1px solid ${theme.palette.grey["200"]}`,
        })}
      >
        Add photos and videos
      </Typography>
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-end" }}>
        <Box sx={{ ...flexRowCenterCenter, pt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                sx={(theme) => ({ color: theme.palette.grey["300"] })}
              />
            }
            label="Add additional information"
            sx={() => ({
              "& .MuiTypography-root": { fontSize: "14px", fontWeight: 200 },
            })}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 2, mx: 5 }}>
        <Typography variant="body2" fontWeight={600}>
          Add Media
        </Typography>
        <Box
          {...getRootProps()}
          sx={(theme) => ({
            border: `2px dashed ${theme.palette.grey["300"]}`,
            borderRadius: 2,
            textAlign: "center",
            width: "100%",
            p: 3,
            boxSizing: "border-box",
          })}
        >
          <input {...getInputProps()} />
          <img src={fileUpload} alt="file-upload" sizes="32px" />
          <Box sx={{ ...flexRowCenterCenter }}>
            <Typography variant="body2" sx={{ my: 1 }}>
              Drag and Drop or{" "}
            </Typography>
            <label htmlFor="picker">
              <Button
                variant="text"
                sx={{ textTransform: "none", border: "none", color: "#1650CF" }}
              >
                Click to upload
              </Button>
            </label>
            <input
              id="picker"
              type="image/*, video/*"
              hidden
              onChange={handleFileChange}
              multiple
            />
          </Box>
          <Typography variant="caption" color="textDisabled">
            Supported formats: Images or videos. Max Size: 25MB
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ px: 2 }}>
        Selected pictures for upload
      </Typography>
      <Box>
        <Box
          gap={1}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
            p: 2,
          }}
        >
          {selectedFiles?.map((preview: any, index: any) => (
            <Box
              key={index}
              mx={1}
              my={1}
              position="relative"
              sx={{ width: 100, height: 100 }}
            >
              <img
                src={preview}
                alt="Pic Thumbnail"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  borderRadius: "8px",
                }}
              />
              <IconButton
                onClick={() => handleRemoveFile(index)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: "white",
                  "&:hover": { bgcolor: "grey.200" },
                }}
              >
                <img
                  src={close}
                  alt="remove"
                  style={{ width: 10, height: 10 }}
                />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
});

export default PhotosOrVidoes;
