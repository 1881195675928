import {
  Box,
  FormControlLabel,
  Typography,
  TextField as TextInput,
  MenuItem,
} from "@mui/material";
import { Button } from "components/Buttons";
import Checkbox from "components/Checkbox";
import { DropDown } from "components/Select";
import TextField from "components/TextField";
import React, { forwardRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { flexRowCenterCenter } from "utils/styles";
import fileUpload from "assets/icons/file-upload.svg";
import styled from "@emotion/styled";

const StyledTextField = styled(TextInput)(() => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#f8f1f182",
  },
  "& .MuiFilledInput-root::before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-root::after": {
    borderBottom: "none",
  },
  "& .MuiInputBase-input": {
    fontWeight: 400,
    fontSize: "14px",
    paddingTop: "0px",
  },
  "& .MuiFilledInput-input": {
    padding: "8px !important",
  },
  "& .MuiInputBase-root": {
    padding: "0px 8px !important",
  },
}));

const buttonsList = [
  "None",
  "Book",
  "Order online",
  "Buy",
  "Learn more",
  "Sign up",
  "Call now",
];

const Updates: React.FC = forwardRef((props, ref) => {
  const [tabs, setTabs] = useState([
    { id: 1, name: "Add Update", isActive: true },
    { id: 2, name: "Add Offer", isActive: false },
    { id: 3, name: "Add Event", isActive: false },
  ]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = (acceptedFiles: File[]) => {
    const previews = acceptedFiles.map((file) => URL.createObjectURL(file));
    setSelectedFiles((prev: File[]) => [...prev, ...previews]);
  };
  console.log(selectedFiles);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const previews = fileArray.map((file) => URL.createObjectURL(file));
      setSelectedFiles((prev: any) => [...prev, ...previews]);
    }
  };

  return (
    <Box ref={ref}>
      <Typography variant="body1" sx={() => ({ fontWeight: 600, p: 2 })}>
        Add Update
      </Typography>
      <Box
        sx={(theme) => ({
          p: 2,
          pt: 0,
          borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          display: "flex",
          flexWrap: "wrap",
        })}
      >
        {tabs.map((tab) => (
          <Typography
            component="a"
            href={`#${tab.name.toLowerCase()}-section`}
            key={tab.id}
            variant="body2"
            sx={(theme) => ({
              textDecoration: "none",
              borderBottom: tab.isActive ? "4px solid #440BFF" : "",
              mr: 2,
              p: 1,
              cursor: "pointer",
              boxSizing: "border-box",
              fontWeight: 400,
              color: tab.isActive
                ? theme.palette.primary.main
                : theme.palette.text.disabled,
            })}
            onClick={() =>
              setTabs(
                tabs.map((updateTab) => ({
                  ...updateTab,
                  isActive: updateTab.id === tab.id,
                }))
              )
            }
          >
            {tab.name}
          </Typography>
        ))}
      </Box>
      <Box sx={{ p: 2, pt: 0 }}>
        {tabs[0].isActive ? (
          <Box>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Typography variant="body1" fontWeight={400}>
                Add update about the business
              </Typography>
              <Box sx={{ ...flexRowCenterCenter }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={(theme) => ({ color: theme.palette.grey["300"] })}
                    />
                  }
                  label="Add additional information"
                  sx={() => ({
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 200,
                    },
                  })}
                />
              </Box>
            </Box>
            <Box sx={{ my: 3, mx: 5 }}>
              <Typography variant="body2" fontWeight={600}>
                Add Media
              </Typography>
              <Box
                {...getRootProps()}
                sx={(theme) => ({
                  border: `2px dashed ${theme.palette.grey["300"]}`,
                  borderRadius: 2,
                  textAlign: "center",
                  width: "100%",
                  p: 3,
                  boxSizing: "border-box",
                })}
              >
                <input {...getInputProps()} />
                <img src={fileUpload} alt="file-upload" sizes="32px" />
                <Box sx={{ ...flexRowCenterCenter }}>
                  <Typography variant="body2" sx={{ my: 1 }}>
                    Drag and Drop or{" "}
                  </Typography>
                  <label htmlFor="picker">
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                        border: "none",
                        color: "#1650CF",
                      }}
                    >
                      Click to upload
                    </Button>
                  </label>
                  <input
                    id="picker"
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    multiple
                  />
                </Box>
                <Typography variant="caption" color="textDisabled">
                  Supported formats: Images or videos. Max Size: 25MB
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Add a description
              </Typography>
              <StyledTextField
                multiline
                minRows={2}
                variant="filled"
                fullWidth
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}>
                <Typography variant="caption">0/1500</Typography>
              </Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Add a button
              </Typography>
              <DropDown value="None">
                {buttonsList.map((button) => (
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "12px" }}
                    value={button}
                  >
                    {button}
                  </MenuItem>
                ))}
              </DropDown>
              <Box sx={{ pt: 1 }}>
                <TextField
                  variant="outlined"
                  placeholder="Add link for your button"
                  size="small"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        ) : tabs[1].isActive ? (
          <Box>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Typography variant="body1" fontWeight={400}>
                Add offer
              </Typography>
              <Box sx={{ ...flexRowCenterCenter }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={(theme) => ({ color: theme.palette.grey["300"] })}
                    />
                  }
                  label="Add additional information"
                  sx={() => ({
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 200,
                    },
                  })}
                />
              </Box>
            </Box>
            <Box sx={{ my: 3, mx: 5 }}>
              <Typography variant="body2" fontWeight={600}>
                Add Media
              </Typography>
              <Box
                {...getRootProps()}
                sx={(theme) => ({
                  border: `2px dashed ${theme.palette.grey["300"]}`,
                  borderRadius: 2,
                  textAlign: "center",
                  width: "100%",
                  p: 3,
                  boxSizing: "border-box",
                })}
              >
                <input {...getInputProps()} />
                <img src={fileUpload} alt="file-upload" sizes="32px" />
                <Box sx={{ ...flexRowCenterCenter }}>
                  <Typography variant="body2" sx={{ my: 1 }}>
                    Drag and Drop or{" "}
                  </Typography>
                  <label htmlFor="picker">
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                        border: "none",
                        color: "#1650CF",
                      }}
                    >
                      Click to upload
                    </Button>
                  </label>
                  <input
                    id="picker"
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    multiple
                  />
                </Box>
                <Typography variant="caption" color="textDisabled">
                  Supported formats: Images or videos. Max Size: 25MB
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Offer Title (Example: 20% off in store or online)
              </Typography>
              <StyledTextField variant="filled" fullWidth />
              <Box
                gap={2}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  py: 3,
                }}
              >
                <Box>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    Start Date
                  </Typography>
                  <StyledTextField variant="filled" type="date" />
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    End Date
                  </Typography>
                  <StyledTextField variant="filled" type="date" />
                </Box>
              </Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                More details about offer
              </Typography>
              <StyledTextField
                multiline
                minRows={2}
                variant="filled"
                fullWidth
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}>
                <Typography variant="caption">0/1500</Typography>
              </Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Voucher code (optional)
              </Typography>
              <StyledTextField
                multiline
                minRows={2}
                variant="filled"
                fullWidth
                sx={{ mb: 3 }}
              />
              <Typography variant="body2" sx={{ pb: 1 }}>
                Link to redeem offer (optional)
              </Typography>
              <StyledTextField
                multiline
                minRows={2}
                variant="filled"
                fullWidth
                sx={{ mb: 3 }}
              />
              <Typography variant="body2" sx={{ pb: 1 }}>
                Terms and conditions (optional)
              </Typography>
              <StyledTextField
                multiline
                minRows={2}
                variant="filled"
                fullWidth
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}>
                <Typography variant="caption">0/5000</Typography>
              </Box>
            </Box>
          </Box>
        ) : tabs[2].isActive ? (
          <Box>
            <Box
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Typography variant="body1" fontWeight={400}>
                Add event
              </Typography>
              <Box sx={{ ...flexRowCenterCenter }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={(theme) => ({ color: theme.palette.grey["300"] })}
                    />
                  }
                  label="Add additional information"
                  sx={() => ({
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 200,
                    },
                  })}
                />
              </Box>
            </Box>
            <Box sx={{ my: 3, mx: 5 }}>
              <Typography variant="body2" fontWeight={600}>
                Add Media
              </Typography>
              <Box
                {...getRootProps()}
                sx={(theme) => ({
                  border: `2px dashed ${theme.palette.grey["300"]}`,
                  borderRadius: 2,
                  textAlign: "center",
                  width: "100%",
                  p: 3,
                  boxSizing: "border-box",
                })}
              >
                <input {...getInputProps()} />
                <img src={fileUpload} alt="file-upload" sizes="32px" />
                <Box sx={{ ...flexRowCenterCenter }}>
                  <Typography variant="body2" sx={{ my: 1 }}>
                    Drag and Drop or{" "}
                  </Typography>
                  <label htmlFor="picker">
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                        border: "none",
                        color: "#1650CF",
                      }}
                    >
                      Click to upload
                    </Button>
                  </label>
                  <input
                    id="picker"
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    multiple
                  />
                </Box>
                <Typography variant="caption" color="textDisabled">
                  Supported formats: Images or videos. Max Size: 25MB
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Event Title (Example: Sale of the week)
              </Typography>
              <StyledTextField variant="filled" fullWidth />
              <Box
                gap={1}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  pt: 3,
                  pb: 1,
                }}
              >
                <Box mr={2}>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    Start Date
                  </Typography>
                  <StyledTextField variant="filled" type="date" />
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    Start Time (optional)
                  </Typography>
                  <StyledTextField variant="filled" type="time" />
                </Box>
              </Box>
              <Box
                gap={1}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  py: 3,
                }}
              >
                <Box mr={2}>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    End Date
                  </Typography>
                  <StyledTextField variant="filled" type="date" />
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ pb: 1 }}>
                    End Time (optional)
                  </Typography>
                  <StyledTextField variant="filled" type="time" />
                </Box>
              </Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Event Details
              </Typography>
              <StyledTextField
                multiline
                minRows={2}
                variant="filled"
                fullWidth
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}>
                <Typography variant="caption">0/1500</Typography>
              </Box>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Add a button
              </Typography>
              <DropDown value="None">
                {buttonsList.map((button) => (
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "12px" }}
                    value={button}
                  >
                    {button}
                  </MenuItem>
                ))}
              </DropDown>
              <Box sx={{ pt: 1 }}>
                <TextField
                  variant="outlined"
                  placeholder="Add link for your button"
                  size="small"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
});

export default Updates;
