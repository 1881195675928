import { InputAdornment, TextFieldProps } from "@mui/material";
import TextField from "components/TextField";
import React from "react";
import search from "assets/icons/search.svg";

const Searchbar: React.FC<TextFieldProps> = ({ sx, slotProps, ...props }) => {
  return (
    <TextField
      color="primary"
      size="small"
      variant='outlined'
      placeholder="Search"
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <img src={search} alt="search" width={16} height={16} />
            </InputAdornment>
          ),
        },
        ...slotProps
      }}
      sx={{
        mb: 0,
        ml: 1,
        minWidth: "100px",
        ...sx,
      }}
      type="search"
      {...props}
    />
  );
};

export default Searchbar;
