import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid2,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Button, ButtonOutline } from "components/Buttons";
import { DropDown } from "components/Select";
import {
  BusinessProfile,
  ProductProps,
} from "interfaces/BusinessProfiles.types";
import React, { forwardRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { flexRowCenterCenter } from "utils/styles";
import TextField from "components/TextField";
import image from "assets/images/nike-shoe-white.png";
import close from "assets/icons/close.svg";
import fileUpload from "assets/icons/file-upload.svg";
import Checkbox from "components/Checkbox";

const Products: React.FC<{ selectedBusinessProfiles: BusinessProfile[] }> =
  forwardRef(({ selectedBusinessProfiles }, ref) => {
    const [tabs, setTabs] = useState([
      { id: 1, name: "Listed Products", isActive: true },
      { id: 2, name: "Edit Product", isActive: false },
      { id: 3, name: "Add Product", isActive: false },
    ]);

    const [products, setProducts] = useState<ProductProps[]>([
      {
        id: 1,
        status: "not approved",
        description:
          "Product Description will be coming here, however every is covered in two lines",
        currentPrice: 5400,
        mrp: 5600,
        image,
        isSpecial: true,
      },
      {
        id: 2,
        status: "pending",
        description:
          "Product Description will be coming here, however every is covered in two lines",
        currentPrice: 5400,
        mrp: 5600,
        image,
        isSpecial: false,
      },
    ]);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const onDrop = (acceptedFiles: any) => setSelectedFile(acceptedFiles[0]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileChange = (event: any) =>
      setSelectedFile(event.target.files[0]);

    const removeProduct = (currentProduct: ProductProps) => {
      setProducts(
        products.filter((product) => product.id !== currentProduct.id)
      );
      setOpenConfirmation(false);
    };

    const onClickTab = (tab: any) => {
      if (
        (selectedBusinessProfiles.length !== 1 || products.length == 0) &&
        tab.id === 2
      )
        return;
      setTabs(
        tabs.map((updateTab) => ({
          ...updateTab,
          isActive: updateTab.id === tab.id,
        }))
      );
    };
    return (
      <Box ref={ref}>
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: -1,
            backgroundColor: theme.palette.secondary.main,
            zIndex: 10,
          })}
        >
          <Typography variant="body1" sx={() => ({ fontWeight: 600, p: 2 })}>
            Add Products
          </Typography>
          <Box
            gap={1}
            sx={(theme) => ({
              p: 2,
              pt: 0,
              borderBottom: `1px solid ${theme.palette.grey["200"]}`,
              display: "flex",
              flexWrap: "wrap",
            })}
          >
            {tabs.map((tab) => (
              <Typography
                component="a"
                href={`#${tab.name.toLowerCase()}-section`}
                key={tab.id}
                variant="body2"
                sx={(theme) => ({
                  textDecoration: "none",
                  borderBottom: tab.isActive ? "4px solid #440BFF" : "",
                  mr: 2,
                  p: 1,
                  cursor: "pointer",
                  boxSizing: "border-box",
                  fontWeight: 400,
                  color: tab.isActive
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled,
                  whiteSpace: "nowrap",
                })}
                onClick={() => onClickTab(tab)}
              >
                {tab.name}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box p={2}>
          {selectedBusinessProfiles.length === 1 && products.length > 0 ? (
            <Box sx={{ pb: 2 }}>
              <Box
                gap={2}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <DropDown defaultValue="all-products" sx={{ mr: 5 }}>
                  <MenuItem
                    value="all-products"
                    sx={{ fontSize: "14px", fontWeight: 400 }}
                  >
                    All Products
                  </MenuItem>
                </DropDown>
                <ButtonOutline
                  sx={{ borderRadius: "4px", whiteSpace: "nowrap" }}
                >
                  Add Product
                </ButtonOutline>
              </Box>
              {tabs[0].isActive ? (
                <Box
                  gap={2}
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    pt: 2,
                  }}
                >
                  {products?.map((product) => (
                    <Box
                      key={product.id}
                      sx={(theme) => ({
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.grey["300"]}`,
                      })}
                    >
                      <Box
                        sx={{
                          backgroundImage: `url(${image})`,
                          backgroundSize: "cover",
                          borderRadius: "8px 8px 0px 0px",
                          width: 180,
                          height: 100,
                          p: 1,
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box>
                              <Chip
                                sx={(theme) => ({
                                  backgroundColor: theme.palette.secondary.main,
                                  fontSize: "10px",
                                  textTransform: "uppercase",
                                  height: 20,
                                  mb: 1,
                                })}
                                label={product.status}
                              />
                            </Box>
                            {product.isSpecial ? (
                              <Chip
                                sx={(theme) => ({
                                  backgroundColor: theme.palette.grey["700"],
                                  color: theme.palette.secondary.main,
                                  fontSize: "10px",
                                  fontWeight: 600,
                                  height: 20,
                                  width: 60,
                                  borderRadius: "3px",
                                  textTransform: "capitalize",
                                })}
                                label="Special"
                              />
                            ) : null}
                          </Box>
                          <IconButton
                            onClick={() => setOpenConfirmation(true)}
                            sx={{ height: 15, width: 15 }}
                          >
                            <img
                              src={close}
                              alt="close"
                              style={{ width: 14, height: 14 }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          boxSizing: "border-box",
                          maxWidth: 180,
                          top: -100,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            maxWidth: 180,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {product.description}
                        </Typography>
                        <Box pt={2}>
                          <Typography variant="caption" color="success" mr={2}>
                            Rs. {product.currentPrice.toFixed(2)}
                          </Typography>
                          <Typography variant="caption" color="textDisabled">
                            Rs. {product.mrp}
                          </Typography>
                        </Box>
                      </Box>
                      <Dialog
                        sx={{
                          "& .MuiDialog-paper": {
                            width: 300,
                            borderRadius: "11px",
                            textAlign: "center",
                          },
                        }}
                        maxWidth="xs"
                        open={openConfirmation}
                      >
                        <DialogTitle>Warning</DialogTitle>
                        <DialogContent>
                          <Typography variant="body2">
                            Once the product listing is deleted, cannot be
                            reverted
                          </Typography>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
                          <Button
                            autoFocus
                            sx={{ mr: 4 }}
                            onClick={() => setOpenConfirmation(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              border: "none",
                              backgroundColor: "rgba(22, 80, 207, 1)",
                            }}
                            onClick={() => removeProduct(product)}
                          >
                            Proceed
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Grid2 gap={3} container sx={{ pt: 3 }}>
                  <Grid2 size={{ xs: 12, lg: 6.7 }}>
                    <Box sx={{ width: "100%", minWidth: 250 }}>
                      <Typography variant="body2">Product Name</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ mb: 0.2 }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography variant="caption">0/58</Typography>
                      </Box>
                      <Typography variant="body2" mb={1}>
                        Select a category
                      </Typography>
                      <DropDown label="Select" labelVisible={true} width="100%">
                        <MenuItem></MenuItem>
                      </DropDown>
                      <Typography variant="body2" mt={2}>
                        Product price (INR) - optional
                      </Typography>
                      <TextField variant="outlined" fullWidth size="small" />
                      <Typography variant="body2">
                        Product description - optional
                      </Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ mb: 0.2 }}
                        multiline
                        minRows={3}
                      />
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography variant="caption">0/1000</Typography>
                      </Box>
                      <Typography variant="body2">
                        Product landing page URL - optional
                      </Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ mb: 0.2 }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography variant="caption">0/1,500</Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={<Checkbox />}
                          label={
                            <Box maxWidth={220}>
                              <Typography sx={{ fontSize: "12px" }}>
                                Mark as Special
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textDisabled"
                              >
                                Product marked as Special as shown on the top of
                                the page
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 12, lg: 4.7 }}>
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={(theme) => ({
                          borderRadius: "15px",
                          border: `1px solid ${theme.palette.grey["400"]}`,
                        })}
                      >
                        <Box
                          {...getRootProps()}
                          sx={() => ({
                            textAlign: "center",
                            width: "100%",
                            p: 3,
                            boxSizing: "border-box",
                          })}
                        >
                          <input {...getInputProps()} />
                          <img
                            src={fileUpload}
                            alt="file-upload"
                            sizes="32px"
                          />
                          <Box sx={{ ...flexRowCenterCenter }}>
                            <Typography
                              variant="caption"
                              sx={{ my: 1, whiteSpace: "none" }}
                            >
                              Drag and Drop or{" "}
                            </Typography>
                            <Button
                              variant="text"
                              component="label"
                              sx={{
                                textTransform: "none",
                                border: "none",
                                color: "#1650CF",
                                fontSize: "12px",
                                whiteSpace: "none",
                                pl: 0.5,
                                pr: 0,
                              }}
                            >
                              Click to upload
                              <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                              />
                            </Button>
                          </Box>
                          <Typography variant="caption" color="textDisabled">
                            Supported formats: Images or videos. Max Size: 25MB
                          </Typography>
                          {selectedFile && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              Selected file: {selectedFile?.name}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid2>
                </Grid2>
              )}
            </Box>
          ) : (
            <Box sx={{ textAlign: "center", pt: 2 }}>
              <Typography variant="body2" sx={{ pb: 2 }}>
                Select individual listing to see the listed products
              </Typography>
              <Typography variant="body2" sx={{ pb: 2 }}>
                Or
              </Typography>
              <Typography variant="body2" sx={{ pb: 2 }}>
                No product listed
              </Typography>
              <Button
                sx={{
                  color: "#1650CF",
                  border: "1px solid #1650CF",
                  width: 120,
                  mb: 2,
                }}
              >
                Add new
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  });

export default Products;
