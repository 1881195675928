import {
  Box,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { Button } from "components/Buttons";
import Checkbox from "components/Checkbox";
import Searchbar from "components/Searchbar";
import { DropDown } from "components/Select";
import {
  BusinessProfile,
  EditBusinessListingProps,
} from "interfaces/BusinessProfiles.types";
import { forwardRef, useState } from "react";
import { flexColumnCenterCenter, flexRowCenterCenter } from "utils/styles";
import storeIcon from "assets/icons/local-convenience-store-blue.svg";
import photosIcon from "assets/icons/monochrome-photos-blue.svg";
import mall from "assets/icons/local-mall-blue.svg";
import factCheck from "assets/icons/fact-check-blue.svg";
import systemUpdate from "assets/icons/system-update-blue.svg";
import featuredVideo from "assets/icons/featured-video-blue.svg";
import close from "assets/icons/close.svg";
import Profile from "./Profile";
import PhotosOrVidoes from "./PhotosOrVidoes";
import Products from "./Products";
import Services from "./Services";
import Updates from "./Updates";
import Labels from "./Labels";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  maxWidth: 850,
  minWidth: 380,
  width: "75%",
  height: "100vh-150px",
};

const iconsList = [
  { id: "1", name: "Edit Profile", icon: storeIcon },
  { id: "2", name: "Photos", icon: photosIcon },
  { id: "3", name: "Edit Products", icon: mall },
  { id: "4", name: "Edit Service", icon: factCheck },
  { id: "5", name: "Updates", icon: systemUpdate },
  { id: "6", name: "Labels", icon: featuredVideo },
];

const initialBusinessProfiles = [
  {
    id: "5102384584759847987",
    name: "Nike Store",
    address: "Bata Store, G7 Fortune Empire, Jubilee Hills Road",
    city: "Chennai",
    state: "Tamil Nadu",
    phone: "8668881010",
    description:
      "Stylish and comfortable footwear for the whole family. Explore our quality-crafted collections featuring the latest trends and timeless classics",
    website: "www.bata.in",
  },
  {
    id: "5102384584759847988",
    name: "Nike Store Anna Nagar",
    address: "Bata Store, G7 Fortune Empire, Jubilee Hills Road",
    city: "Chennai",
    state: "Tamil Nadu",
    phone: "8668881010",
    description:
      "Stylish and comfortable footwear for the whole family. Explore our quality-crafted collections featuring the latest trends and timeless classics",
    website: "www.bata.in",
  },
];

const EditBusinessListing: React.FC<EditBusinessListingProps> = forwardRef(
  ({ setActionOrOpen, action, selectedBusiness, selectedRef }, ref) => {
    const [selectedBusinessProfiles, setSelectedBusinessProfiles] = useState<
      BusinessProfile[] | []
    >(selectedBusiness || []);
    const [currentAction, setCurrentAction] = useState(action);

    const getComponent = () => {
      switch (currentAction) {
        case "1":
          return (
            <Profile
              selectedBusinessProfiles={selectedBusinessProfiles}
              selectedRef={selectedRef}
            />
          );
        case "2":
          return <PhotosOrVidoes />;
        case "3":
          return (
            <Products selectedBusinessProfiles={selectedBusinessProfiles} />
          );
        case "4":
          return <Services />;
        case "5":
          return <Updates />;
        case "6":
          return <Labels />;
        default:
          return <></>;
      }
    };

    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          ...style,
        })}
      >
        <Box
          sx={{
            maxHeight: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              gap={2}
              sx={(theme) => ({
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: 70,
                maxWidth: 70,
                overflowY: "auto",
                position: "sticky",
                top: 0,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "16px 0 0 16px",
                zIndex: 20,
              })}
            >
              {iconsList.map((item) => (
                <MenuItem
                  key={item.id}
                  sx={{
                    ...flexColumnCenterCenter,
                    p: 0.5,
                    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
                    borderRadius: 1,
                    border: currentAction == item.id ? "1px solid #4B52FF" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setCurrentAction(item.id)}
                >
                  <img
                    src={item.icon}
                    alt={item.name}
                    style={{ width: 35, height: 35 }}
                  />
                  <Typography
                    variant="caption"
                    color="textDisabled"
                    sx={{ whiteSpace: "nowrap", fontSize: "10px" }}
                  >
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
            <Box
              width="calc(100% - 105px)"
              sx={(theme) => ({
                boxSizing: "border-box",
                position: "relative",
                borderLeft: `1px solid ${theme.palette.grey["200"]}`,
              })}
            >
              <Box>
                <Box
                  width="100%"
                  sx={(theme) => ({
                    p: 2,
                    borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                    boxSizing: "border-box",
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    width: "100%",
                    backgroundColor: theme.palette.secondary.main,
                    borderTopRightRadius: "16px",
                  })}
                >
                  <Box
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "space-between",
                      pb: 1,
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Selected Business Profiles
                    </Typography>
                    <IconButton
                      onClick={() => setActionOrOpen({ open: false })}
                    >
                      <img
                        src={close}
                        alt="close"
                        style={{ width: 20, height: 20 }}
                      />
                    </IconButton>
                  </Box>
                  <DropDown
                    width="100%"
                    multiple
                    label={`${selectedBusinessProfiles.length} Locations Selected`}
                    labelVisible={selectedBusinessProfiles.length < 1}
                    variant="outlined"
                    value={selectedBusinessProfiles.map(
                      (profile) => profile.id
                    )}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      const newValue: BusinessProfile[] = Array.isArray(value)
                        ? value
                            .map((id: string) =>
                              initialBusinessProfiles.find(
                                (profile) => profile.id === id
                              )
                            )
                            .filter(
                              (profile): profile is BusinessProfile =>
                                profile !== undefined
                            )
                        : [];
                      setSelectedBusinessProfiles(newValue);
                    }}
                    renderValue={() => {
                      return (
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                            width: "100%",
                          }}
                        >
                          {`${selectedBusinessProfiles.length} Locations Selected`}
                        </span>
                      );
                    }}
                  >
                    <Box sx={{ m: 1, ml: 1.5, mr: 5 }}>
                      <Searchbar fullWidth />
                    </Box>
                    {initialBusinessProfiles.map((profile) => (
                      <MenuItem key={profile.id} value={profile.id}>
                        <Checkbox
                          checked={selectedBusinessProfiles.some(
                            (p) => p.id === profile.id
                          )}
                        />
                        <ListItemText
                          primary={profile.name}
                          secondary={
                            <Box
                              sx={{
                                ...flexRowCenterCenter,
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="caption"
                                color="textDisabled"
                                sx={{
                                  fontWeight: 400,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <span style={{ paddingRight: "8px" }}>
                                  {profile.id}
                                </span>
                                <span style={{ paddingRight: "8px" }}>
                                  {profile.address}
                                </span>
                                <span style={{ paddingRight: "8px" }}>
                                  {profile.city}
                                </span>
                                <span style={{ paddingRight: "8px" }}>
                                  {profile.state}
                                </span>
                              </Typography>
                            </Box>
                          }
                          primaryTypographyProps={{
                            style: { fontSize: "14px", fontWeight: 500 },
                          }}
                        />
                      </MenuItem>
                    ))}
                  </DropDown>
                </Box>
                <Box
                  sx={{
                    overflowY: "auto",
                    minHeight: "calc(100vh - 217px)",
                    maxHeight: "calc(100vh - 217px)",
                    boxSizing: "border-box",
                  }}
                >
                  {getComponent()}
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-end",
                  p: "10px 16px",
                  justifySelf: "flex-end",
                }}
              >
                <Button
                  sx={{ mr: 1 }}
                  onClick={() => setActionOrOpen({ open: false })}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#1650CF", border: "none" }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default EditBusinessListing;
